@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&amp;family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&amp;family=Raleway:wght@300;400;500;600;700;800;900&amp;display=swap");

/* custom scrollbar */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 0px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #005288;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #005288;
}

.cr-accordion .accordion-button .cr-accordion-plus,
.cr-accordion .accordion-button .cr-accordion-plus::after,
.cr-accordion .accordion-button .cr-accordion-plus::before,
.back-to-top-wrapper,
a,
button,
p,
input,
select,
textarea,
li,
.transition-3 {
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}

.back-to-top-btn {
  -webkit-transition: transform 0.3s 0s ease-out;
  -moz-transition: transform 0.3s 0s ease-out;
  -ms-transition: transform 0.3s 0s ease-out;
  -o-transition: transform 0.3s 0s ease-out;
  transition: transform 0.3s 0s ease-out;
}

:root {
  /**
  @font family declaration
  */
  --cr-ff-body: 'DM Sans', sans-serif;
  --cr-ff-heading: 'Raleway', sans-serif;
  --cr-ff-poppins: 'Poppins', sans-serif;
  --cr-ff-p: 'DM Sans', sans-serif;
  --cr-ff-fontawesome: "Font Awesome 6 Pro";
  /**
  @font size declaration
  */
  --cr-fz-body: 16px;
  --cr-fz-p: 16px;
  /**
  @color declaration
  */
  --cr-common-white: #ffffff;
  --cr-common-black: #000;
  --cr-heading-primary: #15587d;
  --cr-heading-secondary: #000000;
  --cr-grey-1: #F1F1F1;
  --cr-grey-2: #F5F6F8;
  --cr-text-body: #5A627D;
  --cr-text-1: #261F1F;
  --cr-text-2: #0C0808;
  --cr-theme-primary: #15587d;
  --cr-theme-top-secondary: #F6E96B;
  --cr-theme-secondary: #399918;
  --cr-bg-primary: #F3FBFE;
  --cr-border-primary: #000;
  --cr-border-secondary: #000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (min-width: 1400px) {

  .container-large,
  .container-large-lg,
  .container-large-md,
  .container-large-sm,
  .container-large-xl,
  .container-large-xxl {
    max-width: 1350px;
  }
}

@media (min-width: 1400px) {

  .container-1400,
  .container-1400-lg,
  .container-1400-md,
  .container-1400-sm,
  .container-1400-xl,
  .container-1400-xxl {
    max-width: 1400px;
  }
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
  font-family: var(--cr-ff-body);
  font-size: var(--cr-fz-body);
  font-weight: normal;
  color: var(--cr-text-body);
  line-height: 26px;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--cr-ff-heading);
  color: var(--cr-heading-primary);
  margin-top: 0px;
  font-weight: 700;
  line-height: 1.2;
  -webkit-transition: color 0.3s 0s ease-out;
  -moz-transition: color 0.3s 0s ease-out;
  -ms-transition: color 0.3s 0s ease-out;
  -o-transition: color 0.3s 0s ease-out;
  transition: color 0.3s 0s ease-out;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 38px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 18px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  font-family: var(--cr-ff-p);
  font-size: var(--cr-fz-p);
  font-weight: 400;
  color: var(--cr-text-body);
  margin-bottom: 15px;
  line-height: 29px;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=number],
input[type=password],
input[type=url],
textarea {
  outline: none;
  background-color: #EFF0F2;
  height: 54px;
  width: 100%;
  line-height: 54px;
  font-size: 16px;
  border: 2px solid #EFF0F2;
  color: var(--cr-common-black);
  padding-left: 29px;
  padding-right: 29px;
}

[dir=rtl] input[type=text],
[dir=rtl] input[type=email],
[dir=rtl] input[type=tel],
[dir=rtl] input[type=number],
[dir=rtl] input[type=password],
[dir=rtl] input[type=url],
[dir=rtl] textarea {
  text-align: right;
}

input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #84848B;
}

input[type=text]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=number]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=url]::-moz-placeholder,
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #84848B;
}

input[type=text]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=number]:-moz-placeholder,
input[type=password]:-moz-placeholder,
input[type=url]:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 4-18 */
  color: #84848B;
}

input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=url]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* IE 10+  Edge*/
  color: #84848B;
}

input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=tel]::placeholder,
input[type=number]::placeholder,
input[type=password]::placeholder,
input[type=url]::placeholder,
textarea::placeholder {
  /* MODERN BROWSER */
  color: #84848B;
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--cr-common-black);
  color: var(--cr-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--cr-common-black);
  color: var(--cr-common-white);
  text-shadow: none;
}

::selection {
  background: var(--cr-common-black);
  color: var(--cr-common-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--cr-common-black);
  font-size: var(--cr-fz-body);
  opacity: 1;
}

*::placeholder {
  color: var(--cr-common-black);
  font-size: var(--cr-fz-body);
  opacity: 1;
}

.test-class {
  padding: 5px 20px;
  background-color: green;
  color: #000;
  position: relative;
  overflow: hidden;
}

.test-class::after,
.test-class::before {
  position: absolute;
  content: "";
  left: -23px;
  bottom: -7px;
  width: 79%;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
}

.test-class::before {
  left: auto;
  right: -23px;
  top: -7px;
  bottom: auto;
}

.test-class span {
  position: relative;
}

.test-class span::after,
.test-class span::before {
  position: absolute;
  content: "";
  left: -23px;
  bottom: -7px;
  width: 79%;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.mousemove {
  --x: 0;
  --y: 0;
  --d: 50;
}

.mousemove:hover .mousemove__image-wrapper {
  transform: scale(1.025) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
}

.mousemove__image {
  --d: 20;
  transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
}

.mousemove__content {
  --d: 60;
  transform: translate(calc(var(--x) / var(--d) * -1px), calc(var(--y) / var(--d) * -1px));
}

.mousemove__image-wrapper {
  height: 67%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 50%;
}

.mousemove__image {
  --d: 20;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  user-select: none;
}

/*---------------------------------
  Common Classes
---------------------------------*/
.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.demo {
  -webkit-transition: color 0.3s 0s linear;
  -moz-transition: color 0.3s 0s linear;
  -ms-transition: color 0.3s 0s linear;
  -o-transition: color 0.3s 0s linear;
  transition: color 0.3s 0s linear;
  -webkit-transition: color 0.3s linear, transform 0.2s ease;
  -moz-transition: color 0.3s linear, transform 0.2s ease;
  -ms-transition: color 0.3s linear, transform 0.2s ease;
  -o-transition: color 0.3s linear, transform 0.2s ease;
  transition: color 0.3s linear, transform 0.2s ease;
}

[dir=rtl] .demo {
  margin-left: 0;
  margin-right: 15px;
}

div.demo img {
  margin-left: 10px;
}

[cr-theme=cr-theme-dark] .demo {
  color: yellow;
}

/*----------------------------------------
    Body Overlay 
-----------------------------------------*/
.body-overlay {
  background-color: var(--cr-common-black);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}

/* .body-overlay:hover {
  cursor: url(../img/icon/close.png), pointer;
} */

.body-overlay.opened {
  opacity: 0.5;
  visibility: visible;
}

.cr-gx-10 {
  --bs-gutter-x: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-gx-10 {
    --bs-gutter-x: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-gx-10 {
    --bs-gutter-x: 8px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-gx-10 {
    --bs-gutter-x: 5px;
  }
}

@media (max-width: 575px) {
  .cr-gx-10 {
    --bs-gutter-x: 5px;
  }
}

.cr-gx-10 [class*=col-] {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

/* dropcap */
.cr-dropcap::first-letter {
  font-size: 50px;
  font-weight: 700;
  float: left;
  text-align: center;
  color: var(--cr-common-black);
  background-color: var(--cr-common-white);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  margin-right: 5px;
  line-height: inherit;
}

.class {
  stroke-dasharray: 189px, 191px;
  stroke-dashoffset: 0px;
}

/*----------------------------------------*/
/*  Back to top
/*----------------------------------------*/
.back-to-top-wrapper {
  position: fixed;
  right: 50px;
  bottom: 0;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 575px) {
  .back-to-top-wrapper {
    right: 20px;
    bottom: 20px;
  }
}

.back-to-top-wrapper.back-to-top-btn-show {
  visibility: visible;
  opacity: 1;
  bottom: 50px;
}

.back-to-top-btn {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: var(--cr-theme-top-secondary);
  box-shadow: 0px 8px 16px rgba(3, 4, 28, 0.3);
  color: var(--cr-common-black);
  border-radius: 50%;
  transition: 0.4s ease-in-out;
}

.back-to-top-btn svg {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}

.back-to-top-btn:hover {
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  transform: translateY(-4px);
  background: var(--cr-theme-primary);
  color: var(--cr-common-white);
}

/*----------------------------------------*/
/*   Theme Settings
/*----------------------------------------*/
.cr-theme-settings-area {
  position: fixed;
  top: 50%;
  left: 0;
  width: 240px;
  background-color: #fff;
  border: 1px solid #EAEAEF;
  -webkit-transform: translateY(-50%) translateX(-100%);
  -moz-transform: translateY(-50%) translateX(-100%);
  -ms-transform: translateY(-50%) translateX(-100%);
  -o-transform: translateY(-50%) translateX(-100%);
  transform: translateY(-50%) translateX(-100%);
  z-index: 991;
  direction: ltr;
  border-bottom-right-radius: 4px;
}

.cr-theme-settings-area.settings-opened {
  -webkit-transform: translateY(-50%) translateX(0%);
  -moz-transform: translateY(-50%) translateX(0%);
  -ms-transform: translateY(-50%) translateX(0%);
  -o-transform: translateY(-50%) translateX(0%);
  transform: translateY(-50%) translateX(0%);
}

.cr-theme-settings-area.settings-opened .cr-theme-settings-gear {
  opacity: 0;
}

.cr-theme-settings-area.settings-opened .cr-theme-settings-close {
  opacity: 1;
}

.cr-theme-settings-open {
  position: absolute;
  top: -1px;
  left: 100%;
}

.cr-theme-settings-open button {
  background-color: var(--cr-common-white);
  border: 1px solid #EAEAEF;
  border-left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  color: var(--cr-common-black);
  position: relative;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.cr-theme-settings-gear {
  display: inline-block;
  -webkit-animation: cr-theme-setting-spin 4s linear infinite;
  -moz-animation: cr-theme-setting-spin 4s linear infinite;
  -ms-animation: cr-theme-setting-spin 4s linear infinite;
  -o-animation: cr-theme-setting-spin 4s linear infinite;
  animation: cr-theme-setting-spin 4s linear infinite;
}

@-webkit-keyframes cr-theme-setting-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cr-theme-setting-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cr-theme-setting-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes cr-theme-setting-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cr-theme-settings-close {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.cr-theme-header-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}

.cr-theme-wrapper {
  padding: 20px 30px 30px;
}

.cr-theme-toggle {
  text-align: center;
}

.cr-theme-toggle-main {
  display: inline-block;
  width: 160px;
  margin: auto;
  position: relative;
  z-index: 1;
  background-color: #f0f0f5;
  padding: 4px;
  border-radius: 20px;
}

.cr-theme-toggle-light,
.cr-theme-toggle-dark {
  display: inline-block;
  width: 48%;
  height: 26px;
  line-height: 26px;
}

.cr-theme-toggle input {
  display: none;
}

.cr-theme-toggle:hover {
  cursor: pointer;
}

.cr-theme-toggle label {
  color: var(--cr-common-black);
  font-size: 14px;
  font-weight: 500;
}

.cr-theme-toggle label:hover {
  cursor: pointer;
}

.cr-theme-toggle #cr-theme-toggler {
  display: none;
}

.cr-theme-toggle #cr-theme-toggler:checked+i {
  right: calc(50% - 4px);
}

.cr-theme-toggle-slide {
  position: absolute;
  top: 50%;
  right: 4px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
  height: 26px;
  color: var(--cr-common-black);
  background-color: var(--cr-common-white);
  border-radius: 30px;
  -webkit-transform: translate3d(0, 0);
  transform: translate3d(0, 0);
  -webkit-transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  -moz-transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  -ms-transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  z-index: -1;
}

.cr-theme-dir {
  text-align: center;
}

.cr-theme-dir-main {
  display: inline-block;
  width: 160px;
  margin: auto;
  position: relative;
  z-index: 1;
  background-color: #f0f0f5;
  padding: 4px;
  border-radius: 20px;
}

.cr-theme-dir-ltr,
.cr-theme-dir-rtl {
  display: inline-block;
  width: 48%;
  height: 26px;
  line-height: 26px;
}

.cr-theme-dir input {
  display: none;
}

.cr-theme-dir:hover {
  cursor: pointer;
}

.cr-theme-dir label {
  color: var(--cr-common-black);
  font-size: 14px;
  font-weight: 500;
}

.cr-theme-dir label:hover {
  cursor: pointer;
}

.cr-theme-dir #cr-dir-toggler {
  display: none;
}

.cr-theme-dir #cr-dir-toggler:checked+i {
  right: calc(50% - 4px);
}

.cr-theme-dir-slide {
  position: absolute;
  top: 50%;
  right: 4px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
  height: 26px;
  color: var(--cr-common-black);
  background-color: var(--cr-common-white);
  border-radius: 30px;
  -webkit-transform: translate3d(0, 0);
  transform: translate3d(0, 0);
  -webkit-transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  -moz-transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  -ms-transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  z-index: -1;
}

.cr-theme-color-item.active button::before {
  opacity: 1;
  visibility: visible;
}

.cr-theme-color-btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: relative;
}

.cr-theme-color-btn::before {
  position: absolute;
  content: "\f00c";
  font-weight: 600;
  font-family: var(--cr-ff-fontawesome);
  color: var(--cr-common-white);
  font-size: 16px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
  opacity: 0;
  visibility: hidden;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#F50963"] {
  background-color: #F50963;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#008080"] {
  background-color: #008080;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#F31E5E"] {
  background-color: #F31E5E;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#AB6C56"] {
  background-color: #AB6C56;
  color: black;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#4353FF"] {
  background-color: #4353FF;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#3661FC"] {
  background-color: #3661FC;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#2CAE76"] {
  background-color: #2CAE76;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#FF5A1B"] {
  background-color: #FF5A1B;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#03041C"] {
  background-color: #03041C;
}

.cr-theme-color-btn.cr-color-settings-btn[data-color="#ED212C"] {
  background-color: #ED212C;
}

.cr-theme-color-input {
  margin-top: 15px;
}

.cr-theme-color-input h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.cr-theme-color-input label {
  display: inline-block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: var(--cr-theme-1);
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}

.cr-theme-color-input label:hover {
  cursor: pointer;
}

.cr-theme-color-input input {
  display: none;
}

/*----------------------------------------*/
/*   Buttons
/*----------------------------------------*/
button:disabled,
button[disabled]{
  background-color: #d1d1d1;
  cursor: not-allowed;
}
/* theme btn */
.cr-btn {
  height: 48px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-family: var(--cr-ff-heading);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 30px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  /* display: inline-block; */
  color: var(--cr-common-white);
  background: var(--cr-theme-primary);
  border-radius: 7px;
  border: 1px solid transparent;
}

.cr-btn::after {
  position: absolute;
  /* content: ""; */
  background: var(--cr-theme-top-secondary);
  height: 34px;
  width: 36px;
  border-radius: 15px;
  right: -11px;
  bottom: -11px;
}

.cr-btn::before {
  content: "";
  position: absolute;
  height: 400px;
  width: 430px;
  top: 50%;
  left: 50%;
  background: var(--cr-theme-top-secondary);
  border-radius: 50%;
  transform: translateX(-22%) translateY(-42%) scale(0);
  -moz-transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  z-index: -1;
}

.cr-btn:hover {
  color: var(--cr-common-black);
  border: 1px solid #cccccc;
}

.cr-btn:hover::before {
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.cr-btn i {
  margin-left: 16px;
  transform: translateY(2px);
}

.cr-btn-2 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-family: var(--cr-ff-heading);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 24px 72px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  display: inline-block;
  color: var(--cr-common-white);
  background: var(--cr-theme-secondary);
}

.cr-btn-2::after {
  position: absolute;
  content: "";
  background: var(--cr-theme-primary);
  height: 34px;
  width: 36px;
  border-radius: 15px;
  right: -11px;
  bottom: -11px;
}

.cr-btn-2::before {
  content: "";
  position: absolute;
  height: 400px;
  width: 430px;
  top: 50%;
  left: 50%;
  background: var(--cr-theme-primary);
  border-radius: 50%;
  transform: translateX(-22%) translateY(-42%) scale(0);
  -moz-transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  z-index: -1;
}

.cr-btn-2:hover {
  color: var(--cr-theme-secondary);
}

.cr-btn-2:hover::before {
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.cr-btn-2 i {
  margin-left: 16px;
  transform: translateY(2px);
}

.cr-btn-3 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-family: var(--cr-ff-heading);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 21px 48px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  display: inline-block;
  color: var(--cr-common-white);
  background: var(--cr-theme-primary);
}

.cr-btn-3::after {
  position: absolute;
  content: "";
  background: var(--cr-common-white);
  height: 34px;
  width: 36px;
  border-radius: 15px;
  right: -11px;
  bottom: -11px;
}

.cr-btn-3::before {
  content: "";
  position: absolute;
  height: 400px;
  width: 430px;
  top: 50%;
  left: 50%;
  background: var(--cr-common-white);
  border-radius: 50%;
  transform: translateX(-22%) translateY(-42%) scale(0);
  -moz-transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  z-index: -1;
}

.cr-btn-3:hover {
  color: var(--cr-theme-secondary);
}

.cr-btn-3:hover::before {
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.cr-btn-3 i {
  margin-left: 16px;
  transform: translateY(2px);
}

.cr-btn-hover {
  position: relative;
  z-index: 9;
  overflow: hidden;
}

.cr-btn-hover::after {
  position: absolute;
  content: "";
  background: var(--cr-theme-secondary);
  height: 34px;
  width: 36px;
  border-radius: 15px;
  right: -11px;
  bottom: -11px;
}

.cr-btn-hover b {
  position: absolute;
  width: 0px;
  height: 0px;
  background-color: var(--cr-common-white);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: -1;
}

.cr-btn-hover:hover {
  color: var(--cr-theme-primary);
}

.cr-btn-hover:hover b {
  width: 1000px;
  height: 1000px;
  display: inline-block;
}

.cr-btn-hover.alt-color b {
  background-color: var(--cr-theme-secondary);
}

.cr-btn-hover.alt-bg-blue b {
  background-color: var(--cr-theme-primary);
}

.cr-btn-hover.alt-black-color b {
  background-color: black;
}

.cr-btn-hover.alt-bg-white b {
  background-color: #fff;
}

.cr-btn-hover.alt-bg-red b {
  background-color: var(--cr-theme-red);
}

.cr-btn-hover-clear {
  position: relative;
  z-index: 9;
  overflow: hidden;
}

.cr-btn-hover-clear b {
  position: absolute;
  width: 0px;
  height: 0px;
  background-color: var(--cr-common-white);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: -1;
}

.cr-btn-hover-clear:hover {
  color: var(--cr-common-white);
  border-color: var(--cr-theme-secondary);
}

.cr-btn-hover-clear:hover b {
  width: 500px;
  height: 500px;
  display: inline-block;
}

.cr-btn-hover-clear.alt-color b {
  background-color: var(--cr-theme-secondary);
}

.cr-btn-hover-clear.alt-bg-pink b {
  background-color: #FF3A8A;
}

.cr-btn-hover-clear.alt-black-color b {
  background-color: black;
}

.cr-btn-hover-clear.alt-bg-white b {
  background-color: #fff;
}

.cr-btn-hover-clear.alt-bg-red b {
  background-color: var(--cr-theme-red);
}

/* offcanvas btn */
.cr-btn-offcanvas {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: var(--cr-common-white);
  background: var(--cr-theme-primary);
  text-align: center;
  font-family: var(--cr-ff-space);
  padding: 9px 22px;
  letter-spacing: -0.02em;
  position: relative;
  z-index: 1;
  text-transform: capitalize;
}

.cr-btn-offcanvas i {
  padding-left: 5px;
}

.cr-btn-offcanvas:hover {
  background-color: var(--cr-common-black);
  color: var(--cr-common-white);
}

/*----------------------------------------*/
/*   Animations
/*----------------------------------------*/
/* pulse effect animation */
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes sliderShape {

  0%,
  100% {
    border-radius: 42% 58% 70% 30%/45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }

  34% {
    border-radius: 70% 30% 46% 54%/30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }

  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }

  67% {
    border-radius: 100% 60% 60% 100%/100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

/* 04. ========= btn-arrow-animation ========== */
@keyframes tfLeftToRight {
  49% {
    transform: translateX(30%);
  }

  50% {
    opacity: 0;
    transform: translateX(-30%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes tpupdown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes circle-animation {
  0% {
    transform: rotate(-46deg);
  }

  100% {
    transform: rotate(314deg);
  }
}

@keyframes circle-animation3 {
  0% {
    transform: rotate(60deg);
  }

  100% {
    transform: rotate(420deg);
  }
}

@keyframes circle-animation2 {
  0% {
    transform: scale(-1, 1) rotate(198deg);
  }

  100% {
    transform: scale(-1, 1) rotate(-162deg);
  }
}

@keyframes circle-animation8 {
  0% {
    transform: rotate(-50deg);
  }

  100% {
    transform: rotate(310deg);
  }
}

@keyframes circle-animation88 {
  0% {
    transform: rotate(60deg);
  }

  100% {
    transform: rotate(420deg);
  }
}

@keyframes circle-animation99 {
  0% {
    transform: rotate(60deg);
  }

  100% {
    transform: rotate(420deg);
  }
}

@keyframes circle-6 {
  0% {
    transform: rotate(215deg);
  }

  100% {
    transform: rotate(575deg);
  }
}

@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@-moz-keyframes headerSlideUp {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: -150px;
  }
}

@-ms-keyframes headerSlideUp {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: -150px;
  }
}

@-webkit-keyframes headerSlideUp {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: -150px;
  }
}

@keyframes headerSlideUp {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: -150px;
  }
}

@keyframes section_stroke {
  0% {
    stroke-dashoffset: 560;
  }

  50% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -560;
  }
}

@keyframes tprotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes about-text {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes icon-bounce {

  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

@keyframes cr-hotspot-2 {
  0% {
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes cr-hotspot-3 {
  0% {
    transform: translate(0px, 0px);
  }

  20% {
    transform: translate(20px, -5px);
  }

  40% {
    transform: translate(40px, 20px);
  }

  60% {
    transform: translate(20px, 40px);
  }

  80% {
    transform: translate(-20px, 30px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes moving {
  0% {
    transform: translatey(0px);
  }

  25% {
    transform: translatex(20px);
  }

  50% {
    transform: translatey(-20px);
  }

  75% {
    transform: translatex(-20px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes movingleft {
  0% {
    transform: translatey(0px);
  }

  25% {
    transform: translatey(50px);
  }

  50% {
    transform: translatex(-50px);
  }

  75% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatex(0px);
  }
}

@keyframes movingright {
  0% {
    transform: translatey(0px);
  }

  25% {
    transform: translatey(30px);
  }

  50% {
    transform: translatex(-30px);
  }

  75% {
    transform: translatey(-40px);
  }

  100% {
    transform: translatex(0px);
  }
}

@keyframes jumpTwo {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes tptranslateX2 {
  0% {
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  100% {
    -webkit-transform: translatXY(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
}

@keyframes section-highlight-4 {
  100% {
    width: 100%;
  }
}

@keyframes scale_up_down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes animationglob {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes tpleftright {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-20px);
  }
}

@keyframes tpswing {
  0% {
    transform: rotate(6deg);
  }

  100% {
    transform: rotate(-6deg);
  }
}

/*----------------------------------------*/
/*   Preloader
/*----------------------------------------*/
#loading {
  background-color: var(--cr-common-black);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  margin-top: 0px;
  top: 0px;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 200px;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.object {
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  border-top: 5px solid #FFF;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #FFF;
  border-right: 5px solid transparent;
  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}

#object_one {
  left: 75px;
  top: 75px;
  width: 50px;
  height: 50px;
}

#object_two {
  left: 65px;
  top: 65px;
  width: 70px;
  height: 70px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

#object_three {
  left: 55px;
  top: 55px;
  width: 90px;
  height: 90px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

#object_four {
  left: 45px;
  top: 45px;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@keyframes animate {
  50% {
    -ms-transform: rotate(360deg) scale(0.8);
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
}

/*----------------------------------------*/
/*   Background
/*----------------------------------------*/
.grey-bg {
  background: var(--cr-grey-1);
}

.grey-bg-2 {
  background: var(--cr-grey-2);
}

.white-bg {
  background: var(--cr-common-white);
}

.black-bg {
  background: var(--cr-common-black);
}

/*----------------------------------------*/
/*   Carousel
/*----------------------------------------*/
/* tp range slider css */
.cr-range-slider .inside-slider {
  padding-left: 7px;
  padding-right: 17px;
}

.cr-range-slider .noUi-target {
  background-color: #191C3C;
  border-radius: 12px;
  border: 0;
  box-shadow: none;
}

.cr-range-slider .noUi-connect {
  background-color: var(--cr-theme-primary);
}

.cr-range-slider .noUi-horizontal {
  height: 6px;
}

.cr-range-slider .noUi-handle {
  height: 24px;
  width: 24px;
  background-color: var(--cr-theme-primary);
  border-radius: 50%;
  border: 4px solid var(--cr-common-white);
  box-shadow: 0px 4px 10px rgba(5, 9, 43, 0.3);
  top: -9px;
}

.cr-range-slider .noUi-handle:hover {
  cursor: pointer;
}

.cr-range-slider .noUi-handle::before,
.cr-range-slider .noUi-handle::after {
  display: none;
}

.cr-range-slider-dark .noUi-handle {
  border: 4px solid #2D314B;
}

/* tp swiper slider dot */
.cr-swiper-dot .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: var(--cr-grey-1);
  opacity: 1;
  margin: 0 9px;
  position: relative;
}

.cr-swiper-dot .swiper-pagination-bullet::after {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.cr-swiper-dot .swiper-pagination-bullet button {
  font-size: 0;
}

.cr-swiper-dot .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--cr-theme-primary);
}

.cr-swiper-dot .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  border-color: var(--cr-theme-primary);
  transform: translate(-50%, -50%) scale(1);
}

.cr-swiper-arrow {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  text-align: center;
  background-color: var(--cr-common-white);
  color: var(--cr-common-black);
}

.cr-swiper-arrow:hover {
  background-color: var(--cr-common-black);
  color: var(--cr-common-white);
}

/* tp slick arrow */
/*----------------------------------------*/
/* Nice Select
/*----------------------------------------*/
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #999;
}

.nice-select::after {
  background-color: transparent;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 0;
  right: 0;
  content: "\f107";
  font-family: var(--cr-ff-fontawesome);
  transform-origin: center;
  color: var(--cr-common-black);
  font-weight: 500;
  height: auto;
  width: auto;
}

.nice-select.open::after {
  -webkit-transform: translateY(-50%) rotate(-180deg);
  -moz-transform: translateY(-50%) rotate(-180deg);
  -ms-transform: translateY(-50%) rotate(-180deg);
  -o-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled::after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small::after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

/*----------------------------------------*/
/*   Pagination
/*----------------------------------------*/
.cr-pagination ul li {
  display: inline-block;
}

.cr-pagination ul li:not(:last-child) {
  margin-right: 10px;
}

.cr-pagination ul li a,
.cr-pagination ul li span {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 46px;
  text-align: center;
  border-radius: 7px;
  border: 2px solid #f1f1f1;
  font-size: 18px;
  font-weight: 600;
}

.cr-pagination ul li a:hover,
.cr-pagination ul li a.current,
.cr-pagination ul li span:hover,
.cr-pagination ul li span.current {
  background: var(--cr-theme-primary);
  border-color: var(--cr-theme-primary);
  color: var(--cr-common-white);
}

/*----------------------------------------*/
/*   Offcanvas
/*----------------------------------------*/
.offcanvas__area {
  position: fixed;
  left: 0;
  top: 0;
  width: 400px;
  height: 100%;
  -webkit-transform: translateX(calc(-100% - 80px));
  -moz-transform: translateX(calc(-100% - 80px));
  -ms-transform: translateX(calc(-100% - 80px));
  -o-transform: translateX(calc(-100% - 80px));
  transform: translateX(calc(-100% - 80px));
  background: var(--cr-common-white) none repeat scroll 0 0;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scrollbar-width: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .offcanvas__area {
    right: 0;
    left: auto;
    -webkit-transform: translateX(calc(100% + 80px));
    -moz-transform: translateX(calc(100% + 80px));
    -ms-transform: translateX(calc(100% + 80px));
    -o-transform: translateX(calc(100% + 80px));
    transform: translateX(calc(100% + 80px));
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__area.home-2-pos {
    right: 0;
    left: auto;
    -webkit-transform: translateX(calc(100% + 80px));
    -moz-transform: translateX(calc(100% + 80px));
    -ms-transform: translateX(calc(100% + 80px));
    -o-transform: translateX(calc(100% + 80px));
    transform: translateX(calc(100% + 80px));
  }
}

.offcanvas__area.home-3-pos {
  right: 0;
  left: auto;
  -webkit-transform: translateX(calc(100% + 80px));
  -moz-transform: translateX(calc(100% + 80px));
  -ms-transform: translateX(calc(100% + 80px));
  -o-transform: translateX(calc(100% + 80px));
  transform: translateX(calc(100% + 80px));
}

.offcanvas__area::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.offcanvas__area.offcanvas-opened {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

@media (max-width: 575px) {
  .offcanvas__area {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .offcanvas__top {
    margin-bottom: 30px;
  }
}

.offcanvas__wrapper {
  position: relative;
  padding: 40px;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

@media (max-width: 575px) {
  .offcanvas__wrapper {
    padding: 20px;
  }
}

.offcanvas__close {
  position: absolute;
  /* top: 35px; */
  /* left: 70%; */
  right: 12px;
}

@media (max-width: 575px) {
  .offcanvas__close {
    /* left: 70%; */
    /* top: 20px; */
    right: 12px;
  }
}

.offcanvas__close-btn {
  display: inline-block;
  font-size: 16px;
  height: 44px;
  width: 44px;
  line-height: 40px;
  background-color: #f5f5f5;
  color: var(--cr-text-1);
}

.offcanvas__close-btn svg {
  transition: all 0.3s ease-in-out;
}

.offcanvas__close-btn:hover svg {
  transform: rotate(45deg);
}

.offcanvas__close-btn svg {
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}

.offcanvas__inner h4 {
  font-family: var(--cr-ff-space);
  font-size: 24px;
  color: var(--cr-common-white);
  line-height: 1.3;
  margin-bottom: 15px;
}

.offcanvas__inner p {
  font-family: var(--cr-ff-inter);
  font-size: 16px;
  line-height: 22px;
  color: var(--cr-common-white);
  margin-bottom: 30px;
}

.offcanvas__inner>img {
  margin-bottom: 30px;
}

.offcanvas__menu ul li {
  list-style: none;
  margin-bottom: 10px;
}

.offcanvas__menu ul li:last-child {
  margin-bottom: 0;
}

.offcanvas__menu ul li a {
  font-weight: 700;
  font-size: 20px;
  color: var(--cr-common-black);
}

.offcanvas__menu ul li:hover>a {
  color: var(--cr-theme-primary);
}

.offcanvas__text p {
  font-family: var(--cr-ff-inter);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.01em;
  color: var(--cr-common-black-4);
  margin-bottom: 25px;
}

.offcanvas__contact {
  margin-bottom: 30px;
}

.offcanvas__contact-content {
  margin-bottom: 10px;
}

.offcanvas__contact-content-icon i {
  color: #6EC1E4;
  margin-right: 10px;
}

.offcanvas__contact-content-content a {
  font-size: 16px;
}

.offcanvas__social {
  margin-bottom: 22px;
  padding-bottom: 40px;
}

.offcanvas__social .icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: transparent;
  color: var(--cr-common-white);
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
}

.offcanvas__social .icon.facebook {
  background-color: #4661C5;
}

.offcanvas__social .icon.twitter {
  background-color: #4CCEF9;
}

.offcanvas__social .icon.youtube {
  background-color: #FF0000;
}

.offcanvas__social .icon.linkedin {
  background-color: #0072b1;
}

.offcanvas__social .icon:hover {
  transform: translateY(-8px);
}

.offcanvas__title {
  font-size: 24px;
  color: #1b1d21;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .offcanvas__popup {
    display: none;
  }
}

.offcanvas__popup p {
  margin-bottom: 20px;
}

.offcanvas__popup-gallery {
  margin-bottom: 30px;
}

.offcanvas__popup-gallery a {
  margin: 5px 3px;
  display: inline-block;
}

.offcanvas__popup-gallery a img {
  height: 100px;
  max-width: 100px;
  object-fit: cover;
}

.offcanvas__info-item {
  margin-bottom: 38px;
}

.offcanvas__info-item-title {
  font-family: var(--cr-ff-space);
  font-weight: 700;
  font-size: 18px;
  color: var(--cr-common-white);
  margin-bottom: 12px;
}

.offcanvas__info-item p {
  font-size: 16px;
  color: var(--cr-text-16);
  margin-bottom: 0;
  line-height: 22px;
}

.offcanvas__info-item p a:hover {
  color: var(--cr-theme-primary);
}

/*----------------------------------------*/
/*   Breadcrumb
/*----------------------------------------*/
.breadcrumb__area {
  background-image: url("../img/all/bredcame-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.breadcrumb__title {
  position: relative;
  font-weight: 700;
  font-size: 52px;
  line-height: 50px;
  color: #121D2C;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.breadcrumb__title img {
  position: absolute;
  bottom: -5px;
  right: 35%;
  z-index: -1;
}

@media (max-width: 575px) {
  .breadcrumb__title img {
    bottom: -10px;
    right: 27%;
  }
}

.breadcrumb__title-pre {
  display: inline-block;
  height: 24px;
  line-height: 26px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  background: var(--cr-theme-2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 0 7px;
  margin-bottom: 12px;
}

.breadcrumb__list span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3D3838;
  padding-right: 3px;
  margin-right: 3px;
  text-transform: capitalize;
}

.breadcrumb__list span a:hover {
  color: var(--cr-theme-primary);
}

/*----------------------------------------*/
/*   Accordion
/*----------------------------------------*/
.cr-accordion .accordion-item {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgba(var(--cr-common-black), 1);
}

.cr-accordion .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cr-accordion .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.cr-accordion .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.cr-accordion .accordion-button {
  padding: 30px 0;
  padding-right: 15px;
  font-size: 24px;
  font-weight: 500;
  color: var(--cr-common-black);
  background-color: transparent;
  /* plus icon css */
}

.cr-accordion .accordion-button::after {
  position: absolute;
  content: "\f106";
  font-family: var(--cr-ff-fontawesome);
  background-image: none;
  top: 28%;
  right: 0;
  transform: rotate(360deg);
  color: var(--cr-theme-primary);
  width: auto;
  height: auto;
  font-weight: 500;
  background: none;
}

.cr-accordion .accordion-button.has-plus-icon::after {
  display: none;
}

.cr-accordion .accordion-button .cr-accordion-plus {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
  width: 14px;
  height: 14px;
}

[dir=rtl] .cr-accordion .accordion-button .cr-accordion-plus {
  right: auto;
  left: 0;
}

.cr-accordion .accordion-button .cr-accordion-plus::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #525258;
  border-radius: 2px;
}

[dir=rtl] .cr-accordion .accordion-button .cr-accordion-plus::after {
  right: 0;
  left: auto;
}

.cr-accordion .accordion-button .cr-accordion-plus::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  left: 50%;
  top: 0;
  background-color: #525258;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 2px;
}

[dir=rtl] .cr-accordion .accordion-button .cr-accordion-plus::before {
  right: 50%;
  left: auto;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
  transform: translateX(50%);
}

.cr-accordion .accordion-button.collapsed::after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--cr-common-black);
}

.cr-accordion .accordion-button:not(.collapsed) {
  color: var(--cr-theme-primary);
  background-color: transparent;
  box-shadow: none;
}

.cr-accordion .accordion-button:not(.collapsed) .cr-accordion-plus::before {
  -webkit-transform: translateX(-50%) rotate(90deg);
  -moz-transform: translateX(-50%) rotate(90deg);
  -ms-transform: translateX(-50%) rotate(90deg);
  -o-transform: translateX(-50%) rotate(90deg);
  transform: translateX(-50%) rotate(90deg);
  background-color: var(--cr-theme-1);
}

[dir=rtl] .cr-accordion .accordion-button:not(.collapsed) .cr-accordion-plus::before {
  -webkit-transform: translateX(50%) rotate(90deg);
  -moz-transform: translateX(50%) rotate(90deg);
  -ms-transform: translateX(50%) rotate(90deg);
  -o-transform: translateX(50%) rotate(90deg);
  transform: translateX(50%) rotate(90deg);
}

.cr-accordion .accordion-button:not(.collapsed) .cr-accordion-plus::after {
  background-color: var(--cr-theme-1);
}

.cr-accordion .accordion-button:focus {
  box-shadow: none;
  border: 0;
}

.cr-accordion .accordion-button:hover {
  color: var(--cr-theme-primary);
}

.cr-accordion .accordion-button:hover::after {
  color: var(--cr-theme-primary);
}

.cr-accordion .accordion-body {
  padding: 0 0 25px;
}

.cr-accordion .accordion-body p {
  font-size: 16px;
  color: var(--cr-text-1);
}

/*----------------------------------------*/
/*   Tab
/*----------------------------------------*/
.cr-tab .nav-tabs {
  padding: 0;
  margin: 0;
  border: 0;
}

.cr-tab .nav-tabs .nav-link {
  padding: 0;
  margin: 0;
  border: 0;
}

/*----------------------------------------*/
/*   Section Title
/*----------------------------------------*/
.cr-section-title {
  position: relative;
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 50px;
  color: var(--cr-theme-primary);
}

@media (max-width: 575px) {
  .cr-section-title {
    font-size: 30px;
  }
}

.cr-section-title i {
  font-style: normal;
  color: var(--cr-theme-secondary);
}

.cr-section-title .title-shape {
  position: absolute;
  left: 0;
  color: var(--cr-theme-secondary);
  bottom: -22px;
}

.cr-section-title .title-color {
  color: var(--cr-theme-secondary);
}

.cr-section-title .title-left-shape {
  position: absolute;
  left: 0;
  bottom: -25px;
}

.cr-section-title .title-right-shape {
  position: absolute;
  right: 60px;
  bottom: -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-section-title .title-right-shape {
    display: none;
  }
}

.cr-section-title .title-center-shape {
  position: absolute;
  bottom: -20px;
  left: 47%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-section-title .title-center-shape {
    left: 40%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-section-title .title-center-shape {
    left: 30%;
  }
}

@media (max-width: 575px) {
  .cr-section-title .title-center-shape {
    left: 26%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-section-title {
    margin-bottom: 30px;
  }
}

.cr-section-title__pre {
  position: relative;
  font-family: var(--cr-ff-body);
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--cr-text-2);
  display: inline-block;
  margin-bottom: 22px;
}

.cr-section-title__pre .title-pre-color {
  color: var(--cr-theme-secondary);
}

.cr-section-title__pre svg {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--cr-theme-secondary);
}


/*----------------------------------------*/
/*  Header Style
/*----------------------------------------*/
.cr-header-space {
  padding-left: 152px;
  padding-right: 162px;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-header-space {
    padding-left: 100px;
    padding-right: 110px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-header-space {
    padding-left: 30px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-header-space {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.cr-header-logo a {
  position: relative;
  z-index: 5;
}

.cr-header-logo .logo-shape {
  position: absolute;
  bottom: -50px;
  left: 55%;
  z-index: 2;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-header-logo .logo-shape {
    left: 47%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-header-logo .logo-shape {
    left: 40%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-header-logo .logo-shape {
    bottom: -40px;
    left: 5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-header-logo .logo-shape {
    bottom: -30px;
    left: -5px;
  }
}

@media (max-width: 575px) {
  .cr-header-logo .logo-shape {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-header-logo {
    padding-left: 40px;
    height: 100px;
    line-height: 100px;
  }
}

.cr-header-top {
  background-color: var(--cr-theme-primary);
  padding-top: 5px;
  padding-bottom: 5px;
}

.cr-header-top-info p {
  font-weight: 400;
  font-size: 14px;
  color: var(--cr-common-white);
  margin-bottom: 0;
}

.cr-header-top-info p i {
  color: var(--cr-theme-top-secondary);
  margin-right: 5px;
}

.cr-header-top-info p span {
  color: var(--cr-theme-top-secondary);
}

.cr-header-top-right .header-social {
  position: relative;
}

.cr-header-top-right .header-social::after {
  /* content: ''; */
  width: 1px;
  height: 100%;
  background: var(--cr-grey-2);
  position: absolute;
  top: 0;
  left: 0;
}

.cr-header-top-right .header-social a {
  font-weight: 400;
  font-size: 16px;
  color: var(--cr-common-white);
  margin-left: 12px;
}

.cr-header-top-right .header-social a i {
  transition: 0.3s;
}

.cr-header-top-right .header-social a:hover i {
  color: var(--cr-theme-top-secondary);
}

.cr-header-top-right .header-date p {
  font-weight: 400;
  font-size: 14px;
  color: var(--cr-common-white);
  margin-bottom: 0;
  margin-right: 25px;
}

.cr-header-top-right .header-date p i {
  color: var(--cr-theme-top-secondary);
  margin-right: 5px;
}

.cr-header-top-right .header-location {
  margin-right: 18px;
}

.cr-header-top-right .header-location a {
  font-weight: 400;
  font-size: 14px;
  color: var(--cr-common-white);
}

.cr-header-top-right .header-location span {
  font-weight: 400;
  font-size: 14px;
  color: var(--cr-common-white);
}

.cr-header-top-right .header-location a i {
  color: var(--cr-theme-top-secondary);
  margin-right: 5px;
}

.cr-header-top-right .header-location .icon,
.cr-header-top-right .header-social .icon {
  color: var(--cr-theme-top-secondary);
  margin-right: 7px;
}

.cr-header-top-right .header-social .icon {
  cursor: pointer;
}

.cr-header-main-right .cr-header-search {
  height: 109px;
  width: 160px;
  text-align: center;
  line-height: 109px;
  background-color: var(--cr-theme-top-secondary);
  cursor: pointer;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-header-main-right .cr-header-search {
    height: 100px;
    width: 120px;
    text-align: center;
    line-height: 100px;
  }
}

.cr-header-main-right .cr-header-search i {
  font-size: 20px;
  color: var(--cr-common-white);
}

.cr-header-phone-icon {
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 32px;
  color: var(--cr-theme-secondary);
  border: 1px solid var(--cr-theme-secondary);
  border-radius: 50%;
  margin-right: 15px;
  flex: 0 0 auto;
}

.cr-header-phone-content {
  position: relative;
  margin-right: 26px;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-header-phone-content {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-header-phone-content {
    margin-right: 0px;
  }
}

.cr-header-phone-content::after {
  content: "";
  position: absolute;
  top: -20px;
  right: -26px;
  height: 99px;
  width: 1px;
  background: #EFEBEB;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-header-phone-content::after {
    right: -20px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-header-phone-content::after {
    right: -12px;
  }
}

.cr-header-phone-content span {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #0B0D0E;
}

.cr-header-phone-content span a {
  font-size: 16px;
  color: var(--cr-theme-secondary);
}

.cr-header-btn {
  margin-right: 27px;
  margin-left: 33px;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-header-btn {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-header-btn {
    margin-right: 10px;
    margin-left: 20px;
  }
}

.cr-header-btn a {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  color: var(--cr-heading-secondary);
}

.cr-header-btn a::before {
  position: absolute;
  content: "";
  background: var(--cr-heading-secondary);
  height: 1px;
  width: 103px;
  left: 0;
  bottom: 0;
}

.cr-header-btn a i {
  margin-left: 12px;
}

.cr-header-btn a:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.cr-header-hamburger-btn {
  background: var(--cr-common-white);
  width: 175px;
  height: 106px;
  line-height: 106px;
  margin-right: 0;
  text-align: center;
  cursor: pointer;
}
.cr-header-hamburger-btn .hamburger-btn .icon {
  color: var(--cr-theme-secondary);
  font-size: 30px;
}

/* .cr-header-hamburger-btn .hamburger-btn span {
  color: var(--cr-theme-secondary);
} */

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-header-hamburger-btn {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 0;
    width: 150px;
    height: 100px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-header-hamburger-btn {
    width: 70px;
  }
}

/*----------------------------------------*/
/*  Main menu css
/*----------------------------------------*/
.cr-main-menu {
  position: relative;
  padding-left: 25px;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-main-menu-area {
    justify-content: center;
  }
}

.cr-main-menu-area .header-icon {
  position: absolute;
  left: 0;
  top: 35%;
}

.cr-main-menu-area.counter ul li a span {
  transform-origin: left bottom;
  will-change: transform;
  transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, -webkit-transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.cr-main-menu-area.counter ul li a span::after {
  position: absolute;
  counter-increment: count;
  content: counter(count, decimal-leading-zero);
  right: 10px;
  top: 25%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: var(--cr-heading-secondary);
  font-family: var(--cr-ff-poppins);
  font-size: 12px;
  font-weight: 500;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-main-menu {
    padding-left: 25px;
    margin-left: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-main-menu {
    margin-left: 50px;
  }
}

.cr-main-menu ul {
  counter-reset: count;
}

.cr-main-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  transition: none;
}

.cr-main-menu ul li:not(:last-of-type) {
  margin-right: 52px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-main-menu ul li:not(:last-of-type) {
    margin-right: 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-main-menu ul li:not(:last-of-type) {
    margin-right: 23px;
  }
}

.cr-main-menu ul li a {
  display: inline-block;
  padding: 30px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  color: var(--cr-text-1);
  position: relative;
}

.cr-main-menu ul li a::before {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.5s transform ease;
  transform: scale3d(0, 1, 1);
  background: var(--cr-theme-secondary);
  transform-origin: 100% 50%;
}

.cr-main-menu ul li a:hover::before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0 50%;
}

.cr-main-menu ul li a i {
  color: var(--cr-theme-secondary);
  margin-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-main-menu ul li a i {
    display: none;
  }
}

.cr-main-menu ul li.has-dropdown>a {
  position: relative;
}

.cr-main-menu ul li.has-dropdown>a::after {
  content: "\f063";
  font-size: 10px;
  color: var(--cr-theme-secondary);
  font-family: var(--cr-ff-fontawesome);
  font-weight: 400;
  margin-left: 10px;
  display: inline-block;
  transition: 0.3s;
}

.cr-main-menu ul li .submenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  background: var(--cr-common-white);
  padding: 0 0 14px 0;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
}

.cr-main-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 25px;
  transform: translateY(11px);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  transition-delay: 0s;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  opacity: 0;
}

.cr-main-menu ul li .submenu li.has-dropdown>a::after {
  position: absolute;
  top: 50%;
  right: 25px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}

.cr-main-menu ul li .submenu li:last-child a {
  border-bottom: 0;
}

.cr-main-menu ul li .submenu li a {
  position: relative;
  padding: 10px 0px;
  font-size: 13px;
  color: var(--cr-common-black);
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.cr-main-menu ul li .submenu li a span {
  transition: all 0.3s ease-in-out;
}

.cr-main-menu ul li .submenu li a span::after {
  display: none;
}

.cr-main-menu ul li .submenu li a::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
  height: 2px;
  width: 0;
  background: var(--cr-theme-secondary);
  transition: all 0.3s ease-in-out;
}

.cr-main-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}

.cr-main-menu ul li .submenu li .submenu>li {
  transform: translateY(11px);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.cr-main-menu ul li .submenu li:hover>a {
  color: var(--cr-theme-secondary);
}

.cr-main-menu ul li .submenu li:hover>a span {
  margin-left: 14px;
}

.cr-main-menu ul li .submenu li:hover>a::after {
  color: var(--cr-common-white);
}

.cr-main-menu ul li .submenu li:hover>a::before {
  width: 10px;
}

.cr-main-menu ul li .submenu li:hover>.submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}

.cr-main-menu ul li:hover>a {
  color: var(--cr-theme-secondary);
}

.cr-main-menu ul li:hover>a::after {
  color: var(--cr-theme-primary);
}

.cr-main-menu ul li:hover .submenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}

.cr-main-menu ul li:hover .submenu>li {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transition-delay: 70ms;
  -moz-transition-delay: 70ms;
  -ms-transition-delay: 70ms;
  -o-transition-delay: 70ms;
  transition-delay: 70ms;
}

.cr-main-menu ul li:hover .submenu>li:nth-child(2) {
  -webkit-transition-delay: 140ms;
  -moz-transition-delay: 140ms;
  -ms-transition-delay: 140ms;
  -o-transition-delay: 140ms;
  transition-delay: 140ms;
}

.cr-main-menu ul li:hover .submenu>li:nth-child(3) {
  -webkit-transition-delay: 210ms;
  -moz-transition-delay: 210ms;
  -ms-transition-delay: 210ms;
  -o-transition-delay: 210ms;
  transition-delay: 210ms;
}

.cr-main-menu ul li:hover .submenu>li:nth-child(4) {
  -webkit-transition-delay: 280ms;
  -moz-transition-delay: 280ms;
  -ms-transition-delay: 280ms;
  -o-transition-delay: 280ms;
  transition-delay: 280ms;
}

.cr-main-menu ul li:hover .submenu>li:nth-child(5) {
  -webkit-transition-delay: 350ms;
  -moz-transition-delay: 350ms;
  -ms-transition-delay: 350ms;
  -o-transition-delay: 350ms;
  transition-delay: 350ms;
}

.cr-main-menu ul li:hover .submenu>li:nth-child(6) {
  -webkit-transition-delay: 420ms;
  -moz-transition-delay: 420ms;
  -ms-transition-delay: 420ms;
  -o-transition-delay: 420ms;
  transition-delay: 420ms;
}

.cr-main-menu ul li:hover .submenu>li:nth-child(7) {
  -webkit-transition-delay: 490ms;
  -moz-transition-delay: 490ms;
  -ms-transition-delay: 490ms;
  -o-transition-delay: 490ms;
  transition-delay: 490ms;
}

.cr-main-menu ul li:hover .submenu>li:nth-child(8) {
  -webkit-transition-delay: 560ms;
  -moz-transition-delay: 560ms;
  -ms-transition-delay: 560ms;
  -o-transition-delay: 560ms;
  transition-delay: 560ms;
}

.cr-main-menu ul li:hover .submenu>li:nth-child(9) {
  -webkit-transition-delay: 630ms;
  -moz-transition-delay: 630ms;
  -ms-transition-delay: 630ms;
  -o-transition-delay: 630ms;
  transition-delay: 630ms;
}

.cr-main-menu-2-area {
  padding-left: 135px;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-main-menu-2-area {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-main-menu-2-area {
    padding-left: 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-main-menu-2-area {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-main-menu-2-area .cr-main-menu {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-main-menu-2-area .cr-main-menu ul li {
    margin-right: 40px;
  }
}

.cr-main-menu-2-area .cr-main-menu ul li.has-dropdown>a::after {
  color: var(--cr-theme-primary);
}

.cr-main-menu-2-area .cr-main-menu ul li:hover.has-dropdown>a::after {
  color: var(--cr-theme-secondary);
}

.cr-main-menu-3-area {
  position: relative;
  background: var(--cr-common-white);
  margin: 0 140px;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-main-menu-3-area {
    margin: 0 85px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-main-menu-3-area {
    margin: 0 40px;
  }
}

.cr-main-menu-3-area::before {
  position: absolute;
  content: "";
  width: calc(100% + 16px);
  height: 89px;
  background: var(--cr-theme-secondary);
  top: 20px;
  left: -8px;
  z-index: -1;
}

.cr-main-menu-3-area .cr-main-menu {
  padding-right: 145px;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px),
only screen and (min-width: 1701px) and (max-width: 1800px) {
  .cr-main-menu-3-area .cr-main-menu {
    padding-right: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-main-menu-3-area .cr-main-menu {
    padding-right: 60px;
    margin-left: 0;
  }
}

.cr-main-menu-3-area .cr-main-menu.menu-icon .header-icon {
  position: absolute;
  top: 35%;
  left: 0;
}

.cr-main-menu-3-area .cr-main-menu ul li a {
  padding: 37px 0;
}

.cr-main-menu-3-area .cr-header-search a {
  position: relative;
  font-size: 20px;
  color: var(--cr-heading-secondary);
}

.cr-main-menu-3-area .cr-header-search a::after {
  position: absolute;
  content: "";
  height: 41px;
  width: 1px;
  background: #D9D9D9;
  left: -48px;
  top: -5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-main-menu-3-area .cr-header-search a::after {
    left: -30px;
  }
}

/*----------------------------------------*/
/*   Meanmenu css
/*----------------------------------------*/
.mean-remove {
  display: none !important;
}

.mean-container {
  margin-bottom: 40px;
}

.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px 13px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  font-weight: 700;
  display: none !important;
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
  float: left;
  width: 100%;
}

.mean-container .mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

.mean-container .mean-nav>ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container .mean-nav>ul>li:first-child>a {
  border-top: 0;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.mean-container .mean-nav ul li.dropdown-opened>a.mean-expand.mean-clicked,
.mean-container .mean-nav ul li.dropdown-opened>span.mean-expand.mean-clicked {
  color: var(--cr-common-white);
}

.mean-container .mean-nav ul li.dropdown-opened>a.mean-expand.mean-clicked i,
.mean-container .mean-nav ul li.dropdown-opened>span.mean-expand.mean-clicked i {
  color: var(--cr-common-black);
}

.mean-container .mean-nav ul li.mean-last {
  border-bottom: none;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li>a.mean-expand i {
  display: inline-block;
}

/* .mean-container .mean-nav ul li>a>i {
  display: none;
} */

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 12px 0 !important;
  margin: 0;
  text-align: left;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  padding: 10px 0;
  color: #4a4a4a;
  border-top: 1px solid #ebebeb;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
}

.mean-container .mean-nav ul li a.active {
  color: var(--cr-theme-secondary);
}

[dir=rtl] .mean-container .mean-nav ul li a {
  float: right;
  text-align: right;
}

.mean-container .mean-nav ul li a:hover {
  color: var(--cr-theme-primary);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 1px;
  width: 26px;
  height: 32px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: transparent;
  border: none !important;
  font-size: 14px;
  margin-top: 5px;
  padding: 0 !important;
  line-height: 14px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #4a4a4a;
  line-height: 30px;
  top: 0;
  font-weight: 400;
}

[dir=rtl] .mean-container .mean-nav ul li a.mean-expand {
  right: auto;
  left: 0;
  text-align: center;
}

.mean-container .mean-nav ul li a.mean-expand i {
  transition: all 0.3s ease-in-out;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  color: var(--cr-common-black);
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked:hover {
  border-color: var(--cr-theme-primary);
}

.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 10px 5%;
  text-shadow: none !important;
  visibility: visible;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 10px 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 10px 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 10px 25%;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
  min-height: 42px;
  z-index: 999999;
  margin-bottom: 50px;
}

.mean-container .mean-bar,
.mean-container .mean-bar * {
  /* Fix for box sizing on Foundation Framework etc. */
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/*----------------------------------------*/
/*   Postbox css
/*----------------------------------------*/
.postbox__thumb {
  position: relative;
}

.postbox__thumb .play-btn {
  position: absolute;
  top: 38%;
  left: 44%;
  z-index: 1;
}

.postbox__thumb .play-btn a {
  height: 85px;
  width: 85px;
  line-height: 87px;
}

.postbox__thumb .play-btn a::after {
  display: none;
}

.postbox__item-single:hover {
  box-shadow: none;
}

.postbox__tag {
  position: absolute;
  bottom: 0px;
  left: 0;
}

.postbox__tag p {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #132047;
  letter-spacing: 0.135em;
  background: var(--cr-theme-secondary);
  clip-path: polygon(0px 0px, 100% 0px, 9% 53.45%, 100% 100%, 0px 100%, 0px 50%);
  width: 130px;
}

.postbox__content {
  padding-top: 28px;
}

@media (max-width: 575px) {
  .postbox__content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.postbox__content-single {
  padding-left: 0;
  padding-right: 0;
  border: none;
}

.postbox__title {
  font-weight: 600;
  font-size: 32px;
  color: var(--cr-theme-primary);
  margin-bottom: 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .postbox__title {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .postbox__title {
    font-size: 25px;
  }
}

.postbox__title a:hover {
  color: var(--cr-theme-secondary);
}

.postbox__meta {
  margin-bottom: 20px;
}

.postbox__meta span {
  position: relative;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--cr-theme-primary);
  margin-right: 25px;
}

.postbox__meta span:last-child {
  margin-right: 0;
}

.postbox__meta span:not(:last-of-type)::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 9px;
  background: #676A7D;
  top: 10px;
  right: -20px;
}

.postbox__meta span i {
  color: var(--cr-theme-secondary);
  margin-right: 5px;
}

.postbox__blockquote-shape {
  position: absolute;
  bottom: 34px;
  right: 44px;
}

.postbox__blockquote blockquote {
  background: #F4F6F8;
  padding: 35px 26px;
  margin-bottom: 50px;
  margin-top: 50px;
}

.postbox__blockquote blockquote::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  clip-path: polygon(64% 0, 0 0, 0 68%);
  background: var(--cr-theme-secondary);
  height: 27px;
  width: 31px;
}

.postbox__blockquote blockquote p {
  font-family: var(--cr-ff-heading);
  font-weight: 600;
  font-size: 24px;
  color: #121D2C;
  margin-bottom: 25px;
}

.postbox__blockquote blockquote cite {
  font-weight: 400;
  font-size: 16px;
  color: #332D2D;
  font-style: inherit;
  position: relative;
}

.postbox__blockquote blockquote cite::before {
  content: "";
  font-size: 28px;
  color: #fff;
  padding-bottom: 0px;
  display: inline-block;
  background: #191515;
  height: 2px;
  width: 16px;
  font-weight: 400;
  text-align: center;
  top: -4px;
  margin-right: 10px;
  position: relative;
}

.postbox-details-desc-thumb-caption {
  font-size: 18px;
  font-weight: 500;
  color: #807A7A;
  font-style: italic;
  text-align: center;
  display: block;
  margin-top: 30px;
  margin-bottom: 50px;
}

.postbox__list {
  margin-bottom: 60px;
}

.postbox__list-title {
  font-weight: 600;
  font-size: 28px;
  color: #121D2C;
  margin-bottom: 50px;
}

.postbox__list-content ul li {
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  color: #445658;
  margin-bottom: 24px;
}

.postbox__list-content ul li span {
  height: 27px;
  width: 27px;
  line-height: 25px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  background-color: var(--cr-common-white);
  color: var(--cr-theme-secondary);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  margin-right: 10px;
}

.postbox__list-content ul li span.active {
  background-color: var(--cr-theme-secondary);
  color: var(--cr-common-white);
}

.postbox__details-share-wrapper {
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #F7F7F7;
}

.postbox__details-share span {
  font-size: 20px;
  font-weight: 500;
  color: #121416;
  margin-right: 15px;
}

.postbox__details-share a {
  height: 37px;
  width: 37px;
  text-align: center;
  line-height: 37px;
  display: inline-block;
  background-color: #F2F6F7;
  color: var(--cr-theme-primary);
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__details-share a {
    margin-bottom: 10px;
  }
}

.postbox__details-share a:hover {
  background-color: var(--cr-theme-secondary);
  color: var(--cr-common-white);
}

.postbox__details-tag span {
  font-size: 20px;
  font-weight: 500;
  color: #121416;
  margin-right: 6px;
}

.postbox__read-more .postbox-btn {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #132047;
}

.postbox__read-more .postbox-btn span {
  margin-left: 5px;
}

.postbox__read-more .postbox-btn span::before {
  position: absolute;
  content: "";
  top: 1px;
  right: -10px;
  border: 1px solid #E8F2F9;
  height: 28px;
  width: 28px;
  display: inline-block;
  border-radius: 50%;
}

.postbox__text img {
  max-width: 100%;
}

.postbox__text p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.8;
  color: #838383;
  margin-bottom: 28px;
}

.postbox__text-single p {
  margin-bottom: 15px;
}

.postbox__slider button {
  position: absolute;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 30px;
  color: var(--cr-common-white);
}

.postbox__slider button.postbox-slider-button-next {
  left: auto;
  right: 50px;
}

@media (max-width: 575px) {
  .postbox__slider button.postbox-slider-button-next {
    right: 10px;
  }
}

@media (max-width: 575px) {
  .postbox__slider button {
    left: 10px;
  }
}

.postbox__comment ul li {
  margin-bottom: 10px;
  list-style: none;
}

.postbox__comment ul li.children {
  margin-left: 65px;
}

@media (max-width: 575px) {
  .postbox__comment ul li.children {
    margin-left: 15px;
  }
}

.postbox__comment-form {
  margin-bottom: 40px;
  padding: 65px 45px 80px 45px;
  background: #F8F8F9;
}

@media (max-width: 575px) {
  .postbox__comment-form {
    padding: 20px;
  }
}

.postbox__comment-form-title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 40px;
}

.postbox__comment-input {
  position: relative;
  margin-bottom: 20px;
}

.postbox__comment-input span {
  font-weight: 600;
  color: var(--cr-common-black);
  margin-bottom: 12px;
  display: block;
}

.postbox__comment-input input,
.postbox__comment-input textarea {
  height: 55px;
  padding: 0 20px;
  width: 100%;
  font-size: 14px;
  color: var(--cr-common-black);
  outline: none;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 0px 15px 10px rgba(242, 242, 242, 0.18);
}

.postbox__comment-input input:focus,
.postbox__comment-input textarea:focus {
  border: 1px solid var(--cr-theme-secondary);
}

.postbox__comment-input input:focus::placeholder,
.postbox__comment-input textarea:focus::placeholder {
  font-size: 0;
}

.postbox__comment-input textarea {
  height: 175px;
  resize: none;
  padding-top: 20px;
  padding-bottom: 20px;
}

.postbox__comment-title {
  font-size: 28px;
  color: #121416;
  font-weight: 600;
  margin-bottom: 20px;
}

.postbox__comment-box {
  padding-top: 20px;
}

.postbox__comment-avater img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.postbox__comment-name {
  margin-bottom: 5px;
}

.postbox__comment-name h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  color: #121416;
  margin-bottom: 0;
}

.postbox__comment-name span {
  font-weight: 500;
  font-size: 12px;
  color: #949392;
}

@media (max-width: 575px) {
  .postbox__comment-text {
    margin-left: 0;
    margin-top: 15px;
  }
}

.postbox__comment-text p {
  font-size: 16px;
  color: #838383;
  margin-bottom: 15px;
}

.postbox__comment-reply span {
  position: absolute;
  top: 22px;
  right: 50px;
}

.postbox__comment-agree {
  padding-left: 5px;
}

.postbox__comment-agree input {
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  display: block;
  width: 16px;
  height: 16px;
  background: var(--cr-common-white);
  border: 1px solid #949392;
  outline: none;
  flex: 0 0 auto;
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}

.postbox__comment-agree input:checked {
  position: relative;
  background-color: var(--cr-theme-primary);
  border-color: transparent;
}

.postbox__comment-agree input:checked::after {
  box-sizing: border-box;
  content: "\f00c";
  position: absolute;
  font-family: var(--cr-ff-fontawesome);
  font-size: 10px;
  color: var(--cr-common-white);
  top: 46%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.postbox__comment-agree input:hover {
  cursor: pointer;
}

.postbox__comment-agree label {
  padding-left: 8px;
  color: #838383;
  line-height: 1;
}

.postbox__comment-agree label a {
  color: var(--cr-common-black);
  font-weight: 600;
  padding-left: 4px;
}

.postbox__comment-agree label a:hover {
  color: var(--cr-theme-primary);
}

.postbox__comment-agree label:hover {
  cursor: pointer;
}

.postbox__tag span {
  font-size: 16px;
  margin-bottom: 17px;
  color: var(--cr-common-black);
  margin-right: 10px;
}

.basic-pagination {
  margin-bottom: 40px;
}

.basic-pagination ul li {
  list-style: none;
  display: inline-block;
  margin-right: 14px;
}

@media (max-width: 575px) {
  .basic-pagination ul li {
    margin-right: 5px;
  }
}

.basic-pagination ul li:hover a {
  color: var(--cr-common-white);
  background-color: var(--cr-theme-secondary);
  border: 2px solid var(--cr-theme-secondary);
}

.basic-pagination ul li a {
  height: 50px;
  width: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  font-weight: 900;
  font-size: 15px;
  color: #071C34;
  border: 2px solid #EDEDED;
  transform: translateY(3px);
  transition: all 0.3s ease-in-out;
}

.basic-pagination ul li .current {
  height: 50px;
  width: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  font-weight: 900;
  font-size: 15px;
  color: var(--cr-common-white);
  background-color: var(--cr-theme-secondary);
  transition: all 0.3s ease-in-out;
}

/*----------------------------------------*/
/*   Recent Post css
/*----------------------------------------*/
.rc__post ul li:not(:last-child) {
  margin-bottom: 15px;
}

.rc__post-title {
  font-weight: 600;
  font-size: 16px;
  color: #0D0D0D;
}

.rc__post-title a:hover {
  color: var(--cr-theme-secondary);
}

.rc__meta span {
  font-weight: 400;
  font-size: 15px;
  color: #888686;
  display: block;
  margin-bottom: 7px;
}

/*----------------------------------------*/
/*   Sidebar css
/*----------------------------------------*/
.sidebar__wrapper {
  position: sticky;
  top: 100px;
}

.sidebar__widget {
  padding: 30px 30px;
  background: #F4F6F8;
}

.sidebar__widget-title {
  position: relative;
  display: inline-block;
  font-weight: 600;
  font-size: 22px;
  color: #0A2C3D;
  padding-left: 7px;
  margin-bottom: 30px;
}

.sidebar__widget-title::after {
  position: absolute;
  content: "";
  left: 0px;
  height: 21px;
  width: 2px;
  top: 50%;
  background: var(--cr-theme-secondary);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar__widget ul li {
  list-style: none;
  background: var(--cr-common-white);
  border: 1px solid #EDEDED;
  height: 54px;
  line-height: 54px;
  border-radius: 2px;
  padding: 0 15px;
  margin-bottom: 20px;
}

.sidebar__widget ul li:hover {
  background-color: var(--cr-theme-secondary);
}

.sidebar__widget ul li:hover a {
  color: var(--cr-common-white);
}

.sidebar__widget ul li:last-child {
  margin-bottom: 10px;
}

.sidebar__widget ul li a {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: #445658;
  display: block;
}

.sidebar__widget ul li a span {
  float: right;
}

.sidebar__about {
  padding: 37px 0 38px 0;
}

.sidebar__thumb img {
  border-radius: 50%;
  margin-bottom: 20px;
}

.sidebar__content-title {
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #0A2C3D;
  margin-bottom: 6px;
}

.sidebar__content-designation {
  font-weight: 400;
  font-size: 14px;
  color: #727A7D;
  display: block;
  margin-bottom: 13px;
}

.sidebar__content p {
  font-size: 16px;
  color: #838383;
  margin-bottom: 27px;
}

.sidebar__content-social a {
  height: 37px;
  width: 42px;
  line-height: 37px;
  text-align: center;
  display: inline-block;
  border: 1px solid #E8E8E8;
  margin-right: 12px;
}

.sidebar__content-social a i {
  transition: 0.3s;
}

.sidebar__content-social a:hover {
  border: 1px solid var(--cr-theme-secondary);
  background-color: var(--cr-theme-secondary);
}

.sidebar__content-social a:hover i {
  color: var(--cr-common-white);
}

.sidebar__search {
  position: relative;
}

.sidebar__search input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: var(--cr-common-white);
  padding: 0 25px;
  text-transform: capitalize;
  border: 1px solid #fff;
  outline: none;
  padding-right: 50px;
}

.sidebar__search input:focus {
  border: 1px solid var(--cr-theme-secondary);
}

.sidebar__search button {
  position: absolute;
  top: 0;
  right: 27px;
  height: 100%;
  line-height: 60px;
  color: var(--cr-theme-primary);
}

.sidebar__banner::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.sidebar__banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  background: var(--cr-common-white);
}

.sidebar__banner-content h4 {
  padding: 15px 20px;
  font-size: 24px;
  color: var(--cr-common-black);
  text-transform: uppercase;
  margin-bottom: 0;
}

.tagcloud a {
  font-weight: 400;
  font-size: 14px;
  color: #77787B;
  text-transform: capitalize;
  padding: 6px 22px;
  margin-right: 5px;
  background-color: var(--cr-common-white);
  border: 1px solid #F6F3EE;
  margin-bottom: 14px;
  display: inline-block;
}

.tagcloud a:hover {
  color: var(--cr-common-white);
  background-color: var(--cr-theme-secondary);
}

/*----------------------------------------*/
/*   Footer Style 1
/*----------------------------------------*/
/* footer col design for home 1 */
.cr-footer-area {
  background-color: var(--cr-theme-primary);
}
.cr-footer-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.cr-footer-bg::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: #020C1B;
  opacity: 0.98;
}

.cr-footer-top-area {
  position: relative;
  padding-top: 48px;
  padding-bottom: 45px;
}

.cr-footer-top-shape {
  position: absolute;
  top: 0;
  left: 0;
  height: 160px;
  width: 100%;
  border-bottom: 1px solid rgba(217, 217, 217, 0.08);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-footer-top-shape {
    height: 200px;
  }
}

@media (max-width: 575px) {
  .cr-footer-top-shape {
    height: 250px;
  }
}

.cr-footer-top-contact a {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: var(--cr-common-white);
  background: #0E1825;
  border-radius: 66.5px;
  padding: 10px 24px;
}

@media (max-width: 575px) {
  .cr-footer-top-contact a {
    font-size: 17px;
  }
}

.cr-footer-top-contact a span {
  color: var(--cr-theme-secondary);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-top-right {
    margin-top: 30px;
  }
}

.cr-footer-top-right-headphone img {
  margin-right: 20px;
  transform: translateY(10px);
}

.cr-footer-top-right-content p {
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  text-transform: uppercase;
  color: var(--cr-common-white);
  margin-bottom: -2px;
}

.cr-footer-top-right-content a {
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: var(--cr-theme-secondary);
}

.cr-footer-logo {
  margin-bottom: 30px;
}

.cr-footer-info p {
  font-weight: 400;
  font-size: 16px;
  color: var(--cr-common-white);
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-info p {
    margin-bottom: 20px;
  }
}

.cr-footer-main-area {
  position: relative;
  padding-top: 65px;
  padding-bottom: 52px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.08);
}

.cr-footer-main-location {
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-footer-main-location {
    padding-left: 25px;
  }
}

.cr-footer-main-location a {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: var(--cr-common-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-footer-main-location a br {
    display: none;
  }
}

.cr-footer-main-location a i {
  color: var(--cr-theme-top-secondary);
  font-size: 35px;
  /* position: absolute; */
  top: 10px;
  left: 0px;
}

.cr-footer-main-location a:hover {
  color: var(--cr-common-white);
}

.cr-footer-main-mail {
  position: relative;
  padding-left: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-footer-main-mail {
    padding-left: 30px;
  }
}

.cr-footer-main-mail a {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: var(--cr-common-white);
}

.cr-footer-main-mail a i {
  color: var(--cr-theme-top-secondary);
  font-size: 35px;
  /* position: absolute; */
  top: 10px;
  left: 0px;
}

.cr-footer-main-mail a:hover {
  color: var(--cr-common-white);
}

.cr-footer-widget-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: var(--cr-common-white);
  display: inline-block;
  margin-bottom: 30px;
  letter-spacing: 1px;
}

.cr-footer-widget ul li {
  list-style: none;
}

.cr-footer-widget ul li:not(:last-of-type) {
  margin-bottom: 22px;
}

.cr-footer-widget ul li a {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: var(--cr-common-white);
  transition: 0.3s ease;
}

.cr-footer-widget ul li a::before {
  position: absolute;
  content: "\f105";
  font-family: "Font Awesome 6 Pro";
  top: 10px;
  left: -18px;
  font-size: 18px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--cr-theme-top-secondary);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.cr-footer-widget ul li a:hover {
  margin-left: 14px;
  color: var(--cr-common-white);
}

.cr-footer-widget ul li a:hover::before {
  opacity: 1;
  visibility: visible;
}

.cr-footer-widget-social a {
  font-size: 16px;
  font-weight: 400;
  color: var(--cr-common-white);
  margin-right: 20px;
}

.cr-footer-widget-social a:hover {
  color: var(--cr-theme-secondary);
}

.cr-footer-author {
  margin-bottom: 32px;
}

.cr-footer-author-thumb {
  position: relative;
  z-index: 0;
}

.cr-footer-author-thumb::before {
  position: absolute;
  content: "";
  background-color: var(--cr-theme-secondary);
  height: 12px;
  width: 12px;
  border-radius: 50%;
  right: 24px;
  bottom: 4px;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-footer-author-thumb::before {
    right: 14px;
  }
}

.cr-footer-author-thumb img {
  filter: drop-shadow(0px 17px 28px rgba(72, 88, 174, 0.2));
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-footer-author-thumb img {
    margin-right: 10px;
  }
}

.cr-footer-author-content span {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--cr-common-white);
}

.cr-footer-from {
  position: relative;
  background: #081221;
  padding: 30px 20px 40px 20px;
}

.cr-footer-text-email {
  margin-bottom: 20px;
  color: var(--cr-common-white);
}
.cr-footer-text-email i {
  color: var(--cr-theme-top-secondary);
}
.cr-footer-text-email input {
  position: relative;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(237, 237, 237, 0.3);
  padding-left: 0px;
  padding-right: 35px;
}

.cr-footer-text-email input::placeholder {
  color: #B4B4B4;
}

.cr-footer-text-email input:focus {
  color: var(--cr-common-white);
  border-bottom: 1px solid #fff;
}

.cr-footer-text-email span {
  position: absolute;
  top: 12px;
  right: 2px;
}

.cr-footer-form-check {
  margin-bottom: 30px;
}

.cr-footer-form-check label {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: var(--cr-common-white);
}

.cr-footer-form-check input {
  border: 1px solid var(--cr-common-white);
  background: transparent;
  height: 23px;
  width: 23px;
  transform: translateY(-2px);
  margin-right: 8px;
  border-radius: 50% !important;
}

.cr-footer-form-check input:checked {
  background-color: transparent;
  box-shadow: none;
}

.cr-footer-copyright-area {
  padding-top: 22px;
  padding-bottom: 26px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-copyright-inner {
    margin-bottom: 15px;
  }
}

.cr-footer-copyright-inner p,
.cr-footer-copyright-inner a {
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: var(--cr-common-white);
  margin-bottom: 0;
}

.cr-footer-copyright-inner p span,
.cr-footer-copyright-inner a span {
  color: var(--cr-theme-secondary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-col-1 {
    margin-bottom: 40px;
  }
}

.cr-footer-col-2 {
  padding-left: 65px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-footer-col-2 {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-footer-col-2 {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-col-2 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-col-2 {
    margin-bottom: 40px;
  }
}

.cr-footer-col-3 {
  padding-left: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-footer-col-3 {
    padding-left: 0;
    margin-left: -30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-col-3 {
    padding-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-col-3 {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-footer-col-4 {
    margin-left: -72px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-col-4 {
    margin-left: 0;
  }
}

/*----------------------------------------*/
/*  Footer Style 
/*----------------------------------------*/
/* footer col design for home 2 */
.cr-footer-shape {
  position: absolute;
  top: 0;
  left: 0;
}

.cr-footer-main-area {
  position: relative;
  padding-top: 50px;
  padding-bottom: 60px;
}

.cr-footer-main-area::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(217, 217, 217, 0.08);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-col-1 {
    margin-bottom: 40px;
  }
}

.cr-footer-col-1 .cr-footer-from span {
  position: absolute;
  top: 12px;
  right: 2px;
  font-size: 24px;
  cursor: pointer;
}

.cr-footer-col-2 {
  padding-left: 105px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-footer-col-2 {
    padding-left: 7px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-footer-col-2 {
    margin-bottom: 40px;
    padding-left: 75px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-col-2 {
    padding-left: 0;
    margin-bottom: 40px;
  }
}

.cr-footer-col-3 .p {
  margin-bottom: 34px;
}

.cr-footer-col-3 .cr-footer-main-location {
  margin-bottom: 20px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-logo {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-footer-menu {
    padding-left: 48px;
  }
}

.cr-footer-menu-area {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cr-footer-menu ul li {
  list-style: none;
  display: inline-block;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-menu ul li {
    display: block;
    margin-bottom: 20px;
  }
}

.cr-footer-menu ul li:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-footer-menu ul li:not(:last-child) {
    margin-right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-footer-menu ul li:not(:last-child) {
    margin-right: 25px;
  }
}

.cr-footer-menu ul li a:hover::before {
  opacity: 1;
  visibility: visible;
}

.cr-footer-menu ul li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

.cr-footer-menu ul li a {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--cr-common-white);
}

.cr-footer-menu ul li a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 2px;
  bottom: 0px;
  left: auto;
  right: 0;
  background-color: var(--cr-theme-top-secondary);
  transition: all 0.3s ease-out 0s;
}

/* .cr-footer-menu ul li a::before {
  position: absolute;
  content: "\f105";
  font-family: "Font Awesome 6 Pro";
  top: 10px;
  left: -20px;
  font-size: 18px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--cr-common-white);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
} */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-footer-call {
    margin-top: 30px;
  }
}

.cr-footer-call p {
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  color: #828A8D;
  margin-bottom: -8px;
}

.cr-footer-call span {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--cr-common-white);
}

/*----------------------------------------*/
/*  Footer Style
/*----------------------------------------*/
/* footer col design for home 3 */
.cr-footer-3-main-area {
  padding-top: 140px;
  padding-bottom: 52px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.08);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-3-col-1 {
    margin-bottom: 40px;
  }
}

.cr-footer-3-col-2 {
  padding-left: 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-footer-3-col-2 {
    padding-left: 0;
    margin-bottom: 40px;
  }
}

/*----------------------------------------*/
/*   Slider css
/*----------------------------------------*/
.swiper-slide.swiper-slide-active .cr-hero-title {
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

.swiper-slide.swiper-slide-active .cr-hero-title-wrapper .cr-section-title__pre {
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

.swiper-slide.swiper-slide-active .cr-hero-btn .cr-btn {
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

.swiper-slide.swiper-slide-active .cr-hero-bg {
  visibility: visible;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.swiper-slide.swiper-slide-active .cr-hero-3-title {
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

.swiper-slide.swiper-slide-active .cr-hero-3-title-wrapper .cr-section-title__pre {
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

.swiper-slide.swiper-slide-active .cr-hero-3-btn .cr-btn-2 {
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

.swiper-slide.swiper-slide-active-3-wrapper {
  visibility: visible;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

/*----------------------------------------*/
/*   About css
/*----------------------------------------*/
.box-plr {
  padding-left: 300px;
  padding-right: 99px;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .box-plr {
    padding-left: 240px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .box-plr {
    padding-left: 200px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .box-plr {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .box-plr {
    padding-left: 0;
    padding-right: 0;
  }
}

.cr-about-area {
  overflow: hidden;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px),
only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-about-wrapper {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-about-wrapper {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-about-wrapper {
    padding-left: 102px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-about-wrapper {
    padding-left: 30px;
  }
}

@media (max-width: 575px) {
  .cr-about-wrapper {
    padding-left: 15px;
  }
}

.cr-about-wrapper-thumb {
  margin-left: -25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-about-wrapper-thumb {
    margin-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-about-wrapper-thumb img {
    max-width: 100%;
  }
}

.cr-about-shape .shape-1 {
  position: absolute;
  left: 0px;
  top: -115px;
  z-index: -1;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .cr-about-shape .shape-1 {
    left: 0px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-about-shape .shape-1 {
    left: -25px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-about-shape .shape-1 {
    left: -145px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-about-shape .shape-1 {
    left: 0px;
  }
}

.cr-about-item {
  position: relative;
}

.cr-about-item:hover .cr-about-item-thumb img {
  transform: scaleX(-1);
}

.cr-about-item::after {
  position: absolute;
  content: "";
  top: 0;
  right: -25px;
  width: 1px;
  height: 248px;
  border-right: 1px dashed #D3D6DA;
  z-index: 6;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .cr-about-item::after {
    right: -15px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-about-item::after {
    right: -2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-about-item::after {
    right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-about-item::after {
    right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-about-item::after {
    right: -12px;
  }
}

.cr-about-item-thumb {
  margin-bottom: 27px;
}

.cr-about-item-thumb img {
  height: 61px;
  width: 61px;
  transition: transform 0.5s ease;
}

.cr-about-item-content .about-title {
  font-family: var(--cr-ff-poppins);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: var(--cr-heading-secondary);
  margin-bottom: 15px;
}

.cr-about-item-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--cr-text-body);
}

@media only screen and (min-width: 1701px) and (max-width: 1800px),
only screen and (min-width: 1600px) and (max-width: 1700px),
only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-about-item-content p br {
    display: none;
  }
}

.cr-about-item-btn a {
  height: 50px;
  width: 50px;
  border: 1px solid #E8EBEE;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  border-radius: 50%;
  color: var(--cr-theme-primary);
}

.cr-about-item-btn a i {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.cr-about-item-btn a:hover i {
  animation: tfLeftToRight 0.5s forwards;
  color: var(--cr-theme-secondary);
}

.cr-about-title-wrapper {
  margin-bottom: 80px;
}

.cr-about-call {
  border: 1px solid #E6EDF2;
  border-radius: 28px;
  padding: 12px 17px;
  width: 643px;
  margin-top: 12px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-about-call {
    width: 100%;
  }
}

.cr-about-call a p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--cr-heading-secondary);
  margin-bottom: 0;
}

.cr-about-call a p span {
  margin-left: 18px;
  text-decoration: underline;
  transition: all 0.3s;
}

.cr-about-call a p span:hover {
  color: var(--cr-theme-secondary);
}

.cr-about-call a p i {
  height: 28px;
  width: 28px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  color: var(--cr-common-white);
  background: var(--cr-theme-secondary);
  transform: translateY(1px);
}

.cr-about-nav button {
  position: absolute;
  bottom: -100px;
  left: 10%;
  height: 51px;
  width: 55px;
  font-size: 16px;
  font-weight: 500;
  color: var(--cr-heading-secondary);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.01);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-about-nav button {
    display: none;
  }
}

.cr-about-nav button:hover {
  color: var(--cr-common-white);
  background: var(--cr-heading-secondary);
}

.cr-about-nav button.about-button-prev-1 {
  left: -25px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-about-breadcrumb {
    padding-top: 30px;
  }
}

@media (max-width: 575px) {
  .cr-about-breadcrumb-img img {
    width: 100%;
  }
}

.cr-about-3-area {
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-about-3-area {
    padding-top: 50px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-about-3-area {
    padding-top: 90px;
  }
}

@media (max-width: 575px) {
  .cr-about-3-area {
    padding-bottom: 100px;
    padding-top: 80px;
  }
}

.cr-about-3-area .shape-bg {
  position: absolute;
  left: -200px;
  top: 0;
  z-index: -1;
}

.cr-about-3-shape {
  position: absolute;
  top: 35%;
  left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-about-3-shape {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-about-3-img {
    padding-bottom: 110px;
  }
}

.cr-about-3-img img {
  border-radius: 7px;
}

@media (max-width: 575px) {
  .cr-about-3-img img {
    max-width: 100%;
  }
}

.cr-about-3-img .shape-1 {
  position: absolute;
  top: 45px;
  right: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-about-3-img .shape-1 {
    right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-about-3-img .shape-1 {
    right: 30%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-about-3-img .shape-1 {
    top: 32px;
    right: 30px;
  }
}

@media (max-width: 575px) {
  .cr-about-3-img .shape-1 {
    display: none;
  }
}

.cr-about-3-img .shape-2 {
  position: absolute;
  bottom: -167px;
  right: 70px;
}

.cr-about-3-img .shape-2 .cr-video-play a {
  height: 77px;
  width: 77px;
  line-height: 80px;
  font-size: 22px;
}

.cr-about-3-img .shape-2 .cr-video-play a::after {
  height: 94px;
  width: 94px;
  top: -7px;
  left: -7px;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-about-3-img .shape-2 {
    right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-about-3-img .shape-2 {
    right: 5%;
    bottom: 9%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-about-3-img .shape-2 {
    bottom: 25px;
    right: 0;
  }
}

@media (max-width: 575px) {
  .cr-about-3-img .shape-2 {
    display: none;
  }
}

.cr-about-3-img .shape-3 {
  position: absolute;
  top: 40%;
  right: 21%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-about-3-img .shape-3 {
    top: 28%;
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-about-3-img .shape-3 {
    top: 25%;
    right: 33%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-about-3-img .shape-3 {
    top: 26%;
    right: 14%;
  }
}

@media (max-width: 575px) {
  .cr-about-3-img .shape-3 {
    display: none;
  }
}

.cr-about-3-img .shape-4 {
  position: absolute;
  left: -7px;
  bottom: -116px;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-about-3-img .shape-4 {
    left: -15px;
    bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-about-3-img .shape-4 {
    bottom: 50px;
  }
}

@media (max-width: 575px) {
  .cr-about-3-img .shape-4 {
    right: 8px;
    bottom: 25px;
    left: auto;
  }
}

.cr-about-3-img .shape-5 {
  position: absolute;
  bottom: -115px;
  left: -5px;
  z-index: 0;
  animation: tprotate 10s ease-in-out 5s forwards infinite alternate;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-about-3-img .shape-5 {
    left: -15px;
    bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-about-3-img .shape-5 {
    bottom: 50px;
  }
}

@media (max-width: 575px) {
  .cr-about-3-img .shape-5 {
    right: 8px;
    bottom: 28px;
    left: auto;
  }
}

.cr-about-3-wrapper .cr-about-3-title-wrapper .cr-section-title {
  margin-bottom: 40px;
}

.cr-about-3-wrapper .text {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: #535353;
  margin-left: 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-about-3-wrapper .text br {
    display: none;
  }
}

.cr-about-3-wrapper .text::after {
  position: absolute;
  top: 7px;
  left: -13px;
  content: "";
  width: 2px;
  height: 72px;
  background: var(--cr-theme-secondary);
}

@media (max-width: 575px) {
  .cr-about-3-wrapper .text::after {
    display: none;
  }
}

.cr-about-3-progressbar {
  margin-bottom: 55px;
}

.cr-about-3-progressbar:not(:last-of-type) {
  margin-right: 42px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-about-3-progressbar:not(:last-of-type) {
    margin-right: 20px;
  }
}

@media (max-width: 575px) {
  .cr-about-3-progressbar:not(:last-of-type) {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-about-3-progressbar {
    margin-bottom: 25px;
  }
}

.cr-about-3-progressbar .circular .knob {
  font-family: var(--cr-ff-p) !important;
  color: var(--cr-theme-secondary) !important;
}

.cr-about-3-progressbar-title p {
  font-weight: 500;
  font-size: 18px;
  margin-left: 28px;
  color: var(--cr-heading-secondary);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-about-3-progressbar-title p {
    margin-left: 20px;
  }
}

.cr-about-3-btn-inner .cr-about-btn {
  margin-right: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-about-3-btn-inner .cr-about-btn {
    margin-right: 20px;
  }
}

.cr-about-3-btn-inner .cr-about-btn .cr-btn {
  text-transform: uppercase;
  padding: 24px 32px;
}

.cr-about-3-year p {
  font-weight: 500;
  font-size: 16px;
  color: var(--cr-heading-secondary);
}

@media (max-width: 575px) {
  .cr-about-3-year p {
    margin-top: 15px;
  }
}

.cr-about-3-year p span {
  font-weight: 400;
  font-size: 16px;
  color: #535353;
}

.cr-video-breadcrumb img {
  max-width: 100%;
}

.cr-video-play {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: auto;
}

.cr-video-play a {
  height: 119px;
  width: 119px;
  font-weight: 900;
  font-size: 24px;
  line-height: 122px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  transform: translateX(-50%);
  color: var(--cr-theme-secondary);
  background: var(--cr-common-white);
  box-shadow: 0px 0px 50px rgba(3, 4, 28, 0.1);
  z-index: 2;
}

.cr-video-play a::after {
  position: absolute;
  content: "";
  height: 146px;
  width: 146px;
  top: -13px;
  left: -13px;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 50%;
  animation: pulse 2s infinite;
  z-index: -1;
}

.cr-video-play a:hover {
  background: var(--cr-theme-secondary);
}

.cr-video-play a:hover i {
  color: var(--cr-common-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-video-play a {
    top: 28%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-video-play a {
    display: none;
  }
}

/*----------------------------------------*/
/*   hero css
/*----------------------------------------*/
.cr-hero-space {
  padding-left: 53px;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-space {
    padding-left: 0;
  }
}

.cr-hero-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: scale(1);
  -webkit-transition: all 10s linear 0s;
  -moz-transition: all 10s linear 0s;
  -o-transition: all 10s linear 0s;
  transition: all 10s linear 0s;
}

.cr-hero-content {
  z-index: 1;
  padding-top: 230px;
  padding-bottom: 262px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-content {
    padding-top: 134px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-content {
    padding-bottom: 150px;
  }
}

.cr-hero-overlay::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(96.25deg, #D7E8F8 45.06%, rgba(196, 196, 196, 0) 60.45%);
  z-index: 0;
}

.cr-hero-title-wrapper .cr-section-title__pre {
  animation-delay: 0.6s;
  animation-duration: 0.8s;
}

.cr-hero-title-wrapper .cr-hero-title {
  position: relative;
  font-weight: 700;
  font-size: 75px;
  line-height: 95px;
  text-transform: capitalize;
  color: var(--cr-text-2);
  margin-bottom: 25px;
  animation-delay: 0.8s;
  animation-duration: 1s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-title-wrapper .cr-hero-title {
    font-size: 62px;
    line-height: 85px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-title-wrapper .cr-hero-title {
    font-size: 62px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-hero-title-wrapper .cr-hero-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 15px;
  }
}

@media (max-width: 575px) {
  .cr-hero-title-wrapper .cr-hero-title {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 15px;
  }
}

.cr-hero-title-wrapper .cr-hero-title svg {
  position: absolute;
  left: 0;
  top: 80px;
  color: var(--cr-theme-secondary);
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-hero-title-wrapper .cr-hero-title svg {
    top: 55px;
  }
}

@media (max-width: 575px) {
  .cr-hero-title-wrapper .cr-hero-title svg {
    top: 40px;
  }
}

.cr-hero-title-wrapper .cr-hero-title .title-color {
  color: var(--cr-theme-secondary);
}

.cr-hero-title-wrapper .cr-hero-title .title-text-transparent {
  font-weight: 600;
  font-family: var(--cr-ff-poppins);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #858B8F;
}

.cr-hero-btn .cr-btn {
  padding: 24px 39px;
  animation-delay: 1s;
  animation-duration: 1.2s;
}

.cr-hero-shape .shape-1 {
  position: absolute;
  top: -55px;
  left: -2px;
}

.cr-hero-shape .shape-2 {
  position: absolute;
  bottom: 115px;
  right: 0px;
  z-index: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-shape .shape-2 {
    bottom: -42px;
  }
}

.cr-hero-shape .shape-3 {
  position: absolute;
  right: 49px;
  top: 39px;
  animation: moving 9s linear infinite;
}

.cr-hero-shape .shape-4 {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-shape .shape-4 {
    display: none;
  }
}

.cr-hero-shape .shape-7 {
  position: absolute;
  bottom: 30%;
  left: 40%;
  z-index: 2;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-shape .shape-7 {
    left: 70%;
  }
}

.cr-hero-shape-animation span {
  background-color: #EAF3FB;
  height: 610px;
  width: 648px;
  position: absolute;
  top: 100px;
  left: -115px;
  border-radius: 62% 47% 82% 35%/45% 45% 80% 66%;
  will-change: border-radius, transform, opacity;
  display: block;
  z-index: -1;
  -webkit-animation: sliderShape 8s linear infinite;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-hero-shape-animation span {
    height: 590px;
    width: 600px;
    left: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-hero-shape-animation span {
    height: 500px;
    width: 520px;
    left: -35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-shape-animation span {
    height: 470px;
    width: 520px;
    left: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-shape-animation span {
    height: 400px;
    width: 450px;
    left: -30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-hero-shape-animation span {
    height: 300px;
    width: 350px;
    left: -20px;
  }
}

@media (max-width: 575px) {
  .cr-hero-shape-animation span {
    display: none;
  }
}

.cr-hero-nav button {
  position: absolute;
  bottom: 30px;
  right: 42%;
  height: 66px;
  width: 66px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 500;
  color: var(--cr-common-white);
  background: transparent;
  border: 1px solid var(--cr-common-white);
  z-index: 1;
}

.cr-hero-nav button.hero-button-next-1 {
  right: 37%;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .cr-hero-nav button.hero-button-next-1 {
    right: 40%;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-hero-nav button.hero-button-next-1 {
    right: 42%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-hero-nav button.hero-button-next-1 {
    right: 46%;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .cr-hero-nav button {
    right: 45%;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-hero-nav button {
    right: 48%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-hero-nav button {
    right: 52%;
  }
}

.cr-hero-play-btn {
  position: relative;
}

.cr-hero-play-btn a {
  position: absolute;
  left: 45%;
  top: 330px;
  height: 125px;
  width: 125px;
  line-height: 125px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-weight: 900;
  font-size: 30px;
  color: var(--cr-common-white);
  background: transparent;
  border: 1px solid var(--cr-common-white);
  animation: pulse 2s infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-play-btn a {
    top: 235px;
  }
}

.cr-hero-bottom {
  position: absolute;
  bottom: 30px;
  left: 18px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-bottom {
    display: none;
  }
}

.cr-hero-experince {
  display: flex;
  text-align: center;
}

.cr-hero-experince .year {
  position: relative;
  font-weight: 600;
  font-size: 75px;
  line-height: 36px;
  color: var(--cr-common-white);
  z-index: 1;
}

.cr-hero-experince .year .experince {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.cr-hero-experince .year .experince::after {
  content: "";
  position: absolute;
  left: -18px;
  background: var(--cr-theme-secondary);
  height: 160px;
  width: 190px;
  z-index: -1;
  bottom: -30px;
}

.cr-hero-service {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  height: 161px;
  width: 608px;
  background-color: var(--cr-theme-primary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-service {
    width: 480px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-service {
    width: 450px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-service {
    display: none;
  }
}

.cr-hero-service-shape {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.cr-hero-service p {
  padding: 55px 55px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
  color: var(--cr-common-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-service p br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-service p {
    padding: 38px 60px;
  }
}

.cr-hero-service p span {
  color: #FFB302;
}

.cr-hero-service p::before {
  content: "";
  background: var(--cr-theme-secondary);
  height: 48px;
  width: 1px;
  left: 45px;
  top: 64px;
  z-index: 3;
  position: absolute;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-service p::before {
    height: 70px;
    top: 50px;
  }
}

.cr-hero-service-quote {
  background: var(--cr-common-white);
  position: absolute;
  top: -27px;
  left: -27px;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 16px;
}

.cr-hero-2-area {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 600px;
  background-image: url("../img/all/main-banner.jpg")
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-hero-2-area {
    min-height: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-2-area {
    min-height: 750px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-hero-2-area {
    min-height: 620px;
  }
}

@media (max-width: 575px) {
  .cr-hero-2-area {
    min-height: 500px;
  }
}

.cr-hero-2-shape .shape-1 {
  position: absolute;
  top: 90px;
  right: 16%;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-hero-2-shape .shape-1 {
    top: 56px;
    right: 9%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-2-shape .shape-1 {
    top: 40px;
    right: 8%;
  }
}

.cr-hero-2-shape .shape-2 {
  position: absolute;
  top: 367px;
  right: 188px;
  animation: movingleft 7s linear infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-2-shape .shape-2 {
    top: 390px;
  }
}

.cr-hero-2-shape .shape-3 {
  position: absolute;
  top: 531px;
  right: 470px;
  animation: movingright 6s linear infinite;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-2-shape .shape-3 {
    top: 540px;
    right: 90px;
  }
}

.cr-hero-2-content {
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-2-content {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
(max-width: 575px) {
  .cr-hero-2-content {
    padding-top: 100px;
  }
}

.cr-hero-2-content .cr-hero-2-title {
  position: relative;
  font-weight: 700;
  font-size: 70px;
  line-height: 1.3;
  text-transform: capitalize;
  color: var(--cr-common-white);
  margin-bottom: 58px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-2-content .cr-hero-2-title {
    font-size: 56px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-hero-2-content .cr-hero-2-title {
    font-size: 45px;
  }
}

@media (max-width: 575px) {
  .cr-hero-2-content .cr-hero-2-title {
    font-size: 40px;
    margin-bottom: 35px;
  }
}

.cr-hero-2-content .cr-hero-2-title svg {
  position: absolute;
  right: 31%;
  top: 44%;
  color: var(--cr-theme-secondary);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-2-content .cr-hero-2-title svg {
    right: 23%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-hero-2-content .cr-hero-2-title svg {
    right: 20%;
  }
}

@media (max-width: 575px) {
  .cr-hero-2-content .cr-hero-2-title svg {
    display: none;
  }
}

.cr-hero-2-content .cr-hero-2-title span {
  color: var(--cr-theme-secondary);
}

@media (max-width: 575px) {
  .cr-hero-2-side-text {
    display: none;
  }
}

.cr-hero-2-side-text .cr-hero-2-mail {
  position: absolute;
  top: 46%;
  right: -53px;
  transform: rotate(-90deg) translateX(80px);
  z-index: 5;
}

.cr-hero-2-side-text .cr-hero-2-mail a {
  font-family: var(--cr-ff-heading);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: var(--cr-heading-secondary);
}

.cr-hero-2-side-text .cr-hero-2-mail a i {
  margin-right: 15px;
  color: var(--cr-theme-secondary);
}

.cr-hero-2-side-text .cr-hero-2-message {
  position: absolute;
  top: 127%;
  right: -110px;
  transform: rotate(-90deg) translateX(80px);
  z-index: 5;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-2-side-text .cr-hero-2-message {
    top: 118%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-hero-2-side-text .cr-hero-2-message {
    top: 112%;
  }
}

.cr-hero-2-side-text .cr-hero-2-message p {
  font-family: var(--cr-ff-heading);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: var(--cr-heading-secondary);
  margin-bottom: 0;
}

.cr-hero-2-side-text .cr-hero-2-message p i {
  margin-right: 15px;
  color: var(--cr-theme-secondary);
}

.cr-hero-2-bottom {
  position: absolute;
  height: 270px;
  width: 1025px;
  bottom: 0;
  left: 50%;
  background: var(--cr-common-white);
  transform: translateX(-50%);
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-2-bottom {
    width: 992px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-2-bottom {
    width: 767px;
  }
}

.cr-hero-2-bottom .cr-hero-2-bottom-title {
  position: relative;
  font-weight: 700;
  font-size: 130px;
  text-align: center;
  line-height: 270px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #858B8F;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-2-bottom .cr-hero-2-bottom-title {
    font-size: 100px;
  }
}

.cr-hero-2-bottom .cr-hero-2-bottom-title::after {
  content: "";
  height: 180px;
  width: 180px;
  background: var(--cr-theme-secondary);
  top: 50px;
  left: 102px;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  animation: tpupdown s ease-in-out s forwards infinite alternate;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-2-bottom .cr-hero-2-bottom-title::after {
    left: 135px;
  }
}

.cr-hero-2-nav button {
  position: absolute;
  top: 30px;
  right: 229px;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #000000;
  z-index: 1;
}

.cr-hero-2-nav button.hero-button-next-1 {
  right: 36px;
}

.cr-hero-2-nav button:hover::before {
  opacity: 1;
}

.cr-hero-2-nav button::before {
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: -4px;
  right: -5px;
  display: inline-block;
  position: absolute;
  opacity: 0;
  z-index: -1;
  background: var(--cr-theme-secondary);
  transition: all 0.3s ease-in;
}

.cr-hero-2-nav::after {
  content: "";
  height: 1px;
  width: 120px;
  background: #D9D9D9;
  position: absolute;
  top: 42px;
  right: 95px;
}

.cr-hero-2-btn .cr-btn {
  padding: 23px 29px;
}

.cr-hero-3-shape .shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-3-shape .shape-1 {
    display: none;
  }
}

.cr-hero-3-shape .shape-2 {
  position: absolute;
  top: 38%;
  right: 18%;
  z-index: 1;
  animation: tpswing 5s ease-in-out 1s forwards infinite alternate;
  transform-origin: right center;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-hero-3-shape .shape-2 {
    top: 42%;
    right: 14%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-hero-3-shape .shape-2 {
    top: 43%;
    right: 12%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-hero-3-shape .shape-2 {
    top: 42%;
    right: 6%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-3-shape .shape-2 {
    display: none;
  }
}

.cr-hero-3-shape .shape-3 {
  position: absolute;
  bottom: 26%;
  right: 39%;
  z-index: 1;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-3-shape .shape-3 {
    display: none;
  }
}

.cr-hero-3-wrapper {
  padding-top: 345px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 1060px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-hero-3-wrapper {
    padding-top: 300px;
    min-height: 970px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-3-wrapper {
    padding-top: 230px;
    min-height: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-3-wrapper {
    padding-top: 230px;
    min-height: 700px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-hero-3-wrapper {
    min-height: 900px;
  }
}

@media (max-width: 575px) {
  .cr-hero-3-wrapper {
    padding-top: 240px;
    min-height: 660px;
  }
}

.cr-hero-3-wrapper::before {
  position: absolute;
  content: "";
  background: linear-gradient(251deg, rgba(4, 17, 61, 0.4482) 16.32%, rgba(3, 23, 62, 0.8217) 99.94%);
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
}

.cr-hero-3-title {
  position: relative;
  font-weight: 700;
  font-size: 80px;
  line-height: 1.2;
  margin-bottom: 30px;
  text-transform: capitalize;
  color: var(--cr-common-white);
  animation-delay: 0.8s;
  animation-duration: 1s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-3-title {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-3-title {
    font-size: 65px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-hero-3-title {
    font-size: 60px;
  }
}

@media (max-width: 575px) {
  .cr-hero-3-title {
    font-size: 39px;
  }
}

.cr-hero-3-title .title-color {
  color: var(--cr-theme-secondary);
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
}

.cr-hero-3-title .circle {
  position: absolute;
  left: 37%;
  top: 0;
  color: var(--cr-theme-secondary);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-hero-3-title .circle {
    left: 40%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-3-title .circle {
    left: 41%;
    top: -3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-3-title .circle {
    left: 50%;
    top: -10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-3-title .circle {
    display: none;
  }
}

.cr-hero-3-title .line {
  position: absolute;
  top: 85px;
  left: 0;
  color: var(--cr-theme-secondary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-3-title .line {
    top: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-3-title .line {
    top: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-hero-3-title .line {
    display: none;
  }
}

.cr-hero-3-title-wrapper .cr-section-title__pre {
  color: var(--cr-common-white);
  animation-delay: 0.6s;
  animation-duration: 0.8s;
}

.cr-hero-3-btn .cr-btn-2 {
  padding: 24px 72px;
  animation-delay: 1s;
  animation-duration: 1.2s;
}

.cr-hero-3-btn .cr-btn-2:hover {
  color: var(--cr-common-white);
}

.cr-hero-3-btn .cr-btn-hover {
  background: var(--cr-theme-secondary);
}

.cr-hero-3-btn .cr-btn-hover::after {
  background: var(--cr-theme-primary);
}

.cr-hero-3-bottom {
  overflow: hidden;
}

.cr-hero-3-bottom::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 54px;
  background: var(--cr-theme-secondary);
  left: 12%;
  top: 52%;
}

.cr-hero-3-bottom::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 54px;
  background: var(--cr-theme-secondary);
  right: 12%;
  top: 52%;
}

.cr-hero-3-bottom-title {
  position: relative;
  font-weight: 700;
  font-size: 130px;
  text-align: center;
  line-height: 270px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #858B8F;
  margin-bottom: 0;
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-hero-3-bottom-title {
    font-size: 115px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-hero-3-bottom-title {
    font-size: 100px;
    line-height: 225px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-hero-3-bottom-title {
    font-size: 70px;
    line-height: 225px;
  }
}

.cr-hero-3-bottom-title::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  color: var(--cr-theme-secondary);
  overflow: hidden;
  width: 100%;
  transition: all 0.2s;
  -moz-animation: about-text 2s ease-in-out 1s forwards infinite alternate-reverse;
  -webkit-animation: about-text 2s ease-in-out 1s forwards infinite alternate-reverse;
  animation: about-text 2s ease-in-out 1s forwards infinite alternate-reverse;
}

.cr-hero-3-nav button {
  position: absolute;
  bottom: 16%;
  right: 160px;
  height: 66px;
  width: 66px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 500;
  color: var(--cr-common-white);
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.28);
  z-index: 5;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-hero-3-nav button {
    bottom: 7%;
  }
}

.cr-hero-3-nav button.hero-button-next-1 {
  right: 60px;
}

/*----------------------------------------*/
/*   feature css
/*----------------------------------------*/
.cr-feature-area {
  position: relative;
  padding-top: 60px;
  background: rgba(232, 247, 253, 0.5);
  overflow: hidden;
}

.cr-feature-area::before {
  content: "";
  position: absolute;
  bottom: 0;
  background: var(--cr-theme-primary);
  height: 166px;
  width: 100%;
  left: 0;
}

.cr-feature-area.feature-breadcrumb {
  padding-top: 0;
}

.cr-feature-area.feature-breadcrumb::before {
  display: none;
}

.cr-feature-area.feature-breadcrumb .cr-feature-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}

.cr-feature-shape img {
  position: absolute;
  left: -212px;
  z-index: 0;
  bottom: -36px;
  opacity: 0.5;
}

.cr-feature-wrapper p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  justify-content: end;
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-feature-wrapper p {
    justify-content: start;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
  .cr-feature-wrapper p br {
    display: none;
  }
}

.cr-feature-wrapper p::after {
  position: absolute;
  content: "";
  background: var(--cr-theme-top-secondary);
  width: 2px;
  height: 41px;
  left: 128px;
  top: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-feature-wrapper p::after {
    left: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-feature-wrapper p::after {
    left: -15px;
    height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-feature-wrapper p::after {
    left: -10px;
  }
}

.cr-feature-item {
  text-align: center;
  background: var(--cr-common-white);
  box-shadow: 0px -4px 31px rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-feature-item {
    margin-bottom: 50px;
  }
}

.cr-feature-item-box .cr-feature-item-btn a {
  height: 50px;
  width: 50px;
  display: inline-block;
  line-height: 50px;
  border-radius: 50%;
  color: var(--cr-common-white);
  background: var(--cr-theme-secondary);
  position: absolute;
  bottom: -25px;
  left: 43%;
  text-align: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  scale: 0;
  z-index: 2;
}

.cr-feature-item-box .cr-feature-item-btn a:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.cr-feature-item-box:hover .cr-feature-item-btn a {
  opacity: 1;
  visibility: visible;
  scale: 1;
}

.cr-feature-item:hover .cr-feature-item-thumb .shape {
  opacity: 1;
  visibility: visible;
  top: 80px;
}

.cr-feature-item:hover .cr-feature-item-thumb .thumb {
  transform: scale(1.05);
}

.cr-feature-item:hover .cr-feature-item-wrapper::after {
  opacity: 1;
  visibility: visible;
  left: 0;
}

.cr-feature-item:hover .cr-feature-item-wrapper::before {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.cr-feature-item-wrapper {
  padding: 56px 12px 25px 0;
}

.cr-feature-item-wrapper::after {
  position: absolute;
  top: 0;
  content: "";
  background: var(--cr-theme-secondary);
  clip-path: polygon(0 0, 0% 100%, 80% 50%);
  width: 42px;
  height: 57px;
  opacity: 0;
  visibility: visible;
  transform: scaleY(1);
  left: -10px;
  transition: all 0.3s ease-in-out;
}

.cr-feature-item-wrapper::before {
  position: absolute;
  right: 0;
  content: "";
  background: #FFB302;
  clip-path: polygon(50% 15%, 0% 100%, 100% 100%);
  width: 52px;
  height: 37px;
  opacity: 0;
  visibility: visible;
  transform: scaleY(1);
  bottom: -10px;
  transition: all 0.3s ease-in-out;
}

.cr-feature-item-shape {
  position: absolute;
  top: 0;
  left: 0;
}

.cr-feature-item-thumb .shape {
  position: absolute;
  right: 120px;
  top: 100px;
  background: var(--cr-common-white);
  box-shadow: 6px -6px 4px rgba(21, 49, 74, 0.07);
  border-radius: 24.945px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  margin-bottom: 0;
  opacity: 0;
  visibility: visible;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-feature-item-thumb .shape {
    right: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-feature-item-thumb .shape {
    right: 150px;
  }
}

@media (max-width: 575px) {
  .cr-feature-item-thumb .shape {
    right: 60px;
  }
}

.cr-feature-item-thumb .thumb {
  border-radius: 8px;
  margin-bottom: 62px;
  overflow: hidden;
  transition: 0.2s 0s ease-in;
}

.cr-feature-item-content .feature-title {
  position: relative;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  margin-bottom: 33px;
  color: var(--cr-heading-secondary);
}

.cr-feature-item-content .feature-title span {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--cr-theme-secondary);
}

.cr-feature-item-content .feature-title:hover {
  color: var(--cr-theme-secondary);
}

.cr-feature-item-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 28px;
}

.cr-feature-area-2 {
  background: rgba(232, 247, 253, 0.5);
}

@media (max-width: 575px) {
  .cr-feature-thumb-2 img {
    width: inherit;
  }
}

.cr-feature-2-info {
  background: var(--cr-common-white);
  padding: 22px 40px 21px 47px;
  justify-content: space-between;
  width: 500px;
  position: absolute;
  bottom: 0;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
  transition: all 0.5s ease-in-out;
  z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-feature-2-info {
    padding: 26px 40px 20px 30px;
    width: 430px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-feature-2-info {
    padding: 26px 40px 20px 30px;
    width: 400px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-feature-2-info {
    padding: 20px;
    width: 300px;
  }
}

@media (max-width: 575px) {
  .cr-feature-2-info {
    padding: 20px;
    width: 275px;
  }
}

.cr-feature-2-info:hover .cr-feature-2-btn a i {
  transform: rotate(45deg);
}

.cr-feature-2-title {
  font-weight: 600;
  font-size: 25px;
  color: var(--cr-heading-secondary);
  background-image: linear-gradient(#03041C, #03041C), linear-gradient(#03041C, #03041C);
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
  transition: 0.3s ease;
}

.cr-feature-2-title:hover {
  background-size: 0 2px, 100% 1px;
}

.cr-feature-2-title-sub {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 18px;
  color: var(--cr-theme-secondary);
  margin-bottom: 10px;
  display: block;
}

.cr-feature-2-btn a {
  font-size: 20px;
  color: var(--cr-theme-secondary);
}

.cr-feature-2-btn a i {
  transition: all 0.3s ease-in-out;
}

.cr-feature-3-area {
  position: relative;
  background: var(--cr-bg-primary);
}

@media (max-width: 575px) {
  .cr-feature-3-area {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.cr-feature-3-content {
  position: relative;
}

.cr-feature-3-content-inner {
  position: relative;
  background: var(--cr-common-white);
  box-shadow: 0px 18px 83px rgba(63, 91, 143, 0.02);
  padding: 35px 30px 20px 38px;
}

@media (max-width: 575px) {
  .cr-feature-3-content-inner {
    padding: 15px;
  }
}

.cr-feature-3-content-inner::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(29% 0, 100% 71%, 100% 0);
  background: var(--cr-theme-secondary);
  height: 42px;
  width: 42px;
  transition: all 0.4s;
}

.cr-feature-3-content-inner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(29% 0, 100% 71%, 100% 0);
  background: var(--cr-theme-primary);
  height: 42px;
  width: 0px;
  z-index: 1;
  transition: all 0.3s;
}

.cr-feature-3-content-inner:hover::before {
  width: 42px;
}

.cr-feature-3-content::after {
  position: absolute;
  content: "";
  top: 25px;
  left: -38px;
  height: 79px;
  width: 1px;
  background: #D9D9D9;
}

@media only screen and (min-width: 1701px) and (max-width: 1800px),
only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-feature-3-content::after {
    top: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 575px) {
  .cr-feature-3-content::after {
    top: 50px;
    left: -15px;
  }
}

.cr-feature-3-content-thumb {
  margin-right: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 575px) {
  .cr-feature-3-content-thumb {
    margin-right: 35px;
  }
}

.cr-feature-3-content .cr-feature-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-transform: capitalize;
  color: #121D2C;
  margin-bottom: 17px;
}

.cr-feature-3-content .cr-feature-title a:hover {
  color: var(--cr-theme-secondary);
}

.cr-feature-3-content p {
  font-weight: 400;
  font-size: 16px;
  color: #9C9C9C;
}

.cr-feature-3-text-style {
  padding-bottom: 190px;
}

@media (max-width: 575px) {
  .cr-feature-3-text-style {
    padding-bottom: 70px;
  }
}

.cr-feature-3-text-style .feature-title {
  position: relative;
  font-weight: 700;
  font-size: 150px;
  line-height: 1;
  text-align: center;
  color: transparent;
  text-transform: lowercase;
  /* background-image: url("../img/feature/home-3/text-img.jpg"); */
  -webkit-background-clip: text;
  background-size: auto;
  background-position: center;
  margin-bottom: 50px;
}

.cr-feature-3-text-style .feature-title::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 54px;
  background: var(--cr-theme-secondary);
  right: 23%;
  top: 55%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-feature-3-text-style .feature-title::after {
    right: 16%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-feature-3-text-style .feature-title::after {
    right: 7%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-feature-3-text-style .feature-title::after {
    display: none;
  }
}

.cr-feature-3-text-style .feature-title::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 54px;
  background: var(--cr-theme-secondary);
  left: 23%;
  top: 55%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-feature-3-text-style .feature-title::before {
    left: 16%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-feature-3-text-style .feature-title::before {
    left: 7%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-feature-3-text-style .feature-title::before {
    display: none;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-feature-3-text-style .feature-title {
    font-size: 135px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-feature-3-text-style .feature-title {
    font-size: 115px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-feature-3-text-style .feature-title {
    font-size: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-feature-3-text-style .feature-title {
    font-size: 100px;
  }
}

@media (max-width: 575px) {
  .cr-feature-3-text-style .feature-title {
    font-size: 60px;
  }
}

.cr-feature-active-2 .splide__slide.is-visible.is-active .cr-feature-2-info {
  opacity: 1;
  visibility: visible;
  bottom: 34px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-feature-active-2 .splide__slide.is-visible.is-active .cr-feature-2-info {
    bottom: 20px;
  }
}

@media (max-width: 575px) {
  .cr-feature-active-2 .splide__slide.is-visible.is-active .cr-feature-2-info {
    bottom: 70px;
  }
}

/*----------------------------------------*/
/*   feature css
/*----------------------------------------*/
.cr-service-funfact-box {
  background: var(--cr-theme-primary);
  position: relative;
  z-index: 1;
}

.cr-service-title-wrapper .cr-section-title__pre {
  color: var(--cr-common-white);
}

.cr-service-title-wrapper .cr-section-title {
  color: var(--cr-common-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-service-breadcrumb-title-wrapper p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-service-breadcrumb-title-wrapper p {
    margin-bottom: 40px;
  }
}

.cr-service-wrapper {
  background: #0D1130;
  border-radius: 5px;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 65px 0 50px 0;
  transition: all 0.3s ease-in-out;
  border: 1px solid #0D1130;
}

.cr-service-wrapper:hover {
  border: 1px solid #06D9C3;
}

.cr-service-wrapper .service-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: var(--cr-common-white);
  margin-bottom: 35px;
  position: relative;
  z-index: 1;
}

.cr-service-wrapper .hide-text {
  position: absolute;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--cr-common-white);
  margin-bottom: 0;
  visibility: hidden;
  opacity: 0;
  top: 43%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 30px;
  transition: all 0.3s ease-in-out;
}

.cr-service-wrapper:hover .cr-service-icon {
  visibility: hidden;
  opacity: 0;
}

.cr-service-wrapper:hover .hide-text {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.cr-service-wrapper:hover .cr-service-btn a {
  color: var(--cr-common-white);
}

.cr-service-wrapper:hover .cr-service-btn a i {
  transform: rotate(45deg);
}

.cr-service-designation p {
  font-family: var(--cr-ff-poppins);
  font-weight: 500;
  font-size: 18px;
  color: var(--cr-common-white);
  border: 1px solid rgba(230, 237, 242, 0.15);
  border-radius: 50%;
  display: inline-block;
  line-height: 52px;
  width: 52px;
  height: 52px;
  position: absolute;
  right: 21px;
  top: 18px;
  z-index: 1;
}

.cr-service-icon {
  margin-bottom: 60px;
  transition: all 0.3s;
}

.cr-service-btn {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: var(--cr-theme-secondary);
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.cr-service-btn svg {
  margin-left: 5px;
  transition: all 0.1s linear;
  transform: translateY(2px);
}

.cr-service-all-btn .cr-btn {
  padding: 21px 51px;
  border: 1px solid #252949;
}

.cr-service-3-area {
  background: var(--cr-theme-primary);
  overflow: hidden;
}

.cr-service-3-title-wrapper .cr-section-title {
  color: var(--cr-common-white);
}

.cr-service-3-title-wrapper .cr-section-title__pre {
  color: var(--cr-common-white);
}

.cr-service-3-title-wrapper p {
  font-weight: 400;
  font-size: 16px;
  color: #9C9C9C;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-service-3-title-wrapper p {
    margin-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .cr-service-3-title-wrapper p br {
    display: none;
  }
}

.cr-service-3-content {
  position: relative;
  background: #020626;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 58px 40px 36px 39px;
}

.cr-service-3-content.breadcrumb-item {
  background-color: var(--cr-common-white);
  border: 1px solid rgba(181, 182, 196, 0.28);
  transition: all 0.3s ease-in-out;
}

.cr-service-3-content.breadcrumb-item:hover .cr-service-breadcrumb-title {
  color: var(--cr-theme-secondary);
}

.cr-service-3-content.breadcrumb-item .cr-service-3-content-thumb::before {
  background: rgba(6, 218, 195, 0.1);
}

.cr-service-3-content::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(100% 100%, 0 100%, 100% 0);
  background: #39991829;
  height: 100%;
  width: 0px;
  transition: all 0.4s;
}

.cr-service-3-content:hover::after {
  width: 100%;
}

.cr-service-3-content-thumb {
  position: relative;
}

.cr-service-3-content-thumb::before {
  position: absolute;
  content: "";
  top: -15px;
  left: 25px;
  height: 46px;
  width: 46px;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 50%;
}

.cr-service-3-content-thumb img {
  margin-bottom: 30px;
  width: 44px;
  height: 44px;
}

.cr-service-3-content img {
  height: 66px;
}

.cr-service-3-content p {
  font-weight: 400;
  font-size: 16px;
  color: #9C9C9C;
  margin-bottom: 22px;
}

.cr-service-3-content .cr-service-btn:hover svg {
  transform: rotate(45deg);
}

.cr-service-3-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: var(--cr-common-white);
  margin-bottom: 20px;
}

.cr-service-3-title a:hover {
  color: var(--cr-theme-secondary);
}

.cr-service-3-trend p {
  font-weight: 500;
  font-size: 15px;
  line-height: 36px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--cr-common-white);
  margin-bottom: 0;
}

.cr-service-3-trend p a {
  color: var(--cr-theme-secondary);
}

.cr-service-3-trend p i {
  margin-left: 20px;
  margin-right: 20px;
}

.cr-service-breadcrumb-area {
  overflow: hidden;
}

.cr-service-breadcrumb-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  transition: none;
}

.cr-service-details-wrapper p {
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #5A627D;
  margin-bottom: 42px;
}

.cr-service-details-title {
  font-weight: 600;
  font-size: 28px;
  text-transform: capitalize;
  color: var(--cr-heading-secondary);
  margin-bottom: 15px;
}

.cr-service-details-thumb {
  margin-bottom: 40px;
}

.cr-service-details-thumb img {
  width: 100%;
}

.cr-service-details-thumb .cr-video-play a {
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 20px;
  top: 39%;
  left: 48%;
}

.cr-service-details-thumb .cr-video-play a::after {
  height: 94px;
  width: 94px;
  top: -7px;
  left: -7px;
  z-index: -1;
}

.cr-service-details-list p {
  margin-bottom: 15px;
}

.cr-service-details-list ul li {
  list-style: none;
  margin-bottom: 14px;
}

.cr-service-details-list ul li span {
  color: var(--cr-theme-secondary);
  margin-right: 10px;
}

.cr-service-widget {
  position: sticky;
  top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-service-widget {
    position: inherit;
  }
}

.cr-service-widget-tab ul li {
  list-style: none;
  margin-bottom: 13px;
}

.cr-service-widget-tab ul li .active {
  background-color: var(--cr-theme-primary);
  color: var(--cr-common-white);
}

.cr-service-widget-tab ul li .active i {
  background-color: var(--cr-theme-secondary);
}

.cr-service-widget-tab ul li a {
  position: relative;
  font-family: var(--cr-ff-heading);
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #101C2A;
  background: #F1FBFE;
  display: inline-block;
  width: 100%;
  padding: 24px 20px;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.cr-service-widget-tab ul li a:hover {
  background-color: var(--cr-theme-primary);
  color: var(--cr-common-white);
}

.cr-service-widget-tab ul li a:hover i {
  background-color: var(--cr-theme-secondary);
}

.cr-service-widget-tab ul li a i {
  position: absolute;
  top: 30%;
  right: 7px;
  transform: translateY(-10px);
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 45px;
  transition: background-color 0.4s ease-in-out;
}

.cr-service-contact-form {
  background: #F8F8F8;
  padding: 80px 30px 65px;
}

@media (max-width: 575px) {
  .cr-service-contact-form {
    padding: 60px 30px 50px;
  }
}

.cr-service-contact-input {
  margin-bottom: 19px;
}

.cr-service-contact-input input {
  width: 100%;
  height: 60px;
  border: 1px solid #EFEFF2;
  background-color: var(--cr-common-white);
  outline: 0;
  color: var(--cr-common-black);
  padding: 0 25px;
  border-radius: 2px;
}

.cr-service-contact-input input::placeholder {
  font-family: var(--cr-ff-heading);
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  color: #000D44;
  opacity: 0.5;
}

.cr-service-contact-input input:focus {
  border: 1px solid var(--cr-theme-secondary);
}

.cr-service-contact-input input:focus::placeholder {
  font-size: 0;
}

.cr-service-contact-input textarea {
  height: 167px;
  border: 1px solid #EFEFF2;
  background-color: var(--cr-common-white);
  resize: none;
  line-height: 3.5;
  border-radius: 2px;
}

.cr-service-contact-input textarea::placeholder {
  font-family: var(--cr-ff-heading);
  font-weight: 500;
  font-size: 14px;
  color: #000D44;
  opacity: 0.5;
}

.cr-service-contact-input textarea:focus {
  border: 1px solid var(--cr-theme-secondary);
}

.cr-service-contact-input textarea:focus::placeholder {
  font-size: 0;
}

/*----------------------------------------*/
/*   fun fact css
/*----------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-fun-fact-area {
    overflow: hidden;
  }
}

.cr-fun-fact-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 20px;
  animation: scale_up_down 1s infinite alternate both;
}

.cr-fun-fact-shape .shape-2 {
  position: absolute;
  bottom: -75%;
  left: -25%;
  opacity: 0.6;
  animation: animationglob 30s cubic-bezier(1, 0.99, 0.03, 0.01) infinite;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-fun-fact-shape .shape-2 {
    bottom: -82%;
    left: -30%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-fun-fact-shape .shape-2 {
    bottom: -90%;
    left: -30%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-fun-fact-shape .shape-2 {
    bottom: -90%;
    left: -42%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-fun-fact-shape .shape-2 {
    bottom: -85%;
    left: -50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-fun-fact-shape .shape-2 {
    display: none;
  }
}

.cr-fun-fact-shape .shape-3 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.cr-fun-fact-shape .shape-4 {
  position: absolute;
  top: 20px;
  right: 85px;
  animation: tpupdown 1s infinite alternate;
}

.cr-fun-fact-shape .shadow {
  position: absolute;
  top: -60%;
  left: 0;
  z-index: -1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-fun-fact-shape .shadow {
    top: 7%;
    left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-fun-fact-shape .shadow {
    display: none;
  }
}

.cr-fun-fact-wrapper {
  margin-bottom: 80px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-fun-fact-wrapper {
    margin-bottom: 40px;
  }
}

.cr-fun-fact-wrapper-box {
  margin-top: -40px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-fun-fact-wrapper-box {
    margin-left: 60px;
  }
}

.cr-fun-fact-wrapper .counter-title {
  font-weight: 700;
  font-size: 80px;
  line-height: 49px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px;
}

.cr-fun-fact-wrapper p {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.25em;
  color: var(--cr-common-white);
}

.cr-fun-fact-thumb {
  margin-left: -25px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-fun-fact-thumb {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-fun-fact-thumb {
    margin-left: -60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-fun-fact-thumb {
    margin-left: 0px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-fun-fact-thumb img {
    width: 120%;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-fun-fact-thumb img {
    max-width: 136%;
  }
}

@media (max-width: 575px) {
  .cr-fun-fact-thumb img {
    max-width: 100%;
  }
}

.cr-fun-fact-content {
  padding-left: 92px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-fun-fact-content {
    padding-left: 0;
    padding-bottom: 50px;
  }
}

.cr-fun-fact-title-wrapper .cr-section-title {
  color: var(--cr-common-white);
  margin-bottom: 30px;
}

.cr-fun-fact-title-wrapper .cr-section-title__pre {
  color: var(--cr-common-white);
}

.cr-fun-fact-title-wrapper.support-breadcrumb .cr-section-title {
  color: var(--cr-theme-primary);
}

.cr-fun-fact-title-wrapper.support-breadcrumb .cr-section-title__pre {
  color: var(--cr-text-2);
}

.cr-fun-fact-title-wrapper p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-fun-fact-title-wrapper p br {
    display: none;
  }
}

.cr-fun-fact-title-wrapper ul li {
  list-style: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #6F7183;
  margin-bottom: 20px;
}

.cr-fun-fact-title-wrapper ul li span {
  color: var(--cr-theme-secondary);
  margin-right: 18px;
}

@media (max-width: 575px) {
  .cr-fun-fact-title-wrapper ul li span {
    margin-right: 10px;
  }
}

.cr-fun-fact-btn .cr-btn {
  background: rgba(255, 255, 255, 0.04);
  margin-top: 30px;
  padding: 21px 45px;
}

.cr-counter-area {
  background: var(--cr-theme-primary);
}

.cr-counter-area.counter-breadcrumb {
  background: none;
}

.cr-counter-area.counter-breadcrumb::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(232, 247, 253, 0.5);
  height: 68%;
  width: 100%;
  z-index: -1;
}

/* .cr-counter-box {
  background: var(--cr-common-white);
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.06);
  padding: 38px 60px;
} */

.cr-counter-wrapper {
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-counter-wrapper {
    margin-bottom: 30px;
  }
}

.cr-counter-wrapper .counter-title {
  position: relative;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: var(--cr-common-white);
  margin-bottom: 17px;
}

.cr-counter-wrapper .counter-title::after {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  border-radius: 50%;
  top: 0px;
  left: 135px;
  display: inline-block;
  opacity: 0;
  z-index: -1;
  background: var(--cr-theme-top-secondary);
  transition: all 0.3s ease-in;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-counter-wrapper .counter-title::after {
    left: 110px;
  }
}

.cr-counter-wrapper .counter-subtitle {
  font-family: var(--cr-ff-heading);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--cr-common-white);
}

.cr-counter-wrapper:hover .counter-title::after {
  opacity: 1;
}

.cr-counter-border::after {
  position: absolute;
  content: "";
  border-right: 1px solid #D4DBDE;
  height: 37px;
  right: 0px;
  top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-counter-border::after {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-counter-3-area {
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-counter-3-area {
    padding-top: 100px;
  }
}

.cr-counter-3-bg {
  width: 100%;
}

.cr-counter-3-bg .shape-1 {
  position: absolute;
  bottom: -65px;
  left: 0;
  width: 100%;
  z-index: 2;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-counter-3-bg .shape-1 {
    bottom: -45px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-counter-3-bg .shape-1 {
    bottom: -35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-counter-3-bg .shape-1 {
    bottom: 95px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-counter-3-bg .shape-1 {
    display: none;
  }
}

.cr-counter-3-wrapper {
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-counter-3-wrapper {
    margin-bottom: 30px;
  }
}

.cr-counter-3-wrapper .counter-title {
  position: relative;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: var(--cr-common-white);
  margin-bottom: 17px;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-counter-3-wrapper .counter-title {
    color: var(--cr-common-black);
  }
}

.cr-counter-3-wrapper .counter-title::after {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  border-radius: 50%;
  top: 0px;
  left: 150px;
  display: inline-block;
  opacity: 0;
  z-index: -1;
  background: var(--cr-theme-secondary);
  transition: all 0.3s ease-in;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-counter-3-wrapper .counter-title::after {
    left: 110px;
  }
}

.cr-counter-3-wrapper .counter-subtitle {
  position: relative;
  font-family: var(--cr-ff-heading);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  z-index: 1;
  color: var(--cr-common-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-counter-3-wrapper .counter-subtitle {
    color: var(--cr-common-black);
  }
}

.cr-counter-3-wrapper:hover .counter-title::after {
  opacity: 1;
}

.cr-counter-3-border::after {
  position: absolute;
  content: "";
  border-right: 1px solid rgba(217, 217, 217, 0.2);
  height: 37px;
  right: 0px;
  top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-counter-3-border::after {
    display: none;
  }
}

/*----------------------------------------*/
/*   brand css
/*----------------------------------------*/
.cr-brand-area {
  background: var(--cr-bg-primary);
  overflow: hidden;
  z-index: 1;
}

@media (max-width: 575px) {
  .cr-brand-area {
    padding-top: 60px;
  }
}

.cr-brand-shape .bg-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.5;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-brand-shape .bg-shape {
    max-width: 100%;
  }
}

.cr-brand-shape .shape-1 {
  position: absolute;
  bottom: 60px;
  left: 38%;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-brand-shape .shape-1 {
    bottom: 70px;
    left: 35%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-shape .shape-1 {
    bottom: 77px;
    left: 32%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-shape .shape-1 {
    bottom: 72px;
    left: 25%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-shape .shape-1 {
    left: 25%;
  }
}

@media (max-width: 575px) {
  .cr-brand-shape .shape-1 {
    display: none;
  }
}

.cr-brand-shape .shape-2 {
  position: absolute;
  bottom: 145px;
  left: 29%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-brand-shape .shape-2 {
    left: 23%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-brand-shape .shape-2 {
    bottom: 160px;
    left: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-shape .shape-2 {
    bottom: 155px;
    left: 14%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-shape .shape-2 {
    bottom: 190px;
    left: 5%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-shape .shape-2 {
    left: 5%;
  }
}

@media (max-width: 575px) {
  .cr-brand-shape .shape-2 {
    display: none;
  }
}

.cr-brand-shape .shape-3 {
  position: absolute;
  bottom: 41%;
  left: 23%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-brand-shape .shape-3 {
    bottom: 43%;
    left: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-brand-shape .shape-3 {
    bottom: 44%;
    left: 17%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-shape .shape-3 {
    left: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-shape .shape-3 {
    bottom: 50%;
    left: 5%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-shape .shape-3 {
    bottom: 42%;
    left: 0%;
  }
}

@media (max-width: 575px) {
  .cr-brand-shape .shape-3 {
    display: none;
  }
}

.cr-brand-shape .shape-4 {
  position: absolute;
  top: 20%;
  left: 34%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-brand-shape .shape-4 {
    top: 17%;
    left: 33%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-brand-shape .shape-4 {
    top: 16%;
    left: 33%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-shape .shape-4 {
    top: 18%;
    left: 28%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-shape .shape-4 {
    top: 14%;
    left: 28%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-shape .shape-4 {
    top: 13%;
    left: 18%;
  }
}

@media (max-width: 575px) {
  .cr-brand-shape .shape-4 {
    display: none;
  }
}

.cr-brand-shape .shape-5 {
  position: absolute;
  top: 7%;
  left: 47%;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-shape .shape-5 {
    right: 16%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-shape .shape-5 {
    top: 5%;
    left: 42%;
  }
}

@media (max-width: 575px) {
  .cr-brand-shape .shape-5 {
    display: none;
  }
}

.cr-brand-shape .shape-6 {
  position: absolute;
  right: 31%;
  top: 20%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-brand-shape .shape-6 {
    right: 26%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-brand-shape .shape-6 {
    right: 23%;
    top: 18%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-shape .shape-6 {
    right: 16%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-shape .shape-6 {
    right: 10%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-shape .shape-6 {
    right: 0%;
    top: 16%;
  }
}

@media (max-width: 575px) {
  .cr-brand-shape .shape-6 {
    display: none;
  }
}

.cr-brand-shape .shape-7 {
  position: absolute;
  right: 22%;
  top: 44%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-brand-shape .shape-7 {
    right: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-brand-shape .shape-7 {
    right: 15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-shape .shape-7 {
    right: 8%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-shape .shape-7 {
    right: 0%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-shape .shape-7 {
    right: 0%;
    top: 44%;
  }
}

@media (max-width: 575px) {
  .cr-brand-shape .shape-7 {
    display: none;
  }
}

.cr-brand-shape .shape-8 {
  position: absolute;
  right: 27%;
  bottom: 13%;
  z-index: 1;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-brand-shape .shape-8 {
    right: 23%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-brand-shape .shape-8 {
    right: 17%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-shape .shape-8 {
    right: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-shape .shape-8 {
    right: 2%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-shape .shape-8 {
    right: 0%;
    bottom: 12%;
  }
}

@media (max-width: 575px) {
  .cr-brand-shape .shape-8 {
    display: none;
  }
}

.cr-brand-shape .shape-9 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-shape .shape-9 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-brand-shape .shape-9 {
    display: none;
  }
}

.cr-brand-thumb {
  position: relative;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-brand-thumb img {
    max-width: 100%;
  }
}

.cr-brand-2-area {
  overflow: hidden;
}

.cr-brand-2-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90%;
  width: 100%;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-2-bg {
    bottom: -35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-2-bg {
    bottom: -220px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-brand-2-item {
    text-align: center;
  }
}

.cr-brand-2-item img {
  box-shadow: 0px 7px 29px rgba(13, 39, 69, 0.05);
  border-radius: 50%;
  transition: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.cr-brand-2-item .shape-1 {
  margin-top: 10px;
}

.cr-brand-2-item .shape-1:hover {
  webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.cr-brand-2-item .shape-2 {
  margin-left: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-2-item .shape-2 {
    margin-left: 180px;
  }
}

@media (max-width: 575px) {
  .cr-brand-2-item .shape-2 {
    margin-left: 50px;
    margin-top: 35px;
  }
}

.cr-brand-2-item .shape-2:hover {
  webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.cr-brand-2-item .shape-3 {
  margin-top: 85px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-2-item .shape-3 {
    margin-top: 20px;
    margin-left: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-brand-2-item .shape-3 {
    margin-top: 35px;
  }
}

.cr-brand-2-item .shape-3:hover {
  webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.cr-brand-2-item .shape-4 {
  margin-top: -80px;
  margin-left: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-2-item .shape-4 {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-2-item .shape-4 {
    margin-top: 0;
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-2-item .shape-4 {
    margin-top: 35px;
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .cr-brand-2-item .shape-4 {
    margin-top: 35px;
    margin-left: 15px;
  }
}

.cr-brand-2-item .shape-4:hover {
  webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.cr-brand-2-item .shape-5 {
  margin-left: 125px;
  margin-top: 85px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-brand-2-item .shape-5 {
    margin-left: 245px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-2-item .shape-5 {
    margin-left: 200px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-brand-2-item .shape-5 {
    margin-left: 80px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-2-item .shape-5 {
    margin-left: 100px;
    margin-top: 45px;
  }
}

@media (max-width: 575px) {
  .cr-brand-2-item .shape-5 {
    margin-left: 40px;
    margin-top: 40px;
  }
}

.cr-brand-2-item .shape-5:hover {
  webkit-transform: translate(-20px, -30px);
  -ms-transform: translate(-20px, -30px);
  transform: translate(-20px, -30px);
  transition-delay: all 4s cubic-bezier(0.2, 0.8, 0.2, 1) 0s !important;
}

.cr-brand-2-trend {
  background: var(--cr-common-white);
  box-shadow: 0px 4px 4px rgba(74, 127, 228, 0.02);
  border-radius: 18px;
}

.cr-brand-2-trend p {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--cr-heading-secondary);
  margin-bottom: 0;
}

.cr-brand-2-trend p a {
  color: var(--cr-theme-secondary);
}

.cr-brand-2-trend p i {
  margin-left: 40px;
  margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-brand-2-trend p i {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media (max-width: 575px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-brand-2-trend p i {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.cr-brand-3-area.breadcrumb-brand {
  background: var(--cr-bg-primary);
}

.cr-brand-3-right-color {
  background: var(--cr-bg-primary);
  position: absolute;
  top: -20px;
  right: 0;
  content: "";
  height: 1100px;
  width: 50%;
  z-index: -1;
}

.cr-brand-3-thumb img {
  object-fit: cover;
}

.cr-brand-3-wrapper {
  border-bottom: 1px solid #E6EFF4;
  padding-bottom: 50px;
}

.cr-brand-active {
  margin-left: -10px;
  margin-right: -150px;
}

.scroll-moving-forward,
.scroll-moving-text {
  clear: both;
  display: inline-block;
  white-space: nowrap;
  padding: 0 10px;
  line-height: 110%;
  font-size: calc(1rem + 4.15vw);
}

/*----------------------------------------*/
/*   support css
/*----------------------------------------*/
.cr-support-feature-area {
  margin-top: -50px;
  overflow: hidden;
}

.cr-support-feature-item {
  background: var(--cr-common-white);
  border: 1px solid rgba(155, 171, 227, 0.28);
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
  padding: 18px 12px;
  z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-support-feature-item {
    padding: 12px 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-support-feature-item {
    padding: 10px 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-support-feature-item {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .cr-support-feature-item {
    padding: 15px 12px;
  }
}

.cr-support-feature-item:hover .cr-support-feature-counter::after {
  opacity: 1;
  width: 100%;
}

.cr-support-feature-item:hover .cr-support-feature-thumb img {
  filter: brightness(4);
}

.cr-support-feature-thumb {
  position: absolute;
  top: 0;
  left: 0;
}

.cr-support-feature-thumb img {
  transition: all 0.4s ease-in-out;
}

.cr-support-feature-counter {
  position: relative;
  display: flex;
  align-items: center;
  background: var(--cr-theme-primary);
  clip-path: polygon(0 0, 100% 0, 85% 50%, 100% 100%, 0 100%, 0 50%);
  width: 133px;
  height: 113px;
  margin-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-support-feature-counter {
    height: 100px;
    width: 175px;
    margin-right: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-support-feature-counter {
    width: 223px;
    height: 120px;
    margin-right: 10px;
  }
}

@media (max-width: 575px) {
  .cr-support-feature-counter {
    margin-right: 12px;
    height: 120px;
    width: 240px;
  }
}

.cr-support-feature-counter .support-feature-title {
  font-weight: 700;
  font-size: 42px;
  color: var(--cr-common-white);
  margin-bottom: 0;
  margin-left: 20px;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
  .cr-support-feature-counter .support-feature-title {
    margin-left: 10px;
  }
}

.cr-support-feature-counter::after {
  position: absolute;
  content: "";
  background: var(--cr-theme-secondary);
  clip-path: polygon(0 0, 100% 0, 85% 50%, 100% 100%, 0 100%, 0 50%);
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.cr-support-feature-content {
  margin-top: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-support-feature-content {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-support-feature-content {
    margin-top: 15px;
  }
}

@media (max-width: 575px) {
  .cr-support-feature-content {
    margin-top: 0px;
  }
}

.cr-support-feature-content-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: var(--cr-theme-primary);
  margin-bottom: 3px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-support-feature-content-title {
    margin-bottom: 4px;
  }
}

.cr-support-feature-content p {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
  .cr-support-feature-content p {
    line-height: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-support-feature-content p br {
    display: none;
  }
}

.cr-support-area {
  overflow: hidden;
}

.cr-support-bg {
  background: linear-gradient(180deg, rgba(243, 254, 249, 0.21) 0%, rgba(158, 234, 229, 0.0973438) 53.65%, rgba(243, 251, 254, 0) 100%);
}

.cr-support-shape .shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.cr-support-shape .shape-2 {
  position: absolute;
  top: -10px;
  left: 155px;
  z-index: -2;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-support-shape .shape-2 {
    display: none;
  }
}

.cr-support-faq.faq-style-1 .cr-faq-tab-content .accordion-item {
  border: none;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.05);
}

.cr-support-faq.faq-style-1 .cr-faq-tab-content .accordion-item .accordion-button {
  padding: 33px 55px 33px 54px;
  color: #020626;
}

@media (max-width: 575px) {
  .cr-support-faq.faq-style-1 .cr-faq-tab-content .accordion-item .accordion-button {
    padding: 30px 55px 30px 30px;
  }
}

.cr-support-faq.faq-style-1 .cr-faq-tab-content .accordion-item .accordion-button::after {
  content: "\f063";
}

.cr-support-faq.faq-style-1 .cr-faq-tab-content .accordion-item .accordion-button:hover {
  color: #020626;
}

.cr-support-faq.faq-style-1 .cr-faq-tab-content .accordion-item.cr-faq-active .accordion-button {
  color: #020626;
}

.cr-support-faq.faq-style-1 .cr-faq-tab-content .accordion-body {
  position: relative;
  padding: 0 59px 37px 54px;
}

@media (max-width: 575px) {
  .cr-support-faq.faq-style-1 .cr-faq-tab-content .accordion-body {
    padding: 0 35px 32px 30px;
  }
}

.cr-support-faq.faq-style-1 .cr-faq-tab-content .accordion-body p {
  font-size: 18px;
  color: #9D9E9F;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-support-breadcrumb {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-support-breadcrumb {
    padding-bottom: 0;
    padding-top: 80px;
  }
}

/*----------------------------------------*/
/*   testimonial css
/*----------------------------------------*/
.cr-testimonial-shape {
  position: absolute;
  bottom: 125px;
  right: 14%;
  z-index: 1;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-testimonial-shape {
    right: 7%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px),
only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-testimonial-shape {
    right: 2%;
  }
}

.cr-testimonial-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  right: 0;
  text-align: center;
}

.cr-testimonial-bg img {
  background: var(--cr-common-white);
  box-shadow: 0px 24px 34px rgba(55, 84, 128, 0.07);
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
}

.cr-testimonial-item-inner {
  background: var(--cr-common-white);
  box-shadow: 0px 4px 41px rgba(0, 0, 0, 0.05);
  padding: 60px 40px 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-testimonial-item-inner {
    padding: 40px 30px 70px;
  }
}

@media (max-width: 575px) {
  .cr-testimonial-item-inner {
    padding: 40px 28px 74px;
  }
}

.cr-testimonial-item-inner p {
  font-weight: 500;
  font-size: 18px;
  line-height: 2;
  letter-spacing: -0.04em;
  color: #33343A;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 575px) {
  .cr-testimonial-item-inner p br {
    display: none;
  }
}

.cr-testimonial-quot {
  margin-bottom: 31px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-testimonial-quot {
    margin-bottom: 30px;
  }
}

.cr-testimonial-quot span {
  color: var(--cr-theme-secondary);
  transition: all 0.3s ease-in-out;
}

.cr-testimonial-rating span i {
  font-weight: 900;
  font-size: 15px;
  line-height: 26px;
  color: #FFB820;
  margin-right: 4px;
}

.cr-testimonial-user-thumb {
  margin-top: -40px;
}

.cr-testimonial-user-thumb img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--cr-common-white);
  border: 6px solid #FFFFFF;
  box-shadow: 0px 4px 4px #EEEEEE;
  margin-bottom: 15px;
}

.cr-testimonial-designation .testimonial-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #08151F;
  margin-bottom: 7px;
}

.cr-testimonial-designation p {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--cr-theme-secondary);
}

.cr-testimonial-2-area {
  background: var(--cr-bg-primary);
}

@media (max-width: 575px) {
  .cr-testimonial-2-area {
    padding-top: 70px;
  }
}

.cr-testimonial-2-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 40%;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-testimonial-2-shape .shape-1 {
    display: none;
  }
}

.cr-testimonial-2-shape .shape-2 {
  position: absolute;
  top: 0;
  left: 21%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-testimonial-2-shape .shape-2 {
    top: 12%;
    left: 12%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-testimonial-2-shape .shape-2 {
    top: 4%;
    left: 8%;
  }
}

@media (max-width: 575px) {
  .cr-testimonial-2-shape .shape-2 {
    display: none;
  }
}

.cr-testimonial-2-shape .shape-3 {
  position: absolute;
  top: 90px;
  right: 24%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-testimonial-2-shape .shape-3 {
    top: 16%;
    right: 14%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-testimonial-2-shape .shape-3 {
    top: 20%;
    right: 7%;
  }
}

@media (max-width: 575px) {
  .cr-testimonial-2-shape .shape-3 {
    display: none;
  }
}

.cr-testimonial-2-shape .shape-4 {
  position: absolute;
  bottom: 10px;
  right: 24%;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-testimonial-2-shape .shape-4 {
    display: none;
  }
}

.cr-testimonial-2-shape .shape-5 {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-testimonial-2-shape .shape-5 {
    display: none;
  }
}

.cr-testimonial-2-np .prev {
  position: absolute;
  content: "";
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: inline-block;
  background: var(--cr-theme-top-secondary);
  top: 52%;
  left: 21%;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-testimonial-2-np .prev {
    left: 14%;
    top: 62%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-testimonial-2-np .prev {
    top: 64%;
    left: 11%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-testimonial-2-np .prev {
    top: 62%;
    left: 7%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-testimonial-2-np .prev {
    display: none;
  }
}

.cr-testimonial-2-np .next {
  position: absolute;
  content: "";
  height: 55px;
  width: 55px;
  border-radius: 50%;
  display: inline-block;
  background: var(--cr-theme-top-secondary);
  top: 52%;
  right: 21%;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-testimonial-2-np .next {
    right: 14%;
    top: 62%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-testimonial-2-np .next {
    top: 64%;
    right: 10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-testimonial-2-np .next {
    top: 62%;
    right: 7%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-testimonial-2-np .next {
    display: none;
  }
}

.cr-testimonial-2-thumb img {
  box-shadow: 0px 7px 29px rgba(13, 39, 69, 0.05);
  border-radius: 50%;
  margin-bottom: 35px;
}

.cr-testimonial-2-info {
  margin-bottom: 26px;
}

.cr-testimonial-2-info-title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #0D0D0D;
}

.cr-testimonial-2-info p {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #0D0D0D;
  margin-bottom: 0;
}

.cr-testimonial-2-text p {
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: #0D0D0D;
  margin-bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-testimonial-2-text p br {
    display: none;
  }
}

.cr-testimonial-2-nav button {
  position: absolute;
  bottom: 42%;
  right: 22%;
  z-index: 1;
}

.cr-testimonial-2-nav button span {
  color: #0d0d0d;
  transition: all 0.3s linear;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-testimonial-2-nav button {
    right: 15%;
    bottom: 32%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-testimonial-2-nav button {
    bottom: 30%;
    right: 12%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-testimonial-2-nav button {
    bottom: 32%;
    right: 9%;
  }
}

.cr-testimonial-2-nav button.testimonial-button-prev-1 {
  left: 22%;
  right: auto;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-testimonial-2-nav button.testimonial-button-prev-1 {
    left: 15%;
    bottom: 32%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-testimonial-2-nav button.testimonial-button-prev-1 {
    left: 13%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-testimonial-2-nav button.testimonial-button-prev-1 {
    left: 9%;
  }
}

.cr-testimonial-3-area {
  margin-top: -68px;
  position: relative;
}

@media (max-width: 575px) {
  .cr-testimonial-3-area {
    padding-bottom: 60px;
  }
}

.cr-testimonial-3-large-box {
  position: absolute;
  top: -117px;
  right: 0;
  content: "";
  background: var(--cr-common-white);
  height: 651px;
  width: calc(100% - 203px);
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.04);
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-testimonial-3-large-box {
    width: calc(100% - 100px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-testimonial-3-large-box {
    width: calc(100% - 40px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-testimonial-3-large-box {
    height: 600px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-testimonial-3-large-box {
    height: 1050px;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-testimonial-3-large-box {
    height: 1150px;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .cr-testimonial-3-large-box {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-testimonial-3-shape {
    display: none;
  }
}

.cr-testimonial-3-shape .shape-1 {
  position: absolute;
  top: -315px;
  left: 0;
  z-index: -1;
}

.cr-testimonial-3-shape .shape-2 {
  position: absolute;
  top: -117px;
  right: 0;
  z-index: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-testimonial-3-shape .shape-2 {
    top: -170px;
  }
}

.cr-testimonial-3-wrapper-thumb .slide {
  position: relative;
  margin-left: 71px;
  z-index: 1;
  height: 430px;
  width: 370px;
  object-fit: cover;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
  .cr-testimonial-3-wrapper-thumb .slide {
    margin-left: 0;
    width: 100%;
  }
}

.cr-testimonial-3-wrapper-thumb .shape-1 {
  position: absolute;
  top: -60px;
  left: 10px;
  animation: tprotate 10s ease-in-out 5s forwards infinite alternate;
  z-index: 0;
}

@media (max-width: 575px) {
  .cr-testimonial-3-wrapper-thumb .shape-1 {
    left: -10px;
  }
}

.cr-testimonial-3-wrapper-thumb .shape-2 {
  position: absolute;
  bottom: 19px;
  left: 15px;
  z-index: 1;
}

@media (max-width: 575px) {
  .cr-testimonial-3-wrapper-thumb .shape-2 {
    display: none;
  }
}

.cr-testimonial-3-slider-wrapper p {
  font-family: var(--cr-ff-heading);
  font-weight: 500;
  font-size: 28px;
  line-height: 1.8;
  margin-bottom: 40px;
  color: var(--cr-heading-secondary);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-testimonial-3-slider-wrapper p br {
    display: none;
  }
}

.cr-testimonial-3-slider-wrapper p span {
  color: var(--cr-theme-secondary);
}

.cr-testimonial-3-content {
  padding: 45px 80px 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-testimonial-3-content {
    padding: 0px 60px 0 20px;
  }
}

@media (max-width: 575px) {
  .cr-testimonial-3-content {
    padding: 15px;
  }
}

.cr-testimonial-3-descreiption {
  position: relative;
}

.cr-testimonial-3-descreiption .testimonial-title {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #0F0F2D;
  margin-bottom: 3px;
}

.cr-testimonial-3-descreiption p {
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #0F0F2D;
}

.cr-testimonial-3-descreiption p span {
  color: var(--cr-theme-secondary);
}

.cr-text-slider-area {
  background: linear-gradient(180deg, rgba(243, 254, 249, 0.21) 0%, rgba(158, 234, 229, 0.0973438) 53.65%, rgba(243, 251, 254, 0) 100%);
}

.cr-text-slider-item {
  text-align: center;
  margin-right: 40px;
}

.cr-text-slider-item img {
  margin-right: 30px;
}

.cr-text-slider-item .text-title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--cr-theme-primary);
}

@media only screen and (min-width: 1801px) and (max-width: 1900px) {
  .cr-text-slider-item .text-title {
    font-size: 55px;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .cr-text-slider-item .text-title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-text-slider-item .text-title {
    font-size: 49px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-text-slider-item .text-title {
    font-size: 42px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-text-slider-item .text-title {
    font-size: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-text-slider-item .text-title {
    font-size: 26px;
  }
}

@media (max-width: 575px) {
  .cr-text-slider-item .text-title {
    font-size: 30px;
  }
}

.cr-text-slider-item .text-title span {
  color: var(--cr-theme-secondary);
}

.testimonial-active .swiper-slide.swiper-slide-active .cr-testimonial-quot span svg {
  fill: var(--cr-theme-secondary);
}

.testimonial-3-active .splide__pagination {
  bottom: -55px;
  left: 0;
  border: 1px dashed #D9D9D9;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05));
  border-radius: 30.5px;
  width: 146px;
  height: 41px;
}

@media (max-width: 575px) {
  .testimonial-3-active .splide__pagination {
    display: none;
  }
}

.testimonial-3-active .splide__pagination li {
  margin-top: -5px;
}

.testimonial-3-active .splide__pagination .splide__pagination__page {
  background: #E3E3E3;
  margin-left: 10px;
  height: 10px;
  width: 10px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.testimonial-3-active .splide__pagination .splide__pagination__page::after {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.3s ease-in-out;
}

.testimonial-3-active .splide__pagination .is-active {
  background: var(--cr-theme-secondary);
  transform: scale(1);
}

.testimonial-3-active .splide__pagination .is-active::after {
  border-color: var(--cr-theme-secondary);
  transform: translate(-50%, -50%) scale(1);
}

/*----------------------------------------*/
/*  blog css
/*----------------------------------------*/
.cr-blog-area {
  background: var(--cr-bg-primary);
  overflow: hidden;
}

.cr-blog-text p {
  position: relative;
  color: #8A90A3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-blog-text p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-blog-text p {
    margin-bottom: 50px;
  }
}

@media (max-width: 575px) {
  .cr-blog-text p {
    margin-bottom: 30px;
  }
}

.cr-blog-text p::after {
  position: absolute;
  content: "";
  top: 10px;
  left: -14px;
  height: 41px;
  width: 2px;
  background: var(--cr-theme-secondary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-blog-text p::after {
    height: 67px;
  }
}

.cr-blog-content {
  background: var(--cr-common-white);
  padding: 25px 50px 30px 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-blog-content {
    padding: 25px 20px 30px 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-blog-content {
    padding: 25px 25px 30px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-blog-content {
    padding: 25px 25px 30px 25px;
  }
}

@media (max-width: 575px) {
  .cr-blog-content {
    padding: 25px 20px 25px 20px;
  }
}

.cr-blog-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: var(--cr-theme-primary);
  transition: 0.3s;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-blog-title br {
    display: none;
  }
}

.cr-blog-title a:hover {
  color: var(--cr-theme-secondary);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-blog-title-wrapper .cr-section-title {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-blog-title-wrapper .cr-section-title {
    font-size: 32px;
  }
}

.cr-blog-wrapper:hover .cr-blog-thumb::after {
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, 150%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, 150%, 0);
}

.cr-blog-tag {
  position: absolute;
  bottom: 18px;
  left: 0;
}

.cr-blog-tag p {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #132047;
  letter-spacing: 0.135em;
  background: var(--cr-theme-secondary);
  clip-path: polygon(0px 0px, 100% 0px, 9% 53.45%, 100% 100%, 0px 100%, 0px 50%);
  width: 130px;
}

.cr-blog-thumb {
  position: relative;
  overflow: hidden;
}

.cr-blog-thumb::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, -150%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, -150%, 0);
}

.cr-blog-thumb img {
  transition: all 0.3s 0s ease-out;
  max-width: 100%;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-blog-thumb img {
    min-width: 100%;
  }
}

.cr-blog-date span {
  font-weight: 400;
  font-size: 15px;
  color: var(--cr-theme-primary);
  opacity: 0.6;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-blog-date span {
    margin-right: 0;
  }
}

.cr-blog-date span i {
  color: var(--cr-theme-secondary);
  margin-right: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-blog-date span i {
    margin-right: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-blog-date span i {
    margin-right: 3px;
  }
}

.cr-blog-btn .read-more a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: ruby;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #132047;
  transition: 0.3s;
}

.cr-blog-btn .read-more a span svg {
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.cr-blog-btn .read-more a span::before {
  position: absolute;
  top: 1px;
  right: -10px;
  content: "";
  border: 1px solid #E8F2F9;
  height: 28px;
  width: 28px;
  display: inline-block;
  border-radius: 50%;
}

.cr-blog-btn .read-more a:hover {
  color: var(--cr-theme-secondary);
}

.cr-blog-btn .read-more a:hover span svg {
  animation: tfLeftToRight 0.5s forwards;
}

.cr-blog-2-area {
  background: var(--cr-bg-primary);
  overflow: hidden;
  z-index: 0;
}

.cr-blog-2-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: var(--cr-heading-primary);
  height: 72%;
  width: 100%;
  z-index: -1;
}

.cr-blog-2-shape .shape-1 {
  position: absolute;
  bottom: 215px;
  left: -90px;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-blog-2-shape .shape-1 {
    display: none;
  }
}

.cr-blog-2-title-wrapper .cr-section-title {
  color: var(--cr-common-white);
}

.cr-blog-2-title-wrapper .cr-section-title__pre {
  color: var(--cr-common-white);
}

.cr-blog-2-wrapper {
  position: relative;
}

.cr-blog-2-wrapper::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 2px;
  bottom: 0px;
  left: auto;
  right: 0;
  background-color: var(--cr-theme-secondary);
  transition: all 0.3s ease-out 0s;
}

.cr-blog-2-wrapper:hover .cr-blog-2-thumb a::after {
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, 150%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, 150%, 0);
}

.cr-blog-2-wrapper:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

.cr-blog-2-thumb {
  position: relative;
  background: #161A37;
  padding: 14px 13px 0 14px;
  overflow: hidden;
}

.cr-blog-2-thumb a {
  position: relative;
  display: block;
  overflow: hidden;
}

.cr-blog-2-thumb a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, -150%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, -150%, 0);
}

.cr-blog-2-thumb img {
  max-width: 100%;
}

@media (max-width: 575px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-blog-2-thumb img {
    width: 100%;
  }
}

.cr-blog-2-tag-name {
  background: var(--cr-theme-secondary);
  width: 126px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.cr-blog-2-tag-name p {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #1B1A1A;
}

.cr-blog-2-content {
  background: var(--cr-common-white);
  padding: 25px 20px 30px 27px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
  .cr-blog-2-content {
    padding: 25px 15px 30px 20px;
  }
}

.cr-blog-2-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: var(--cr-theme-primary);
  margin-bottom: 12px;
}

.cr-blog-2-title a {
  background-image: linear-gradient(#03041C, #03041C), linear-gradient(#03041C, #03041C);
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-blog-2-title a br {
    display: none;
  }
}

.cr-blog-2-title a:hover {
  background-size: 0 1px, 100% 1px;
}

.cr-blog-2-btn a {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: var(--cr-theme-primary);
}

.cr-blog-2-btn a span {
  margin-left: 5px;
  transform: translateY(1px);
  display: inline-block;
}

.cr-blog-2-btn a:hover {
  color: var(--cr-theme-secondary);
}

.cr-blog-2-btn a:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.cr-blog-2-nav .blog-button-next-1 {
  position: absolute;
  top: 50%;
  right: 35px;
  color: var(--cr-common-white);
  font-size: 18px;
  z-index: 1;
}

.cr-blog-2-nav .blog-button-prev-1 {
  position: absolute;
  top: 50%;
  left: 35px;
  color: var(--cr-common-white);
  font-size: 18px;
  z-index: 1;
}

.cr-blog-3-area {
  background: #F1FBFE;
}

.cr-blog-3-shape .shape-1 {
  position: absolute;
  right: -100px;
  top: 0px;
}

.cr-blog-3-shape .shape-2 {
  position: absolute;
  left: -120px;
  bottom: 0;
}

.cr-blog-3-thumb {
  position: relative;
  overflow: hidden;
}

.cr-blog-3-thumb a img {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-blog-3-thumb a img {
    min-width: 100%;
  }
}

.cr-blog-3-thumb::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, -150%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, -150%, 0);
}

.cr-blog-3-user {
  display: flex;
  position: absolute;
  left: 22px;
  top: 28px;
}

.cr-blog-3-user.active::after {
  width: 145px;
}

.cr-blog-3-user.active p {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.cr-blog-3-user img {
  z-index: 1;
}

.cr-blog-3-user::after {
  position: absolute;
  content: "";
  background: var(--cr-common-white);
  height: 38px;
  width: 36px;
  border-radius: 19.5px;
  left: 0;
  top: 0;
  z-index: 0;
  transition: all 0.3s ease-out;
}

.cr-blog-3-user:hover::after {
  width: 145px;
}

.cr-blog-3-user:hover p {
  opacity: 1;
}

.cr-blog-3-user p {
  font-weight: 700;
  font-size: 12px;
  line-height: 26px;
  text-transform: uppercase;
  color: #1B1A1A;
  margin-left: 10px;
  margin-bottom: 0;
  margin-top: 7px;
  opacity: 0;
  z-index: 2;
}

.cr-blog-3-content {
  position: relative;
  padding: 22px 30px 8px 26px;
  border-bottom: 1px solid #EBEBEB;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-blog-3-content {
    padding: 22px 20px 8px 20px;
  }
}

@media (max-width: 575px) {
  .cr-blog-3-content {
    padding: 15px;
  }
}

.cr-blog-3-wrapper {
  position: relative;
  border: 1px solid #EBEBEB;
  background: var(--cr-common-white);
  box-shadow: 0px 13px 26px #EEF0F8;
}

.cr-blog-3-wrapper:hover .cr-blog-3-thumb::before {
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, 150%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, -45deg) translate3d(0, 150%, 0);
}

.cr-blog-3-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #191B1E;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 575px) {
  .cr-blog-3-title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-blog-3-title br {
    display: none;
  }
}

.cr-blog-3-title a:hover {
  color: var(--cr-theme-secondary);
}

.cr-blog-3-btn {
  padding: 13px 25px 30px 30px;
}

.cr-blog-3-btn .read-more::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 119px;
  background: #121C22;
  bottom: -6px;
  left: -4px;
  transition: all 0.3s;
}

.cr-blog-3-btn .read-more:hover::after {
  background: var(--cr-theme-secondary);
}

.cr-blog-3-btn .read-more a {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-transform: capitalize;
  color: var(--cr-heading-secondary);
}

.cr-blog-3-btn .read-more a span svg {
  margin-left: 12px;
  transition: all 0.3s;
}

.cr-blog-3-btn .read-more a:hover {
  color: var(--cr-theme-secondary);
}

.cr-blog-3-btn .read-more a:hover span svg {
  transform: rotate(40deg);
}

/*----------------------------------------*/
/*   offer css
/*----------------------------------------*/
.cr-offer-area {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.cr-offer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(243, 251, 254, 0.7) 55.73%, rgba(243, 251, 254, 0.7) 100%);
  z-index: -1;
}

.cr-offer-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.5;
}

.cr-offer-shape .shape-2 {
  position: absolute;
  right: 124px;
  top: 210px;
  animation: tprotate 2s ease-in-out 0.1s forwards infinite alternate;
}

.cr-offer-wrapper {
  position: relative;
  background: var(--cr-common-white);
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.04);
  padding: 48px 0px 35px 0px;
  border: 1px solid var(--cr-grey-1);
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  z-index: 1;
}

.cr-offer-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  /* clip-path: polygon(0 0, 100% 100%, 100% 0%); */
  background: #39991829;
  height: 100%;
  width: 0px;
  transition: all 0.4s;
  clip-path: polygon(100% 100%, 0 100%, 100% 0);
  z-index: -1;
  border-bottom-right-radius: 25px;
}

.cr-offer-wrapper:hover .cr-offer-wrapper-thumb {
  animation: icon-bounce 0.8s 1;
}

.cr-offer-wrapper:hover::after {
  width: 100%;
}

.cr-offer-wrapper:hover .cr-offer-wrapper-btn a i {
  transform: rotate(45deg);
}

.cr-offer-wrapper .offer-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  margin-bottom: 22px;
  color: var(--cr-theme-primary);
}

.cr-offer-wrapper-thumb img {
  margin-bottom: 30px;
  height: 66px;
}

.cr-offer-wrapper-btn a {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: var(--cr-theme-secondary);
  transition: all 0.3s ease-in;
}

.cr-offer-wrapper-btn a:hover {
  color: var(--cr-theme-primary);
}

.cr-offer-wrapper-btn a i {
  transform: translateY(2px);
  margin-left: 10px;
  transition: all 0.3s ease-in;
}

.cr-offer-all-btn p {
  position: relative;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--cr-heading-secondary);
  margin-bottom: 0;
}

.cr-offer-all-btn p a {
  color: var(--cr-theme-secondary);
  text-decoration: underline;
}

.cr-offer-all-btn p a span {
  margin-left: 10px;
  background: var(--cr-common-white);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: var(--cr-theme-secondary);
}

.cr-offer-all-btn p a span i {
  transform: translateX(2px);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-feature-wrapper.offer p::after {
    left: 95px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-feature-wrapper.offer p::after {
    height: 45px;
  }
}

/*----------------------------------------*/
/*   offer css
/*----------------------------------------*/
.cr-portfolio-item-wrapper:hover .cr-portfolio-item-thumb img {
  transform: scale(1.05);
}

.cr-portfolio-item-wrapper:hover .cr-portfolio-item-content {
  transform: translateY(-20px);
}

.cr-portfolio-item-thumb {
  overflow: hidden;
}

.cr-portfolio-item-thumb img {
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-portfolio-item-thumb img {
    width: 100%;
  }
}

.cr-portfolio-item-content {
  background-color: var(--cr-common-white);
  padding: 35px 25px 32px;
  filter: drop-shadow(0px 3px 13px rgba(0, 0, 0, 0.078));
  margin-top: -85px;
  margin-bottom: 40px;
  margin-right: 20px;
  margin-left: 20px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-portfolio-item-content {
    padding: 25px 15px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-portfolio-item-content {
    padding: 20px 20px 20px;
  }
}

.cr-portfolio-item-content:hover {
  background-color: var(--cr-theme-primary);
}

.cr-portfolio-item-content:hover .cr-portfolio-item-title {
  color: var(--cr-common-white);
}

.cr-portfolio-item-content-btn a {
  font-size: 15px;
  font-weight: 400;
  color: var(--cr-theme-secondary);
}

.cr-portfolio-item-content-btn a:hover i {
  animation: tfLeftToRight 0.5s forwards;
}

.cr-portfolio-item-content-btn a i {
  margin-left: 5px;
}

.cr-portfolio-item-subtitle {
  text-transform: uppercase;
  color: #969FA5;
  font-size: 14px;
  font-weight: 500;
}

.cr-portfolio-item-title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  transition: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-portfolio-item-title {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .cr-portfolio-item-title {
    font-size: 20px;
  }
}

.cr-portfolio-details-wrapper {
  margin-bottom: 60px;
}

.cr-portfolio-details-wrapper p {
  line-height: 1.95;
  color: #5C727D;
  margin-bottom: 40px;
}

.cr-portfolio-details-thumb {
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-portfolio-details-thumb img {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-portfolio-details-meta {
    margin-bottom: 15px;
  }
}

.cr-portfolio-details-meta-item {
  margin-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-portfolio-details-meta-item {
    margin-right: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-portfolio-details-meta-item {
    margin-right: 40px;
    margin-bottom: 30px;
  }
}

.cr-portfolio-details-meta-item p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #949392;
  margin-bottom: 0;
}

.cr-portfolio-details-meta-title {
  font-weight: 600;
  font-size: 16px;
  color: var(--cr-heading-secondary);
}

.cr-portfolio-details-meta-icon {
  margin-right: 10px;
}

.cr-portfolio-details-btn a {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  padding: 14px 55px;
  text-transform: capitalize;
  color: var(--cr-common-white);
  background: var(--cr-theme-secondary);
  transition: 0.3s;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-portfolio-details-btn a {
    margin-top: 20px;
  }
}

.cr-portfolio-details-btn a:hover {
  background: var(--cr-theme-primary);
}

.cr-portfolio-details-title {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 15px;
  color: var(--cr-theme-primary);
}

.cr-portfolio-details-list-title {
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 18px;
  color: var(--cr-theme-primary);
}

.cr-portfolio-details-list ul li {
  list-style: none;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
}

.cr-portfolio-details-list ul li span {
  color: var(--cr-theme-secondary);
  margin-right: 8px;
}

.cr-portfolio-details-info-box {
  background: var(--cr-common-white);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 60px 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-portfolio-details-info-box {
    padding: 50px 30px;
  }
}

@media (max-width: 575px) {
  .cr-portfolio-details-info-box {
    padding: 40px 20px;
  }
}

.cr-portfolio-details-info-title {
  font-family: var(--cr-ff-body);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: capitalize;
  color: var(--cr-heading-secondary);
}

.cr-portfolio-details-info-title span {
  margin-right: 12px;
}

.cr-portfolio-details-info-address {
  border-bottom: 1px solid rgba(21, 19, 19, 0.11);
  padding-bottom: 18px;
  margin-bottom: 30px;
}

.cr-portfolio-details-info-address p {
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #696969;
  line-height: 1.9;
}

.cr-portfolio-details-info-mail {
  border-bottom: 1px solid rgba(21, 19, 19, 0.11);
  padding-bottom: 18px;
  margin-bottom: 30px;
}

.cr-portfolio-details-info-mail-content span {
  margin-right: 10px;
}

.cr-portfolio-details-info-mail-text p {
  text-transform: capitalize;
  color: #696969;
  margin-bottom: 6px;
}

.cr-portfolio-details-info-open {
  margin-bottom: 50px;
}

.cr-portfolio-details-info-open-icon {
  height: 78px;
  width: 78px;
  text-align: center;
  line-height: 78px;
  border-radius: 3px;
  background-color: var(--cr-theme-secondary);
  margin-right: 15px;
}

.cr-portfolio-details-info-open-content p {
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #696969;
  margin-bottom: 5px;
}

.cr-portfolio-details-info-social a {
  color: var(--cr-theme-primary);
  background: rgba(72, 164, 185, 0.06);
  border-radius: 73px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-portfolio-details-info-social a {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-portfolio-details-info-social a {
    margin-right: 4px;
  }
}

@media (max-width: 575px) {
  .cr-portfolio-details-info-social a {
    margin-right: 10px;
  }
}

.cr-portfolio-details-info-social a:hover {
  color: var(--cr-common-white);
  background-color: var(--cr-theme-secondary);
}

/*----------------------------------------*/
/*   testimonial css
/*----------------------------------------*/
.cr-business-area {
  overflow: hidden;
}

.cr-business-shape .shape-1 {
  position: absolute;
  top: 223px;
  left: 325px;
  z-index: 1;
  animation: moving 9s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-business-shape .shape-1 {
    top: 20%;
    left: 10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-business-shape .shape-1 {
    top: 150px;
    left: 120px;
  }
}

.cr-business-shape .shape-2 {
  position: absolute;
  top: 132px;
  right: 175px;
  animation: tpupdown 1s infinite alternate;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-business-shape .shape-2 {
    top: 90px;
    right: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-business-shape .shape-2 {
    top: 100px;
    right: 60px;
  }
}

.cr-business-shape .shape-3 {
  position: absolute;
  top: 50px;
  left: 34px;
  animation: jumpTwo 5s infinite linear;
}

.cr-business-shape .shape-4 {
  position: absolute;
  top: 40%;
  left: 0;
}

@media (max-width: 575px) {
  .cr-business-shape .shape-4 {
    top: 35%;
  }
}

.cr-business-thumb-wrapper {
  transform: translateX(-12%);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-business-thumb-wrapper {
    transform: translateX(0);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-business-thumb-wrapper {
    margin-bottom: 120px;
  }
}

.cr-business-thumb img {
  position: relative;
  /* transform: translateY(-8%); */
  z-index: 1;
  /* border-radius: 50%; */
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-business-thumb img {
    transform: translateY(0%);
  }
}

@media (max-width: 575px) {
  .cr-business-thumb img {
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .cr-business-thumb-shape {
    text-align: center;
  }
}

.cr-business-thumb-shape .shape-1 {
  position: absolute;
  bottom: -12px;
  left: 30px;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-business-thumb-shape .shape-1 {
    left: -10px;
  }
}

@media (max-width: 575px) {
  .cr-business-thumb-shape .shape-1 {
    position: static;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.cr-business-thumb-shape .shape-2 {
  position: absolute;
  bottom: -72px;
  left: 200px;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-business-thumb-shape .shape-2 {
    left: 145px;
  }
}

@media (max-width: 575px) {
  .cr-business-thumb-shape .shape-2 {
    position: static;
    margin-left: 30px;
  }
}

.cr-business-title-wrapper {
  margin-bottom: 40px;
}

.cr-business-title-wrapper p {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #5A627D;
  margin-bottom: 0;
  margin-left: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-business-title-wrapper p br {
    display: none;
  }
}

.cr-business-title-wrapper p::after {
  position: absolute;
  content: "";
  background: var(--cr-theme-secondary);
  width: 2px;
  height: 41px;
  left: -10px;
  top: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-business-title-wrapper p::after {
    height: 70px;
  }
}

.cr-business-box {
  border: 1px solid #E0E8E7;
  border-radius: 6px;
  padding: 15px 15px 26px 22px;
  transition: all 0.3s;
}

.cr-business-box:hover {
  border-color: var(--cr-theme-secondary);
}

.cr-business-box-title {
  margin-bottom: 20px;
}

.cr-business-box-title span {
  background: rgba(255, 255, 255, 0.52);
  box-shadow: 0px 4px 14px rgba(65, 118, 167, 0.11);
  border-radius: 83px;
  color: var(--cr-theme-secondary);
  height: 54px;
  width: 54px;
  text-align: center;
  line-height: 52px;
  margin-right: 12px;
  flex: 0 0 auto;
}

.cr-business-box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #5A627D;
  margin-bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 575px) {
  .cr-business-box p br {
    display: none;
  }
}

.cr-business-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--cr-theme-primary);
  margin-bottom: 0;
}

.cr-business-btn-area img {
  margin-left: 20px;
  transform: translateY(5px);
}

.cr-business-btn-area i {
  font-family: var(--cr-ff-heading);
  position: relative;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  text-transform: capitalize;
  color: var(--cr-theme-primary);
  margin-left: 5px;
}

.cr-business-btn-area i span {
  position: absolute;
  bottom: -12px;
  right: -2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-business-btn-area i {
    margin-left: 30px;
  }
}

@media (max-width: 575px) {
  .cr-business-btn-area i {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .circle-animation {
    display: none;
  }
}

.circle-animation.business-2 {
  top: 50%;
  left: 50%;
  border: 1px solid #EDF1F8;
  width: 480px;
  height: 480px;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .circle-animation.business-2 {
    top: 28%;
    width: 700px;
    height: 700px;
  }
}

@media (max-width: 575px) {
  .circle-animation.business-2 {
    top: 32%;
    width: 330px;
    height: 330px;
  }
}

.circle-animation.business-3 {
  top: 50%;
  left: 50%;
  border: 1px solid #EDF1F8;
  width: 368px;
  height: 368px;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .circle-animation.business-3 {
    top: 28%;
    width: 700px;
    height: 700px;
  }
}

@media (max-width: 575px) {
  .circle-animation.business-3 {
    top: 32%;
    width: 330px;
    height: 330px;
  }
}

.circle-animation.business-4 {
  top: 50%;
  left: 50%;
  border: 1px solid #EDF1F8;
  width: 600px;
  height: 600px;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .circle-animation.business-4 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .circle-animation.business-4 {
    top: 28%;
    width: 700px;
    height: 700px;
  }
}

@media (max-width: 575px) {
  .circle-animation.business-4 {
    top: 32%;
    width: 330px;
    height: 330px;
  }
}

.circle-animation span.cr-circle-1 {
  display: block;
  position: absolute;
  content: "";
  top: calc(50% - 1px);
  left: 50%;
  width: 50%;
  height: 1px;
  background: transparent;
  transform-origin: left;
  -webkit-animation: circle-animation 13s linear infinite;
  -moz-animation: circle-animation 13s linear infinite;
  -ms-animation: circle-animation 13s linear infinite;
  -o-animation: circle-animation 13s linear infinite;
  animation: circle-animation 13s linear infinite;
}

.circle-animation span.cr-circle-1::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: -25px;
  right: -6px;
  background-color: var(--cr-theme-secondary);
}

.circle-animation span.cr-circle-2 {
  display: block;
  position: absolute;
  content: "";
  top: calc(50% - 1px);
  left: 50%;
  width: 50%;
  height: 1px;
  background: transparent;
  transform-origin: left;
  transform: scale(-1, 1);
  -webkit-animation: circle-animation2 7s linear infinite;
  -moz-animation: circle-animation2 7s linear infinite;
  -o-animation: circle-animation2 7s linear infinite;
  animation: circle-animation2 7s linear infinite;
}

.circle-animation span.cr-circle-2::after {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  bottom: -25px;
  right: -7px;
  background-color: #FFA654;
}

.circle-animation span.cr-circle-3 {
  display: block;
  position: absolute;
  content: "";
  top: calc(50% - 1px);
  left: 50%;
  width: 50%;
  height: 1px;
  background: transparent;
  transform-origin: left;
  -webkit-animation: circle-animation3 10s linear infinite;
  -moz-animation: circle-animation3 10s linear infinite;
  -o-animation: circle-animation3 10s linear infinite;
  animation: circle-animation3 10s linear infinite;
}

.circle-animation span.cr-circle-3::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: -25px;
  right: -6px;
  background-color: var(--cr-theme-secondary);
}

.circle-animation span.cr-circle-5 {
  display: block;
  position: absolute;
  content: "";
  top: calc(50% - 1px);
  left: 50%;
  width: 50%;
  height: 1px;
  background: transparent;
  transform-origin: left;
  transform: rotate(-50deg);
  -webkit-animation: circle-animation8 13s linear infinite;
  -moz-animation: circle-animation8 13s linear infinite;
  -ms-animation: circle-animation8 13s linear infinite;
  -o-animation: circle-animation8 13s linear infinite;
  animation: circle-animation8 13s linear infinite;
}

.circle-animation span.cr-circle-5::after {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  bottom: -25px;
  right: -7px;
  background-color: var(--cr-theme-secondary);
}

.circle-animation span.cr-circle-6 {
  display: block;
  position: absolute;
  content: "";
  top: calc(50% - 1px);
  left: 50%;
  width: 50%;
  height: 1px;
  background: transparent;
  transform-origin: left;
  -webkit-animation: circle-6 13s linear infinite;
  -moz-animation: circle-6 13s linear infinite;
  -ms-animation: circle-6 13s linear infinite;
  -o-animation: circle-6 13s linear infinite;
  animation: circle-6 13s linear infinite;
}

.circle-animation span.cr-circle-6::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: -25px;
  right: -6px;
  background-color: var(--cr-theme-top-secondary);
}

.circle-animation span.cr-circle-7 {
  display: block;
  position: absolute;
  content: "";
  top: calc(50% - 1px);
  left: 50%;
  width: 50%;
  height: 1px;
  background: transparent;
  transform-origin: left;
  -webkit-animation: circle-animation99 8s linear infinite;
  -moz-animation: circle-animation99 8s linear infinite;
  -ms-animation: circle-animation99 8s linear infinite;
  -o-animation: circle-animation99 8s linear infinite;
  animation: circle-animation99 8s linear infinite;
}

.circle-animation span.cr-circle-7::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: -25px;
  right: -6px;
  background-color: #FFA654;
}

.circle-animation span.cr-circle-8 {
  display: block;
  position: absolute;
  content: "";
  top: calc(50% - 1px);
  left: 50%;
  width: 50%;
  height: 1px;
  background: transparent;
  transform-origin: left;
  -webkit-animation: circle-animation88 13s linear infinite;
  -moz-animation: circle-animation88 13s linear infinite;
  -ms-animation: circle-animation88 13s linear infinite;
  -o-animation: circle-animation88 13s linear infinite;
  animation: circle-animation88 13s linear infinite;
}

.circle-animation span.cr-circle-8::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: -25px;
  right: -6px;
  background-color: var(--cr-theme-primary);
}

/*----------------------------------------*/
/*   fun fact css
/*----------------------------------------*/
.cr-industry-area {
  background: rgba(232, 247, 253, 0.5);
  overflow: hidden;
}

.cr-industry-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .cr-industry-shape .shape-1 {
    left: 49%;
    transform: translateX(-51%);
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-industry-shape .shape-1 {
    left: 45%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-industry-shape .shape-1 {
    left: 38%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-industry-shape .shape-1 {
    left: 27%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-industry-shape .shape-1 {
    left: 12%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-industry-shape .shape-1 {
    display: none;
  }
}

.cr-industry-shape .shape-2 {
  position: absolute;
  bottom: -55%;
  left: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-industry-shape .shape-2 {
    display: none;
  }
}

.cr-industry-shape .shape-3 {
  position: absolute;
  bottom: -55%;
  right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-industry-shape .shape-3 {
    display: none;
  }
}

.cr-industry-wrapper {
  position: relative;
}

.cr-industry-wrapper:hover::before {
  width: 31px;
}

.cr-industry-wrapper::before {
  /* content: ""; */
  position: absolute;
  bottom: 0;
  left: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  background: var(--cr-theme-secondary);
  height: 27px;
  width: 0px;
  transition: all 0.4s;
}

.cr-industry-content {
  background: var(--cr-common-white);
  border-radius: 5px;
  padding: 55px 20px 51px 51px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-industry-content {
    padding: 55px 20px 45px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-industry-content {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-industry-content {
    padding: 55px 20px 50px 35px;
  }
}

@media (max-width: 575px) {
  .cr-industry-content {
    padding: 30px;
  }
}

.cr-industry-content-title {
  position: relative;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  text-transform: capitalize;
  margin-bottom: 40px;
  color: var(--cr-theme-primary);
  transform: translateX(10px);
}

@media (max-width: 575px) {
  .cr-industry-content-title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .cr-industry-content-title {
    font-size: 19px;
  }
}

.cr-industry-content-title::after {
  position: absolute;
  content: "";
  background: #05DAC3;
  width: 2px;
  height: 56px;
  left: -10px;
  top: 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-industry-content-title::after {
    left: 256px;
  }
}

.cr-industry-thumb {
  position: relative;
  margin-right: 80px;
}

@media (max-width: 575px) {
  .cr-industry-thumb {
    margin-right: 31px;
    margin-bottom: 20px;
  }
}

.cr-industry-thumb:last-child {
  margin-right: 0px;
}

.cr-industry-thumb:not(:last-of-type)::before {
  position: absolute;
  content: "";
  top: -12px;
  right: -48px;
  height: 128px;
  width: 1px;
  background: #EBEBEB;
}

@media (max-width: 575px) {
  .cr-industry-thumb:not(:last-of-type)::before {
    display: none;
  }
}

.cr-industry-thumb-wrapper {
  position: relative;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-industry-thumb-wrapper {
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .cr-industry-thumb-wrapper {
    flex-wrap: wrap;
  }
}

.cr-industry-thumb img {
  height: 61px;
  margin-bottom: 20px;
}

.cr-industry-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: var(--cr-heading-secondary);
}

.cr-industry-btn .icon {
  display: inline-block;
  height: 43px;
  width: 43px;
  border-radius: 50%;
  /* line-height: 42px; */
  text-align: center;
  border: 1px solid #EBEBEB;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.09));
  padding: 5px;
}

.cr-industry-btn a {
  font-weight: 400;
  font-size: 15px;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: var(--cr-heading-secondary);
  margin-left: 10px;
}

.cr-industry-btn a:hover span {
  color: var(--cr-theme-secondary);
}

.cr-industry-btn a span {
  transition: all 0.3s ease-in-out;
  text-decoration: underline;
}

.cr-industry-tab {
  background: var(--cr-common-white);
  border-radius: 5px;
  padding: 55px 60px 61px 51px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-industry-tab {
    padding: 55px 40px 56px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-industry-tab {
    padding: 55px 60px 30px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-industry-tab {
    padding: 55px 60px 20px 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-industry-tab {
    padding: 55px 30px 15px 30px;
  }
}

@media (max-width: 575px) {
  .cr-industry-tab {
    padding: 30px;
  }
}

.cr-industry-tab .nav-pills .nav-link.active,
.cr-industry-tab .nav-pills .show>.nav-link {
  background-color: transparent;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--cr-common-white);
  z-index: 2;
}

.cr-industry-tab .nav-pills .nav-link.active::after,
.cr-industry-tab .nav-pills .show>.nav-link::after {
  height: 100%;
  z-index: -1;
}

.cr-industry-tab #pills-tab {
  border-bottom: 1px solid #EFF0F2;
}

.cr-industry-tab .nav-item .nav-link {
  position: relative;
  border-radius: 0;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #1D1D1D;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 30px;
}

.cr-industry-tab .nav-item .nav-link::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0%;
  background-color: var(--cr-theme-primary);
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}

.cr-industry-tab-content {
  margin-top: 40px;
}

@media (max-width: 575px) {
  .cr-industry-tab-content-inner {
    flex-wrap: wrap;
  }
}

.cr-industry-tab-content img {
  position: relative;
  margin-right: 20px;
  border-radius: 10px;
  width: 212px;
  height: 138px;
  object-fit: cover;
}

@media (max-width: 575px) {
  .cr-industry-tab-content img {
    margin-bottom: 20px;
  }
}

.cr-industry-tab-content a {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30%;
  height: 48px;
  width: 48px;
  display: inline-block;
  line-height: 48px;
  border-radius: 50%;
  text-align: center;
  color: var(--cr-common-white);
  background: var(--cr-theme-secondary);
}

.cr-industry-tab-content ul li {
  display: flex;
  list-style: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #313B3D;
  margin-bottom: 10px;
}

.cr-industry-tab-content ul li .svg-box {
  height: 25px;
  width: 25px;
  margin-right: .25rem !important;
  margin-top: .25rem !important;
}

.cr-industry-tab-content ul li .svg-box svg {
  color: var(--cr-theme-secondary);
}

.cr-industry-progress-bar {
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-industry-progress-bar {
    margin-bottom: 40px;
  }
}

.cr-industry-progress-bar h4 {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--cr-heading-secondary);
  margin-bottom: 10px;
}

.cr-industry-progress-bar .progress {
  overflow: inherit;
  height: 3px;
  border-radius: 1.5px;
}

.cr-industry-progress-bar .progress-bar {
  position: relative;
  overflow: inherit;
  background-color: var(--cr-theme-secondary);
}

.cr-industry-progress-bar .progress-bar span {
  text-align: right;
  transform: translateY(-20px);
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--cr-heading-secondary);
}

/*----------------------------------------*/
/*   brand css
/*----------------------------------------*/
.cr-cta-2-area {
  background-color: var(--cr-bg-primary);
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-cta-2-area {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-cta-2-area {
    padding-top: 0px;
  }
}

.cr-cta-2-shape .shape-1 {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-cta-2-shape .shape-1 {
    display: none;
  }
}

.cr-cta-2-shape .shape-2 {
  position: absolute;
  right: 18%;
  top: -130px;
  z-index: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-cta-2-shape .shape-2 {
    display: none;
  }
}

.cr-cta-2-shape .shape-3 {
  position: absolute;
  bottom: 0;
  left: -25px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-cta-2-shape .shape-3 {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-cta-2-title-wrapper {
    margin-top: 35px;
  }
}

.cr-cta-2-title-wrapper .cr-section-title {
  font-size: 48px;
}

.cr-cta-2-content {
  position: relative;
  height: 336px;
  width: 336px;
  background: var(--cr-theme-secondary);
  border-radius: 50%;
  line-height: 102px;
  padding: 50px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-cta-2-content {
    margin-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .cr-cta-2-content {
    height: 290px;
    width: 290px;
    line-height: 60px;
  }
}

.cr-cta-2-content::before {
  position: absolute;
  content: "";
  top: 19px;
  left: 19px;
  height: 300px;
  width: 300px;
  border: 1px #fff dashed;
  border-radius: 50%;
}

@media (max-width: 575px) {
  .cr-cta-2-content::before {
    height: 255px;
    width: 255px;
  }
}

.cr-cta-2-content-icon span {
  color: var(--cr-common-white);
  display: block;
}

.cr-cta-2-content-inner .cta-title {
  position: relative;
  font-weight: 500;
  font-family: var(--cr-ff-p);
  font-size: 22px;
  line-height: 56px;
  color: var(--cr-heading-secondary);
  margin-bottom: -2px;
}

.cr-cta-2-content-inner .cta-title::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 182px;
  background: var(--cr-common-white);
  bottom: 0;
  left: 78px;
}

@media (max-width: 575px) {
  .cr-cta-2-content-inner .cta-title::after {
    left: 55px;
  }
}

.cr-cta-2-content-inner a {
  font-weight: 500;
  font-size: 22px;
  display: block;
  line-height: 56px;
  color: var(--cr-heading-secondary);
  margin-bottom: 0;
}

.cr-cta-3-area {
  background: var(--cr-theme-primary);
  overflow: hidden;
}

.cr-cta-3-shape {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 575px) {
  .cr-cta-3-shape {
    display: none;
  }
}

.cr-cta-3-wrapper {
  background-color: var(--cr-theme-secondary);
  padding: 45px 48px 30px 65px;
}

@media (max-width: 575px) {
  .cr-cta-3-wrapper {
    padding: 25px;
  }
}

.cr-cta-3-title-wrapper .cr-cta-3-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: var(--cr-common-white);
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-cta-3-title-wrapper .cr-cta-3-title {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-cta-3-title-wrapper .cr-cta-3-title br {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-cta-3-phone {
    margin-bottom: 25px;
  }
}

.cr-cta-3-phone-content span {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.79);
}

.cr-cta-3-phone-content span a {
  color: var(--cr-common-white);
}

.cr-cta-3-phone-icon {
  position: relative;
  height: 60px;
  width: 60px;
  background: var(--cr-common-white);
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  margin-right: 25px;
  flex: 0 0 auto;
}

.cr-cta-3-phone-icon::after {
  position: absolute;
  top: -6px;
  left: -6px;
  height: 72px;
  width: 72px;
  content: "";
  background: rgba(255, 255, 255, 0.54);
  border-radius: 50%;
}

.cr-cta-3-btn {
  padding-left: 75px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-cta-3-btn {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-cta-3-btn {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-cta-3-btn {
    padding-left: 0;
  }
}

.cr-cta-3-btn .cr-btn-hover::after {
  background: var(--cr-common-white);
}

.cr-cta-4-area {
  overflow: hidden;
  background: #F1FBFE;
}

.cr-cta-4-shape {
  position: absolute;
  top: 5px;
  left: 50%;
  z-index: 1;
}

.cr-cta-4-wrapper-left {
  position: relative;
  padding: 51px 15px 50px 55px;
  background: var(--cr-theme-secondary);
}

@media (max-width: 575px) {
  .cr-cta-4-wrapper-left {
    padding: 45px 15px 45px 25px;
  }
}

.cr-cta-4-wrapper-left .shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.cr-cta-4-wrapper-right {
  background: var(--cr-theme-primary);
  padding: 51px 15px 53px 55px;
}

@media (max-width: 575px) {
  .cr-cta-4-wrapper-right {
    padding: 45px 15px 45px 25px;
  }
}

.cr-cta-4-mail img {
  margin-right: 32px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-cta-4-mail img {
    margin-right: 15px;
  }
}

@media (max-width: 575px) {
  .cr-cta-4-mail img {
    margin-bottom: 15px;
  }
}

.cr-cta-4-mail a {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: var(--cr-common-white);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 66.5px;
  padding: 12px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-cta-4-mail a {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .cr-cta-4-mail a {
    font-size: 16px;
  }
}

.cr-cta-4-mail a span {
  text-decoration: underline;
}

.cr-cta-4-headphone-thumb {
  margin-right: 25px;
}

@media (max-width: 575px) {
  .cr-cta-4-headphone-thumb img {
    margin-bottom: 15px;
  }
}

.cr-cta-4-content p,
.cr-cta-4-content a {
  font-family: var(--cr-ff-heading);
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--cr-common-white);
  margin-bottom: 6px;
}

@media (max-width: 575px) {

  .cr-cta-4-content p,
  .cr-cta-4-content a {
    font-size: 15px;
  }
}

.cr-cta-4-content p span,
.cr-cta-4-content a span {
  color: var(--cr-theme-secondary);
}

.cr-cta-4-content a {
  color: var(--cr-theme-secondary);
}

/*----------------------------------------*/
/*   category css
/*----------------------------------------*/
.cr-category-area {
  background: var(--cr-bg-primary);
}

.cr-category-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-category-shape .shape-1 {
    display: none;
  }
}

.cr-category-shape .shape-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.cr-category-shape .shape-3 {
  position: absolute;
  bottom: 36%;
  left: 22%;
  color: var(--cr-theme-secondary);
  z-index: 0;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-category-shape .shape-3 {
    left: 18%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-category-shape .shape-3 {
    left: 12%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-category-shape .shape-3 {
    left: 5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-category-shape .shape-3 {
    left: 1%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-category-shape .shape-3 {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-category-shape .shape-3 svg {
    width: 94%;
  }
}

.cr-category-title-wrapper .cr-section-title {
  margin-bottom: 60px;
}

.cr-category-icon img {
  position: relative;
}

.cr-category-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: var(--cr-theme-secondary);
  transform-origin: center;
  -moz-transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  -webkit-transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  -ms-transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  -o-transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  transition: all 0.3s cubic-bezier(0.54, 0.33, 0.78, 0.84);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0.5);
  z-index: 0;
  opacity: 0;
  border-radius: 50%;
}

.cr-category-content {
  position: relative;
  text-align: center;
  height: 170px;
  width: 170px;
  background: var(--cr-common-white);
  box-shadow: 0px 7px 29px rgba(13, 39, 69, 0.05);
  border-radius: 50%;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.cr-category-content:hover .cr-category-icon::before {
  transform: scale(1.1);
  opacity: 1;
}

.cr-category-content:hover .cr-category-content-title {
  color: var(--cr-common-white);
}

@media (max-width: 575px) {
  .cr-category-content-wrapper {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.cr-category-content.one {
  margin-left: -22px;
  margin-top: 20px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-category-content.one {
    margin-right: 20px;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.cr-category-content.two {
  margin-left: 82px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-category-content.two {
    margin-left: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-category-content.two {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.cr-category-content.three {
  margin-top: 80px;
  margin-left: -14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-category-content.three {
    margin-left: 120px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-category-content.three {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.cr-category-content.four {
  margin-left: 34px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-category-content.four {
    margin-right: 20px;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.cr-category-content.five {
  margin-left: 95px;
  margin-top: 77px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-category-content.five {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-category-content.five {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-category-content.five {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.cr-category-content-title {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 0;
  margin-top: 10px;
  color: var(--cr-heading-secondary);
}

/*----------------------------------------*/
/*   team css
/*----------------------------------------*/
.cr-team-area {
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-team-area {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-team-area {
    padding-bottom: 80px;
  }
}

@media (max-width: 575px) {
  .cr-team-area {
    padding-bottom: 55px;
  }
}

.cr-team-shape {
  position: absolute;
  top: 0;
  left: 10%;
  animation: scale_up_down 1s infinite alternate both;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-team-shape {
    display: none;
  }
}

.cr-team-wrapper:hover .cr-team-wrapper-icon span {
  color: var(--cr-common-white);
  background-color: var(--cr-theme-secondary);
}

.cr-team-wrapper:hover .cr-team-wrapper-icon span svg {
  transform: rotate(45deg);
}

.cr-team-wrapper:hover .cr-team-social-info {
  bottom: 0;
}

.cr-team-wrapper-thumb {
  position: relative;
  overflow: hidden;
}

.cr-team-wrapper-thumb img {
  max-width: 100%;
}

@media (max-width: 575px) {
  .cr-team-wrapper-thumb img {
    min-width: 100%;
  }
}

.cr-team-wrapper-thumb .cr-team-social-info {
  position: absolute;
  bottom: -175px;
  right: 0;
  transform: translateX(-50%);
  background-color: var(--cr-common-white);
  border-radius: 26px 26.5px 0px 0px;
  height: 172px;
  width: 52px;
  padding: 15px 13px;
  text-align: center;
  transition: all 0.4s ease;
  overflow: hidden;
}

.cr-team-wrapper-thumb .cr-team-social-info a {
  display: block;
  color: #18181D;
  margin-bottom: 8px;
  padding-bottom: 5px;
}

.cr-team-wrapper-thumb .cr-team-social-info a:hover {
  color: var(--cr-theme-secondary);
}

.cr-team-wrapper-thumb .cr-team-social-info a:not(:last-of-type) {
  border-bottom: 1px solid #EAEAEE;
}

.cr-team-wrapper-content {
  padding: 33px 25px 0 10px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-team-wrapper-content {
    padding: 33px 15px 0 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-team-wrapper-content {
    padding: 33px 10px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-team-wrapper-content {
    padding: 33px 5px 0 10px;
  }
}

.cr-team-wrapper-content-text .team-title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 11px;
  color: var(--cr-heading-secondary);
  background-image: linear-gradient(#03041C, #03041C), linear-gradient(#03041C, #03041C);
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
  transition: 0.3s ease;
}

.cr-team-wrapper-content-text .team-title:hover {
  background-size: 0 2px, 100% 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cr-team-wrapper-content-text .team-title {
    font-size: 20px;
  }
}

.cr-team-wrapper-content-text p {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: var(--cr-theme-secondary);
}

.cr-team-wrapper-icon span {
  font-size: 18px;
  color: #5D5D70;
  height: 42px;
  width: 42px;
  background: var(--cr-common-white);
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.cr-team-wrapper-icon span svg {
  transition: all 0.4s ease-in-out;
}

.cr-team-wrapper .social {
  position: absolute;
  right: 30px;
  bottom: 108px;
  background: var(--cr-common-white);
  width: 52px;
  height: 0;
  text-align: center;
  border-radius: 26px 26.5px 0px 0px;
  padding-top: 0;
  transition: 0.5s;
  transition-delay: 0.5s;
  transition: all 400ms ease-out 0s;
  opacity: 0;
  visibility: hidden;
}

.cr-team-wrapper .social a {
  display: block;
  font-weight: 400;
  font-size: 0px;
  color: #18181D;
  transition: all 1s ease-in-out;
}

.cr-team-wrapper .social a:not(:last-child) {
  margin-bottom: 0px;
}

.cr-team-nav button {
  height: 66px;
  width: 66px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 500;
  color: var(--cr-heading-secondary);
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.28);
  z-index: 5;
}

.cr-team-nav button.team-button-next-1 {
  margin-left: 25px;
}

.cr-team-details-area {
  overflow: hidden;
}

.cr-team-details-shape .shape-1 {
  position: absolute;
  top: 20%;
  left: 20%;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-team-details-shape .shape-1 {
    left: 14%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-team-details-shape .shape-1 {
    left: 9%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-team-details-shape .shape-1 {
    left: 4%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-team-details-shape .shape-1 {
    display: none;
  }
}

.cr-team-details-shape .shape-2 {
  position: absolute;
  top: 18%;
  left: 26%;
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .cr-team-details-shape .shape-2 {
    left: 23%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .cr-team-details-shape .shape-2 {
    left: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .cr-team-details-shape .shape-2 {
    left: 16%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-team-details-shape .shape-2 {
    top: 10%;
    left: 12%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .cr-team-details-shape .shape-2 {
    top: 10%;
    left: 48%;
  }
}

@media (max-width: 575px) {
  .cr-team-details-shape .shape-2 {
    top: 6%;
    left: 30%;
  }
}

.cr-team-details-thumb {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-team-details-thumb {
    margin-bottom: 40px;
  }
}

.cr-team-details-thumb img {
  border-radius: 238px;
}

.cr-team-details-thumb .social {
  background: var(--cr-common-white);
  border-radius: 34px 34px 26px 26px;
  width: 173px;
  height: 52px;
  margin-top: -67px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  line-height: 52px;
}

.cr-team-details-thumb .social a {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #18181D;
  transition: 0.3s;
}

.cr-team-details-thumb .social a:not(:last-of-type) {
  margin-right: 20px;
}

.cr-team-details-thumb .social a:hover {
  color: var(--cr-theme-secondary);
}

.cr-team-details-designation-content p {
  font-size: 15px;
  margin-bottom: 10px;
}

.cr-team-details-designation-title {
  font-weight: 600;
  font-size: 30px;
  color: var(--cr-heading-secondary);
  margin-bottom: 5px;
}

.cr-team-details-meta {
  margin-bottom: 10px;
}

.cr-team-details-meta-thumb {
  margin-right: 10px;
}

.cr-team-details-meta p {
  font-size: 15px;
}

.cr-team-details-rating span {
  color: #FFA51F;
  margin-right: 7px;
}

.cr-team-details-rating span.deactive {
  color: #D8D5CE;
}

.cr-team-details-rating p {
  font-size: 16px;
  font-weight: 500;
  color: var(--cr-heading-secondary);
}

.cr-team-details-info p {
  margin-bottom: 30px;
}

.cr-team-details-list .list-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
}

.cr-team-details-list ul li {
  list-style: none;
  float: left;
  width: 45%;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-team-details-list ul li {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .cr-team-details-list ul li {
    font-size: 14px;
  }
}

.cr-team-details-list ul li span {
  color: var(--cr-theme-secondary);
  margin-right: 10px;
}

/*----------------------------------------*/
/*   contact css
/*----------------------------------------*/
.cr-contact-title {
  font-weight: 700;
  font-size: 45px;
  line-height: 65px;
  display: inline-block;
  text-transform: uppercase;
  color: var(--cr-heading-secondary);
  margin-bottom: 35px;
}

@media (max-width: 575px) {
  .cr-contact-title {
    font-size: 34px;
  }
}

.cr-contact-title span {
  color: var(--cr-theme-secondary);
}

.cr-contact-title-wrapper p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #7F7F8C;
  margin-bottom: 40px;
}

@media (max-width: 575px) {
  .cr-contact-title-wrapper p br {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-contact-content {
    margin-bottom: 40px;
  }
}

.cr-contact-content-mail {
  margin-bottom: 10px;
}

.cr-contact-content-mail-icon {
  margin-right: 55px;
}

@media (max-width: 575px) {
  .cr-contact-content-mail-icon {
    margin-right: 25px;
  }
}

.cr-contact-content-mail-icon span {
  color: var(--cr-theme-secondary);
}

.cr-contact-content-phone {
  margin-bottom: 50px;
}

.cr-contact-content-phone-icon {
  margin-right: 70px;
}

@media (max-width: 575px) {
  .cr-contact-content-phone-icon {
    margin-right: 35px;
  }
}

.cr-contact-content-phone-icon span {
  color: var(--cr-theme-secondary);
}

.cr-contact-content-phone-icon span svg {
  transform: translateX(12px);
}

@media (max-width: 575px) {
  .cr-contact-location-wrapper {
    flex-wrap: wrap;
  }
}

.cr-contact-location-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;
  color: var(--cr-heading-secondary);
  margin-bottom: 25px;
}

.cr-contact-location-title i {
  color: var(--cr-heading-secondary);
  font-size: 16px;
}

.cr-contact-location p {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #6F7183;
}

.cr-contact-item-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 2.8;
  color: var(--cr-heading-secondary);
  border-bottom: 1px solid #D9D9D9;
}

@media (max-width: 575px) {
  .cr-contact-item-title {
    line-height: 1.8;
    font-size: 19px;
  }
}

.cr-contact-item-title:hover {
  color: var(--cr-theme-secondary);
}

/* .cr-contact-form {
  background: #F8F8F9;
  border-radius: 9px;
  padding: 60px 50px 40px 60px;
} */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cr-contact-form {
    padding: 60px 30px 40px 30px;
  }
}

@media (max-width: 575px) {
  .cr-contact-form {
    padding: 60px 25px 40px 25px;
  }
}

.cr-contact-form-title {
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  text-transform: capitalize;
  color: #08151F;
}

@media (max-width: 575px) {
  .cr-contact-form-title {
    font-size: 30px;
  }
}

.cr-contact-form p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #555555;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
only screen and (min-width: 768px) and (max-width: 991px),
only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .cr-contact-form p br {
    display: none;
  }
}

.cr-contact-input {
  margin-bottom: 26px;
}

.cr-contact-input input {
  width: 100%;
  height: 60px;
  border: 1px solid #D4DCE0;
  /* background-color: #f8f8f9; */
  background-color: #FFFFFF;
  outline: 0;
  color: var(--cr-common-black);
  padding: 0 25px;
  border-radius: 2px;
}

.cr-contact-input input::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: rgba(20, 33, 43, 0.8);
}

.cr-contact-input input:focus {
  border: 1px solid var(--cr-theme-secondary);
}

.cr-contact-input input:focus::placeholder {
  font-size: 0;
}

.cr-contact-input textarea {
  height: 170px;
  background-color: #FFFFFF;
  resize: none;
  line-height: 3.5;
  border-radius: 2px;
  border: 1px solid #D4DCE0;
}

.cr-contact-input textarea::placeholder {
  font-weight: 400;
  font-size: 18px;
  color: rgba(20, 33, 43, 0.8);
}

.cr-contact-input textarea:focus {
  background: var(--cr-common-white);
  border: 1px solid var(--cr-theme-secondary);
}

.cr-contact-input textarea:focus::placeholder {
  font-size: 0;
}

.cr-contact-cta-area {
  margin-top: -165px;
}

.cr-contact-map {
  height: 480px;
  width: 100%;
}

.cr-contact-map iframe {
  height: 100%;
  width: 100%;
  filter: saturate(0);
}

/*----------------------------------------*/
/*   feature css
/*----------------------------------------*/
.faq-style-1 .cr-faq-tab-content .accordion-item {
  position: relative;
  background: var(--cr-common-white);
  border: 1px solid rgba(4, 18, 31, 0.08);
  border-radius: 0;
  margin-bottom: 16px;
}

.faq-style-1 .cr-faq-tab-content .accordion-item.cr-faq-active {
  position: relative;
  box-shadow: 0px 14px 50px rgba(4, 11, 17, 0.12);
  border: none;
}

.faq-style-1 .cr-faq-tab-content .accordion-item.cr-faq-active .accordion-button {
  color: var(--cr-theme-secondary);
}

.faq-style-1 .cr-faq-tab-content .accordion-item.cr-faq-active .accordion-button::after {
  color: var(--cr-theme-secondary);
}

.faq-style-1 .cr-faq-tab-content .accordion-item .accordion-button {
  font-weight: 600;
  font-size: 20px;
  color: #000D44;
  padding: 33px 45px 33px 34px;
}

@media (max-width: 575px) {
  .faq-style-1 .cr-faq-tab-content .accordion-item .accordion-button {
    padding: 33px 45px 33px 25px;
  }
}

.faq-style-1 .cr-faq-tab-content .accordion-item .accordion-button:hover {
  color: var(--cr-theme-secondary);
}

.faq-style-1 .cr-faq-tab-content .accordion-item .accordion-button:hover::after {
  color: var(--cr-theme-secondary);
}

.faq-style-1 .cr-faq-tab-content .accordion-item .accordion-button::after {
  top: 36%;
  right: 34px;
  transition: 0.3s;
}

.faq-style-1 .cr-faq-tab-content .accordion-body {
  padding: 0 59px 29px 34px;
}

@media (max-width: 575px) {
  .faq-style-1 .cr-faq-tab-content .accordion-body {
    padding: 0 45px 29px 34px;
  }
}

.faq-style-1 .cr-faq-tab-content .accordion-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #5A627D;
  margin-bottom: 0;
}

.cr-header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(3, 4, 28, 0.1);
  background: var(--cr-common-white);
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  transition: 0.3s ease;
  z-index: 10;
}