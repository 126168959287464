/* =========================================
============================================ */

/* Wrote OWN CUSTOM CSS HERE // HERE IS MODIFYED CSS DONE */

/* =========================================
============================================ */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&amp;family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&amp;family=Raleway:wght@300;400;500;600;700;800;900&amp;display=swap");

:root {
  /**
    @font family declaration
    */
  --cr-ff-body: 'DM Sans', sans-serif;
  --cr-ff-heading: 'Raleway', sans-serif;
  --cr-ff-poppins: 'Poppins', sans-serif;
  --cr-ff-p: 'DM Sans', sans-serif;
  --cr-ff-fontawesome: "Font Awesome 6 Pro";
  /**
    @font size declaration
    */
  --cr-fz-body: 16px;
  --cr-fz-p: 16px;
  /**
    @color declaration
    */
  --cr-common-white: #ffffff;
  --cr-common-black: #000;
  --cr-heading-primary: #15587d;
  --cr-heading-secondary: #000000;
  --cr-grey-1: #F1F1F1;
  --cr-grey-2: #F5F6F8;
  --cr-text-body: #5A627D;
  --cr-text-1: #261F1F;
  --cr-text-2: #0C0808;
  --cr-theme-primary: #15587d;
  --cr-theme-top-secondary: #F6E96B;
  --cr-theme-secondary: #399918;
  --cr-bg-primary: #F3FBFE;
  --cr-border-primary: #000;
  --cr-border-secondary: #000;
}

.cr-counter-wrapper .counter-title,
.cr-counter-wrapper .counter-subtitle {
  font-family: var(--cr-ff-body);
}

.cr-counter-wrapper .counter-subtitle {
  font-weight: 400;
}

.cr-contact-boxes {
  border: 1px solid #E0E8E7;
  border-radius: 6px;
  padding: 15px 15px 26px 22px;
  transition: all 0.3s;
  text-align: center;
  margin-bottom: 25px;
}

.cr-contact-boxes .cr-smallIcon-box {
  width: 90px;
  height: 90px;
  border: 1px solid #E0E8E7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px;
  box-shadow: 0px 2px 8px #E0E8E7;
}

.cr-contact-boxes a {
  font-size: 18px;
  color: var(--cr-theme-secondary);
  font-weight: 500;
}

.cr-contact-boxes span {
  color: var(--cr-common-black);
  font-weight: 500;
}

.cr-main-menu ul li>a.active {
  color: var(--cr-theme-secondary);
}

.cr-main-menu ul li a.active::before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0 50%;
}

.loder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #D1DFFF;
  border-top: 8px solid #3775FF;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);

  }

  50% {
    border-top-width: 5px;
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 668px) {
  .loder {
    height: 60px;
    width: 60px;
    border-top: 6px solid #3775FF;
  }
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
  float: left;
  width: 100%;
}

.mean-container .mean-nav>ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.hamburger-btn {
  font-size: 32px;
}

.cr-footer-overlay {
  object-fit: cover;
  background-position: right;
  background-size: cover;
}

.cr-footer-from {
  background: #074263;
}

.breadcrumb__title {
  color: var(--cr-common-white);
}

.breadcrumb__list a {
  color: var(--cr-theme-top-secondary);
}

.breadcrumb__list span {
  color: var(--cr-common-white);
}

.cr-circlebx {
  width: 60px;
  height: 60px;
  background: #EFF0F2;
  border: 2px solid #EFF0F2;
  display: flex;
  justify-content: center;
  align-items: center;
}